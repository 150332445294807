import { BlockDataType } from "@/app/types/BlockDataType";
import { PostCard } from "@/app/types/PostCard.type";
import Link from "next/link";
import InteractiveLink from "../../organisms/BloqueHeroGrid/InteractiveLink";

type ItemProps = {
  item: PostCard;
  blockData: BlockDataType;
  cardPosition: number;
  className?:string;
}
export default function SimpleLiLink({item, blockData, cardPosition, className}:ItemProps ){

  return(
    <li>
      <InteractiveLink url={item?.path} title={item?.title} blockData={blockData} cardPosition={cardPosition} className={className}>
        {item?.date}
      </InteractiveLink>
    </li>
  );
}