import React from 'react'
import styles from "@/app/styles/Atoms/Tags.module.scss";

interface props {
  text?: string;
  version?: string | 'lastminute' | 'coverage' | 'live' | 'gray';
}

const Tag = ({text, version}:props) => {
  return (
    <span className=
      {(        
        version === "live" ? (styles?.tag + " " + styles?.live) 
        : version === "lastminute" ? (styles?.tag + " " + styles?.lastminute) 
        : version === "coverage" ? (styles?.tag + " " + styles?.coverage)
        : version === "gray" ? (styles?.tag + " " + styles?.gray)
        : styles?.tag
      )}      
    >     
    {text} </span>    
  )
}

export default Tag