import { Children } from "react";
import Image from "next/image";

import programs from "@/app/styles/Blocks/BloqueProgramas.module.scss";
import { FieldPrograma } from "./interfaces/ProgramasResponse";
import { BlockDataType } from "@/app/types/BlockDataType";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

export const ProgramCards = ({
	fieldPrograms,
	loading,
	blockData
}: {
	fieldPrograms: FieldPrograma[];
	loading: "lazy" | "eager";
	blockData: BlockDataType
}) => {
	return (
		<div className={`${programs["programs__card"]}`}>
			{Children.toArray(
				fieldPrograms?.map((card, i) => {
					return (
						<figure>
							<InteractiveLink url={card?.path || ""} title={card.alt_image ?? card.title} cardPosition={i} blockData={{...blockData, contentTitle: card?.title}}>
								<Image
									className={`${programs["programs__image"]}`}
									src={card?.image_16_9_desktop || "/assets/placeholder_.svg"}
									width={150}
									height={200}
									alt={card.alt_image}
									sizes="(max-width:834px) 40vw 40vw, (max-width:1280px) 22.01vw 22.01vw 22.01vw 22.01vw"
									loading={loading}
								/>
							</InteractiveLink>
							<figcaption>
								<InteractiveLink url={card?.path || ""} title={card.title} cardPosition={i} blockData={{...blockData, contentTitle: card?.title}}>
									<span className={`${programs["programs__card__title"]}`}>
										{card.title}
									</span>
								</InteractiveLink>

								<p className={`${programs["programs__card__schedule"]}`}>
									{card.field_horario}
								</p>
							</figcaption>
						</figure>
					);
				})
			)}
		</div>
	);
};
