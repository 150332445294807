import { useContext } from "react";
import styles from "@/app/styles/Discover/Discover.module.scss";
import Link from "next/link";
import Image from "next/image";

export interface CardHistoryType {
	cardData?: any;
	isLoading?: boolean;
	isDarkMode?: boolean;
	variantDiscover?: boolean;
	cardIndex?: number;
	widgetTitle?: string;
}

export default function CardDiscover({
	cardData,
	isLoading = false,
	cardIndex = undefined,
	widgetTitle = undefined,
}: CardHistoryType) {
	const cleanUrl = cardData?.url?.replaceAll("//", "/") ?? "/";
	return (
		<>
			<div className={styles.discover__card}>
				<div className={styles.discover__card__image_wrapper}>
					<Link title={cardData?.title} prefetch={false} href={cleanUrl}>
						<Image
							src={cardData?.image?.imageUrl ?? "/assets/placeholder_.svg"}
							alt={cardData?.title ?? "Imagen"}
							fill
							sizes="
								(max-width: 768px) 100vw,
								(max-width: 1024px) 100vw,
								(max-width: 1280px) 100vw,
							"
						/>
					</Link>
				</div>
				<div className={styles.discover__card__wrapper}>
					<div className={styles.discover__card__wrapper__clamp}>
						<h3 className={styles.discover__card__wrapper__clamp__title}>
							<Link title={cardData?.title} prefetch={false} href={cardData?.url ?? "/"}>
								{cardData!.title}{" "}
							</Link>
						</h3>
					</div>
				</div>
			</div>
		</>
	);
}
