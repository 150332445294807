"use client";
import { useState } from "react";
import { singleDateString } from "@/app/helpers/timeHelpers/timeHelpers";
import pressRoomStyle from "@/app/styles/Blocks/BlockPressRoomHome.module.scss";
import { RelationContent } from "./interface/PressRoom.interface";
import Link from "next/link";
import Image from "next/image";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
declare const window: WindowWithDataLayer;

const PressRoomHomeCards = ({
	cards,
	loading,
	total,
	cmsName,
	blockType,
}: {
	cards: RelationContent[];
	loading: "eager" | "lazy";
	total: any;
	cmsName: any;
	blockType: string;
}) => {

	const [indiceInicial, setIndiceInicial] = useState(0);

	const handleBannerClick = (obj: any) => {
		const blockDataLayer = {
			event: "select_content",
			content_type: "SdP-Card",
			seccion: obj?.path,
			content_name: obj?.editorialTitle || obj?.title || undefined,
			eventAction: "click",
			tipoBloque: upperCamelCase(blockType) || undefined,
			tipoElemento: "LinkText",
			tituloBloque: obj?.editorialTitle || obj?.title || undefined,
			colorBloque: "Light",
			countWidget: total,
			descripcionBloque: cmsName,
			estiloBloque: undefined,
			subBloque: undefined,
			tamanoBloque: undefined,
		};
		handleDataLayerOnClick(blockDataLayer, 0, obj?.path);
	};

	return (
		<>
			<div className={`${pressRoomStyle["pressRoomHome__wrapperBulletin"]}`}>
				{
					cards.slice(indiceInicial).map((news, index) => {
						return (
							<figure key={index}>
								<div style={{ position: "relative" }}>
									<Link
										href={news?.path || ""}
										prefetch={false}
										onClick={() => handleBannerClick(news)}
									>
										<Image
											src={
												news.corte_1_1_desktop ?? "/assets/placeholder_.svg"
											}
											height={107}
											width={191}
											alt={news.editorialTitle || "nmas"}
											className={`${pressRoomStyle["pressRoomHome__bulletinImage"]}`}
											sizes={
												"(max-width:834px) 42.1vw 42.1vw, (max-width:1280px) 22.7vw 22.7vw 22.7vw 22.7vw"
											}
											loading={loading}
										/>
									</Link>
								</div>

								<figcaption
									className={`${pressRoomStyle["pressRoomHome__figcaption"]}`}
								>
									<Link
										href={news?.path || ""}
										title={news?.editorialTitle || "nmas"}
										aria-label={news?.editorialTitle || "nmas"}
										prefetch={false}
										onClick={() => handleBannerClick(news)}
									>
										<p
											className={`${pressRoomStyle["pressRoomHome__bulletinHeadLine"]}`}
											title={news?.editorialTitle || "nmas"}
											aria-label={news?.editorialTitle || "nmas"}
										>
											{news.editorialTitle}
										</p>
									</Link>
									<time dateTime={`${news?.created}`} className={`${pressRoomStyle["pressRoomHome__bulletinTime"]}`}>
										{singleDateString(news?.created || "")}
									</time>
								</figcaption>
							</figure>
						);
					})
				}
			</div>
		</>
	);
};

export default PressRoomHomeCards;