import Link from "next/link";
import Image from "next/image";

import heroGrid from "@/app/styles/Blocks/BloqueHeroGrid.module.scss";
import { NewsInfo } from "../../organisms/BloqueHeroGrid/interfaces/HeroGridResponse";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import Icon from "../../atoms/Icon/Icon";
import SingleCardImage from "./SingleCardImage";
import InteractiveLink from "../../organisms/BloqueHeroGrid/InteractiveLink";
import { BlockDataType } from "@/app/types/BlockDataType";

const SingleCard = ({
	news,
	noContent,
	theme,
	visibleClass,
	loading,
	blockData,
	cardPosition,
}: {
	news: NewsInfo;
	noContent: string;
	theme: string;
	visibleClass?: string | undefined;
	loading: "eager" | "lazy";
	blockData: BlockDataType;
	cardPosition: number;
}) => {
	const typeOfNews = "video"; // news?.type?.split("--")[1];
	if (!typeOfNews) return <></>;
	return (
		<>
			<figure
				className={`${heroGrid[`heroGrid${noContent}__relations`]} ${
					visibleClass ? visibleClass : ""
				}`}
			>
				<InteractiveLink
					url={news?.newsUrl || ""}
					title={
						news?.altImage?.toUpperCase() ||
						news?.editorialTitle?.toUpperCase() ||
						"Noticia N+"
					}
					blockData={blockData}
					cardPosition={cardPosition}
				>
					{typeOfNews === "video" ? (
						<div
							className={`${
								heroGrid[`heroGrid${noContent}__relations__wrapperImage`]
							}`}
						>
							<SingleCardImage
								loading={loading}
								noContent={noContent}
								news={news}
							/>
							{news?.videoDuration ? (
								<span
									className={`${heroGrid[`heroGrid${noContent}__labelVideo`]}`}
								>
									{secondsToMinutes(news?.videoDuration)}
									<Icon
										name="PlayArrow"
										className={`${heroGrid[`heroGrid${noContent}__videoIcon`]}`}
									/>
								</span>
							) : news?.isLiveblog ? (
								<>
									<div
										className={`${
											heroGrid[`heroGrid${noContent}__liveblog__caption--off`]
										}`}
									>
										<span
											className={`${
												heroGrid[`heroGrid${noContent}__liveblog__captionText`]
											}`}
										>
											LIVEBLOG
										</span>
									</div>
								</>
							) : (
								<></>
							)}
						</div>
					) : (
						<div
							className={`${
								heroGrid[`heroGrid${noContent}__relations__wrapperImage`]
							}`}
						>
							<SingleCardImage
								loading={loading}
								noContent={noContent}
								news={news}
							/>
						</div>
					)}
				</InteractiveLink>
				<figcaption
					className={`${
						heroGrid[`heroGrid${noContent}__relations__figcaption`]
					}`}
				>
					<span
						className={`${heroGrid[`heroGrid${noContent}__relations__tag`]}`}
						style={
							{
								"--heroGridTagColor": `${
									theme.toLowerCase() === "light" ? "#2D5D75" : "#BFD1DA"
								}`,
							} as React.CSSProperties
						}
						title={news?.tagBlock?.toUpperCase() || ""}
					>
						{news?.tagBlock?.toUpperCase() || ""}
					</span>
					<InteractiveLink
						url={news?.newsUrl || ""}
						title={news?.editorialTitle || news?.title || "Noticia N+"}
						aria-label={news?.editorialTitle || news?.title || "Noticia N+"}
						blockData={blockData}
						cardPosition={cardPosition}
						isLink={false}
					>
						<p
							className={`${
								heroGrid[`heroGrid${noContent}__relations__headLine`]
							}`}
							title={news?.editorialTitle || news?.title || ""}
							style={
								{
									"--heroGridHeadLine": `${
										theme.toLowerCase() === "light" ? "#103D53" : "#EDF4F8"
									}`,
								} as React.CSSProperties
							}
						>
							{news?.editorialTitle || news?.title || ""}
						</p>
					</InteractiveLink>
				</figcaption>
			</figure>
		</>
	);
};

export default SingleCard;
