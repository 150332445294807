"use client";

import Image from "next/image";
import { useState } from "react";
import { usePathname } from "next/navigation";

import styles from "@/app/styles/Molecules/TapToPlayCover.module.scss";

import VideoDuration from "../../atoms/VideoDuration/VideoDuration";
import PlayerVodV2 from "../PlayerVodV2/PlayerVodV2";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import { ImgLinkProps } from "@/app/types/ImgLinkProps.type";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import InteractiveLink from "../../organisms/BloqueHeroGrid/InteractiveLink";

declare const window: WindowWithDataLayer;
export default function TapToplayCover({
	post,
	loading,
	blockData,
	cardPosition,
	isTopFive = false,
}: any) {
	const pathName = usePathname();

	const handleOnClick = () => {
		blockData.content_name = "VOD>";
		blockData.contentTitle = post?.title;
		handleDataLayerOnClick(blockData, cardPosition + 1, pathName);
		setShowPlayer(true);
		return null;
	};

	const [showPlayer, setShowPlayer] = useState<boolean>(false);
	return (
		<div className={styles?.imgWrapper} onClick={handleOnClick}>
			{post?.isVideo ? (
				<>
					<Image
						src={post?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading={loading}
						sizes="
						(min-width: 412px) 80vw,
						(min-width: 834px) 41.4vw,
						(min-width: 1024px) 43.1vw,
						(min-width: 1280px) 44.45vw,
						(min-width: 1920px) 35.05vw,
						50vw"
					/>
				</>
			) : post?.isLiveblog ? (
				<InteractiveLink
					url={post?.path || "/"}
					blockData={blockData}
					cardPosition={0}
					title={post?.title || "nmas"}
				>
					<Image
						src={post?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading={loading}
						sizes="
						(min-width: 412px) 80vw,
						(min-width: 834px) 41.4vw,
						(min-width: 1024px) 43.1vw,
						(min-width: 1280px) 44.45vw,
						(min-width: 1920px) 35.05vw,
						50vw"
					/>
					<>
						<div className={`${styles[`topFive__liveblog__caption--on`]}`}>
							<span className={`${styles[`topFive__liveblog__captionText`]}`}>
								LIVEBLOG
							</span>
							<span className={`${styles[`topFive__liveblog__pulseIcon`]}`} />
						</div>
					</>
				</InteractiveLink>
			) : (
				<InteractiveLink
					url={post?.path || "/"}
					blockData={blockData}
					cardPosition={0}
					title={post?.title || "nmas"}
				>
					<Image
						src={post?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"}
						alt={post?.thumbnail?.alt ?? "Imagen del video"}
						width={post?.thumbnail?.width ?? "320"}
						height={post?.thumbnail?.height ?? "180"}
						loading={loading}
						sizes="
						(min-width: 412px) 80vw,
						(min-width: 834px) 41.4vw,
						(min-width: 1024px) 43.1vw,
						(min-width: 1280px) 44.45vw,
						(min-width: 1920px) 35.05vw,
						50vw"
					/>
				</InteractiveLink>
			)}
			{post?.isVideo && !showPlayer && (
				<VideoDuration
					duration={post?.duration}
					isPlay={true}
					isTopFive={isTopFive}
				/>
			)}
			{showPlayer && (
				<PlayerVodV2
					idVideo={post?.cmsId}
					type="clips"
					cmsid={true}
					mute={true}
					autoplay={true}
					currentPlaceholder={post?.thumbnail?.imageUrl?.webp}
				/>
			)}
		</div>
	);
}
