"use client";
import { useSocketEntries } from "@/app/hooks/useLiveBlog";
import Tag from "../../atoms/Tags/Tag";
import Update from "../../atoms/Update/Update";
import styles from "@/app/styles/Molecules/HeadEntry.module.scss";

interface props {
	live?: boolean;
	idEntry?: string;
	liveNow?: boolean;
	textTag?: string;
	versionUpdate?: string;
	versionTag?: string | "lastminute" | "coverage" | "live" | "gray";
	dateModified?: string;
	isLive?: boolean;
	typeMedia?: string;
}

const HeadEntry = ({
	live,
	idEntry,
	liveNow,
	textTag,
	versionTag,
	dateModified,
	versionUpdate = "",
	isLive,
	typeMedia,
}: props) => {
	// Hook para saber cuando llegan actualizaciones
	// y poder actualizar la fecha de la entrada mas reciente
	const { updateEntries } = useSocketEntries({ id: idEntry });

	return (
		<>
			{live ? (
				<div
					className={`${
						liveNow ? styles?.headentry + " " + styles?.live : styles?.headentry
					}
					${
						typeMedia && typeMedia === "multiImage"
							? styles["headentry__liveblog--multiImage"]
							: typeMedia && typeMedia === "multiVideo"
							? styles["headentry__liveblog--multiVideo"]
							: ""
					}
					`}
				>
					<figure className={styles?.image}>
						<img
							src="https://aishlatino.b-cdn.net/wp-content/uploads/2021/11/que-tipo-de-persona-te-gustaria-ser-730x411-SP.jpg"
							alt=""
						/>
					</figure>
					<div className={styles?.info}>
						<div className={styles?.tag}>
							<Tag text={textTag} version={versionTag} />
							<Update
								dateModified={
									updateEntries && updateEntries?.length > 0
										? updateEntries[0]?.datePublished
										: dateModified
								}
								version={versionUpdate ? versionUpdate : versionTag}
							/>
						</div>
						<h2 className={styles?.description}>
							{" "}
							<a href="#">
								{" "}
								Donald Trump regresa a comparecer en la corte de Nueva York{" "}
							</a>{" "}
						</h2>
					</div>
				</div>
			) : (
				<div
					className={`${styles?.headentry} ${
						typeMedia && typeMedia === "multiImage"
							? styles["headentry__liveblog--multiImage"]
							: typeMedia && typeMedia === "multiVideo"
							? styles["headentry__liveblog--multiVideo"]
							: ""
					}`}
				>
					<div className={styles?.info}>
						<div className={styles["headentry__tag"]}>
							<Tag text={textTag} version={versionTag} />
							<Update
								dateModified={
									updateEntries && updateEntries?.length > 0
										? updateEntries[0]?.datePublished
										: dateModified
								}
								version={versionUpdate ? versionUpdate : versionTag}
							/>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default HeadEntry;
