import styles from "@/app/styles/Cards/CardTapToPlay.module.scss";
import TapToplayCover from "../../TapToPlayCover/TapToPlayCover";
import { BlockDataType } from "@/app/types/BlockDataType";
import TooltipIcon from "../../tooltipedIcon/tooltipIcon";
import InteractiveLink from "../../../organisms/BloqueHeroGrid/InteractiveLink";

type Props = {
	post: any;
	index: number;
	isDark: boolean;
	isTopFive?: boolean;
	blockData: BlockDataType;
};
export default function CardTapToPlay({
	post,
	index,
	isDark,
	isTopFive = false,
	blockData,
}: Props) {
	const load = blockData?.posicionBloque === 0 || blockData?.posicionBloque === 1 ? "eager" : "lazy";

	return (
		<figure
			id={post?.id}
			className={
				isDark ? `${styles?.ttp_card} ${styles?.dark}` : `${styles?.ttp_card}`
			}
		>
			<TapToplayCover
				post={post}
				cardPosition={index}
				loading={load}
				blockData={blockData}
				isTopFive={isTopFive}
			/>

			<figcaption className={styles?.ttp_card__caption}>
				<span>{post?.channel ?? post?.term[0]?.name}</span>
				{!post?.isVideo ? (
					<>
						<InteractiveLink url={post?.path || "/"} blockData={blockData}>
							<h3>{post?.title}</h3>
						</InteractiveLink>
						<InteractiveLink url={post?.path || "/"} blockData={blockData}>
							<p
								className={`${styles["ttp_card__headLine"]}`}
								style={
									{
										"--textColor": `${isDark ? "#f4f4f4" : "#474B4D"}`,
									} as React.CSSProperties
								}
							>
								{post?.summary || ""}
							</p>
						</InteractiveLink>
					</>
				) : (
					<>
						<h3>{post?.title}</h3>

						<p
							className={`${styles["ttp_card__headLine"]}`}
							style={
								{
									"--textColor": `${isDark ? "#EDF4F8" : "#001621"}`,
								} as React.CSSProperties
							}
						>
							{post?.summary || ""}
						</p>
					</>
				)}
			</figcaption>
			{/* <div
				className={`${styles["ttp_card__wrapperShare"]}`}
				style={
					{
						"--colorSVG": `${!isDark ? "#001621" : "#fff"}`,
					} as React.CSSProperties
				}
			>
				<TooltipIcon
					variant="share"
					variantDiscover={false}
					baseCardModel={{
						title: post?.title || "nmas",
						url: post?.path || "/",
					}}
				/>
			</div> */}
		</figure>
	);
}
