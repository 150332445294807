import { gql } from "@apollo/client";

export default gql`
	query GetLiveBlogStructuredData($path: String!) {
		getLiveBlogStructuredData(path: $path) {
			_id
			_context
			_type
			url
			coverageStartTime
			coverageEndTime
			headline
			description
			liveBlogUpdate {
				_context
				_type
				headline
				url
				datePublished
				articleBody
				image
				author {
					_type
					name
					url
				}
				publisher {
					_type
					name
					url
				}
				video {
					_context
					_type
					name
					description
					uploadDate
					thumbnail
					thumbnailUrl
					embedUrl
				}
			}
			author {
				_type
				name
				url
			}
			dateModified
			publisher {
				_type
				name
				url
				logo {
					imageUrl
					imageAlt
					_context
					_type
					url
					width
					height
				}
			}
			about {
				_type
				name
				startDate
				endDate
				location {
					_type
					url
				}
				organizer {
					_type
					name
					url
				}
				offers {
					_type
					availability
					price
					priceCurrency
					url
					validFrom
				}
				performer {
					_type
					name
					url
				}
				eventStatus
				eventAttendanceMode
				description
				image {
					imageUrl
					imageAlt
					_context
					_type
					url
					width
					height
				}
				author {
					_type
					name
					url
				}
				dateModified
			}
			mainEntityOfPage {
				_type
				_id
			}
		}
	}
`;
