import { gql } from "@apollo/client";

export default gql`
  query GetLiveAndStories($getLiveAndStoriesId: String!) {
    getLiveAndStories(id: $getLiveAndStoriesId) {
      blockTitle
      blockDescription
      borderColor
      cmsName
      drupalID
      id
      hasRelated
      type
      hasTitle
      isDark
      variant
      liveChannel {
        name
        mcpID
        ui
        uid
        path
        liveTitle
        liveDescription
        liveInfo {
          showName
          hour
          end
        }
      }
      relatedContent {
        id
        title
        duration
        channel
        summary
        path
        isVideo
        term {
          name
        }
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
      }
    }
  }
`;