import { gql } from "@apollo/client";

export default gql`
	query GetDiscoverVideos($sectionPath: String, $id: String) {
		getDiscoverVideos(sectionPath: $sectionPath, id: $id) {
			principal_content {
				title
				description
				id_video
				url
			}

			image {
				imageAlt
				imageUrl
				imageUrlMobile
			}
			related_cards {
				title
				url
				image {
					imageAlt
					imageUrl
					imageUrlMobile
				}
			}
			related_themes {
				title
				url
			}
		}
	}
`;