"use client";

import { ApolloProvider } from "@apollo/client";
import TimeLineDynamic from "./TimeLineDynamic";
import { configureClient } from "@/app/lib/apollo/apollo";
type CardDynamicType = {
	widgetTitle?: string;
	id: string;
	isStandalone?: boolean;
	mainContent?: any;
	blockDataLayer?: any;
};
export default function CardDynamic({
	widgetTitle = "TimeLine minuto a minuto",
	id,
	isStandalone,
	mainContent,
	blockDataLayer,
}: CardDynamicType) {
	return (
		<ApolloProvider client={configureClient({})}>
			<TimeLineDynamic
				id={id}
				widgetTitle={widgetTitle}
				isStandalone={isStandalone}
				mainContent={mainContent}
				blockDataLayer={blockDataLayer}
			/>
		</ApolloProvider>
	);
}
