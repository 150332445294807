"use client";
import CopyToClipboard from "react-copy-to-clipboard";
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { usePathname } from "next/navigation";

import Icon from "@/app/components/atoms/Icon/Icon";
import { ThreadsButton } from "@/app/components/buttons/ButtonShareSocial/ThreadsButton";
import style from "@/app/styles/Molecules/SocialShareDialog.module.scss";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { useState } from "react";
import BaseSnackbar from "../../Snackbar/BaseSnackbar";

type SocialShareDialogType = {
	isOpenState: boolean;
	shareTitle?: string;
	shareDescription?: string;
	shareUrl?: string;
	handleCloseFunction: () => void;
	variantDiscover?: boolean;
	isDiscover?: boolean;
	isVertical?: boolean;
};

export default function SocialShareDialog({
	isOpenState,
	isDiscover = true,
	isVertical = false,
	shareTitle = "Titulo",
	shareDescription = "Descripción",
	shareUrl = process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "") ||
		"https://www.nmas.com.mx",
	handleCloseFunction = () => {},
	variantDiscover = false,
}: SocialShareDialogType) {
	const path = usePathname();
	const BASE_URL =
		process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "") ||
		"https://www.nmas.com.mx";
	shareUrl = shareUrl?.includes(BASE_URL) ? shareUrl : `${BASE_URL}${shareUrl}`;
	const re = /\/videos\//;
	const reParams = /\?([a-z-]+)=([a-z-0-9]+)$/; // Sirve para saber si la url tiene o no parámetros en la url
	const [isOpen, setIsOpen] = useState(false);
	const handleClose = () => setIsOpen(false);

	const eventData = {
		event: "select_content",
		clickBloque: undefined,
		content_name: `${re.test(shareUrl) ? "VOD" : "NEW"}>${shareUrl?.replace(
			BASE_URL,
			""
		)}`,
		colorBloque: "Light",
		contenidosBloque: undefined,
		content_type: "Comparte",
		countWidget: undefined,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: undefined,
		section: `${path}`,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: undefined,
		tituloBloque: undefined,
	};
	const handleShareSocial = (socialName: string) => {
		handleDataLayerOnClick(
			{
				...eventData,
				content_name: `${socialName}`,
				tipoElemento: `${socialName}`,
			},
			1,
			shareUrl?.replace(BASE_URL, ""),
			true
		);
	};
	const copyToClipboard = () => {
		setIsOpen(true);
		setTimeout(() => {
			setIsOpen(false);
		}, 2000);

		handleDataLayerOnClick(
			{
				...eventData,
				content_name: "CopyLink",
				tipoElemento: "CopyLink",
				content_type: "CopyLink",
			},
			1,
			shareUrl?.replace(BASE_URL, ""),
			true
		);
	};
	return (
		<>
			<dialog
				onClose={handleCloseFunction}
				open={isOpenState}
				className={`${style["socialShare__modal__wrapper"]} ${
					!isDiscover
						? style["socialShare__modal__wrapperGWS"]
						: isDiscover
						? `${style["socialShare__modal__wrapper__discover"]}`
						: ""
				}`}
			>
				<div
					className={`${style["socialShare__modal"]} ${
						!isDiscover ? style["socialShare__modal__GWS"] : ""
					}`}
				>
					<div className={`${style["socialShare__modal__wrapper__title"]}`}>
						<span className={`${style["socialShare__modal__title"]}`}>
							Compartir esta historia N+
						</span>

						<Icon
							name="close"
							className={`${style["socialShare__modal__icon__close"]}`}
							onClick={() => {
								handleCloseFunction();
							}}
						/>
					</div>
					<div className={`${style["socialShare__modal__wrapperIcons"]}`}>
						{/* Copy */}
						<div
							className={`${style["socialShare__modal__icon__socialIcon"]} ${style["socialShare__modal__icon__socialIcon--copy"]}`}
						>
							<CopyToClipboard
								text={shareUrl || "/"}
								onCopy={() => {
									copyToClipboard();
								}}
							>
								<Icon name="chain" />
							</CopyToClipboard>
							<span className={`${style["socialShare__modal__icon__text"]}`}>
								<CopyToClipboard
									text={shareUrl || "/"}
									onCopy={() => {
										copyToClipboard();
									}}
								>
									<span>Copiar Link</span>
								</CopyToClipboard>
							</span>
						</div>
						{/* Whatsapp */}
						<div
							className={`${style["socialShare__modal__icon__socialIcon"]} ${style["socialShare__modal__icon__socialIcon--whatsapp"]}`}
						>
							<WhatsappShareButton
								url={shareUrl || ""}
								onClick={() => {
									handleShareSocial("whatsapp");
								}}
							>
								<Icon name="WhatsApp" />
							</WhatsappShareButton>
							<span className={`${style["socialShare__modal__icon__text"]}`}>
								<WhatsappShareButton
									url={shareUrl || ""}
									onClick={() => {
										handleShareSocial("whatsapp");
									}}
								>
									Whatsapp
								</WhatsappShareButton>
							</span>
						</div>
						{/* Facebook */}
						<div
							className={`${style["socialShare__modal__icon__socialIcon"]} ${style["socialShare__modal__icon__socialIcon--facebook"]}`}
						>
							<FacebookShareButton
								url={shareUrl || ""}
								onClick={() => {
									handleShareSocial("facebook");
								}}
							>
								<Icon name="Facebook" />
							</FacebookShareButton>
							<span className={`${style["socialShare__modal__icon__text"]}`}>
								<FacebookShareButton
									url={shareUrl || ""}
									onClick={() => {
										handleShareSocial("facebook");
									}}
								>
									Facebook
								</FacebookShareButton>
							</span>
						</div>
						{/* Twitter */}
						<div
							className={`${style["socialShare__modal__icon__socialIcon"]} ${style["socialShare__modal__icon__socialIcon--twitter"]}`}
						>
							<TwitterShareButton
								url={shareUrl || ""}
								title={shareTitle}
								via={"nmas"}
								onClick={() => {
									handleShareSocial("twitter");
								}}
							>
								<Icon name="Twitter" />
							</TwitterShareButton>
							<span className={`${style["socialShare__modal__icon__text"]}`}>
								<TwitterShareButton
									url={shareUrl || ""}
									via={"nmas"}
									onClick={() => {
										handleShareSocial("twitter");
									}}
									title={shareTitle}
								>
									X
								</TwitterShareButton>
							</span>
						</div>
						{/* Threads */}
						<div
							className={`${style["socialShare__modal__icon__socialIcon"]} ${style["socialShare__modal__icon__socialIcon--threads"]}`}
						>
							<ThreadsButton
								shareUrl={shareUrl || ""}
								text=""
								className={`${style["socialShare__modal__icon__text"]} `}
								isLogo={true}
								dataLayer={{
									...eventData,
								}}
								isLiveBlogLayer={false}
								path={path || ""}
								isLight={false}
								title={shareTitle}
							/>

							<ThreadsButton
								shareUrl={shareUrl || ""}
								text="Threads"
								className={`${style["socialShare__modal__icon__text"]} ${style["socialShare__modal__icon__threads"]}`}
								isLogo={false}
								dataLayer={{
									...eventData,
								}}
								isLiveBlogLayer={false}
								path={path || ""}
								isLight={false}
								title={shareTitle}
							/>
						</div>
					</div>
				</div>
			</dialog>
			<BaseSnackbar
				label="¡Enlace copiado al portapapeles!"
				isOpenState={isOpen}
				handleCloseFunction={handleClose}
			/>
		</>
	);
}
