import { gql } from "@apollo/client";

export default gql`
  query GetBlockUniversal($getBlockUniversalId: String!) {
    getBlockUniversal(id: $getBlockUniversalId) {
      blockName
      blockTitle
      blockSummary
      lookFeel
      templateSize
      aspectRatio
      isDark
      hasTitle
      borderColor
      cmsName
      variant
      blockContent {
        id
        itemLink {
          uri
          target
        }
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
      }
    }
  }
`;