import { gql } from "@apollo/client";

export default gql`
	query GetTalentsTabs($fromSite: String!, $getTalentsTabsId: String!) {
		getChannels(fromSite: $fromSite) {
			channels {
				name
				field_url_talentos
				field_ocultar_de_filtros_talento
			}
		}
		getTalentsTabs(fromSite: $fromSite, id: $getTalentsTabsId) {
			titleBlock
			relationships {
				talents {
					id
					name
					lastName
					talentPath
					talentPosition
					relationships {
						field_canal {
							attributes {
								field_url_talentos
								name
							}
						}
						field_foto {
							attributes {
								uri {
									url
								}
							}
						}
					}
				}
			}
		}
	}
`;
