"use client";
import {
	useRef,
	useEffect,
	useState,
	useMemo,
	Children,
	MouseEvent,
} from "react";
import Image from "next/image";
import { usePathname } from "next/navigation";
import mapboxgl from "mapbox-gl";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { splitName, toCommaNotation } from "@/app/helpers";
import { getPartyColor } from "@/app/helpers/mapas/getPartyColor";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxStyle from "@/app/styles/mapbox/Mapbox.module.scss";
import "@/app/styles/mapbox/MapboxGlobalStyles.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import Icon from "../../atoms/Icon/Icon";
import InteractiveLink from "@/app/helpers/analytics/InteractiveLink";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";

mapboxgl.accessToken = process?.env?.NEXT_PUBLIC_MAPBOX || "";

type MapboxRest = {
	description: string;
	source: string;
	title: string;
	mapType: string;
	initialYear: string;
	newsUrl: string;
	lineColor: string;
};

interface ModalProps {
	state: string;
	data: CandidateProps[];
	year: string;
	resultsData?: CandidateProps[];
}
interface CandidateProps {
	isImage: boolean;
	urlImage?: string;
	name: string[];
	percent: string;
	party: string;
	partyColor: string;
	totalVotes?: number;
}
const MapboxMap = ({
	styleUrl,
	type,
	position,
	total,
	restData,
}: {
	styleUrl: string;
	type: string;
	position: number;
	total: number | undefined;
	restData: MapboxRest;
}) => {
	const mapContainer = useRef(null);
	const map = useRef<mapboxgl.Map | null>(null);
	const geoIdElement = useRef<HTMLDivElement | null>(null);
	const path = usePathname();
	const [lng, setLng] = useState(-103.254603);
	const [lat, setLat] = useState(25.655617);
	const [zoom, setZoom] = useState(3);
	const [maxZoom, setMaxZoom] = useState(6.5);
	const [minZoom, setMinZoom] = useState(5);
	const [isOpenWindow, setIsOpenWindow] = useState(false);
	const [widthWindow, setWidthWindow] = useState<number>();
	const [typeStates, setTypeStates] = useState(
		`${restData.initialYear}_${restData.mapType}_mexico_data`
	);
	const [infoModal, setInfoModal] = useState<ModalProps>({
		state: "",
		data: [],
		year: "",
		resultsData: [],
	});
	const [buttonsArray, setButtonsArray] = useState<string[]>();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isResultsVisible, setIsResultsVisible] = useState(false);

	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";
	const blockDataLayer = useMemo(
		() => ({
			clickBloque: undefined,
			colorBloque: undefined,
			contenidosBloque: undefined,
			content_name: "",
			content_type: "Button",
			contentTitle: undefined,
			countWidget: total ? total : '0',
			descripcionBloque: undefined,
			event: "select_content",
			eventAction: "click",
			posicionBloque: position + 1 ? `${position + 1}` : "0",
			section: undefined,
			subBloque: undefined,
			tamanoBloque: undefined,
			tipoBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
			tipoElemento: "widget",
			tituloBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
		}),
		[blockTypeLayer, position, total]
	);

	const handleSelectYear = (element: MouseEvent<HTMLButtonElement>) => {
		setIsModalVisible(false);
		setIsResultsVisible(false);
		const targetElement = element.target as HTMLButtonElement;
		const year = targetElement.innerText;
		const typeOfMap = restData?.mapType;
		const wrapperElement = targetElement.parentElement;

		if (!wrapperElement) return;
		Array.from(wrapperElement.children).forEach((element) => {
			element.classList.remove(mapboxStyle.mapboxMap__active);
		});
		targetElement.classList.add(mapboxStyle.mapboxMap__active);

		if (!map?.current) return;
		const layers = map.current.getStyle()?.layers || []; // get all layers\
		layers.forEach((layer) => {
			map?.current!?.setLayoutProperty(layer?.id, "visibility", "none");
		});

		map?.current!?.setLayoutProperty(`NombreEstados`, "visibility", "visible"); // State names
		map?.current!?.setLayoutProperty(`land`, "visibility", "visible"); // background color
		map?.current!?.setLayoutProperty(
			`${year}_${typeOfMap}_mexico_data`,
			"visibility",
			"visible"
		); // Geojson data

		// Set the new layer of information
		setTypeStates(`${year}_${restData?.mapType}_mexico_data`);
	};

	const handleOpenModal = () => {
		setIsOpenWindow(!isOpenWindow);
		setIsModalVisible(false);
		setIsResultsVisible(false);
	};

	const handleRecenter = () => {
		setWidthWindow(window.innerWidth);
		if (!widthWindow) return;
		let initialZoom = 3;
		if (widthWindow > 600 && widthWindow < 1000) {
			initialZoom = 3.6;
		} else if (widthWindow > 1000 && widthWindow < 1279) {
			initialZoom = 3.8;
		} else if (widthWindow >= 1280 && widthWindow < 1439) {
			initialZoom = 4;
		} else if (widthWindow >= 1440) {
			initialZoom = 4.6;
		}
		if (map.current) {
			map.current.flyTo({
				center: [-101.99, 24],
				zoom: initialZoom,
				essential: true,
				bearing: 0,
			});
		}
		handleSelectContent(
			{
				...blockDataLayer,
				content_type: "ButtonMapa",
				content_name: "Restablecer",
			},
			position,
			path || ""
		);
	};
	const handleRemoveBackground = () => {
		const element = document.querySelector(
			`.${mapboxStyle["mapboxMap__background"]}`
		);
		element?.classList.add(`${mapboxStyle["mapboxMap__removeBackground"]}`);
		setTimeout(() => {
			element?.classList.add(`${mapboxStyle["mapboxMap__removeElement"]}`);
		}, 600);
	};

	const handleViewResults = () => {
		setIsModalVisible(false);
		setIsResultsVisible(true);
	};

	// Init map
	useEffect(() => {
		if (map.current) return;
		const widthWindow = window.innerWidth;
		let initialZoom = 3;
		if (widthWindow > 600 && widthWindow < 1000) {
			initialZoom = 3.6;
		} else if (widthWindow > 1000 && widthWindow < 1279) {
			initialZoom = 3.8;
		} else if (widthWindow >= 1280 && widthWindow < 1439) {
			initialZoom = 4;
		} else if (widthWindow >= 1440) {
			initialZoom = 4.6;
		}

		map.current = new mapboxgl.Map({
			container: mapContainer.current || document.createElement("div"),
			style: styleUrl,
			center: [lng, lat],
			zoom: initialZoom,
			maxZoom,
			minZoom: initialZoom,
			pitch: 0,
		});
		map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

		map.current.on("load", function () {
			if (map?.current) {
				map?.current?.touchPitch?.disable();
				map?.current.dragRotate.disable();
			}
			const currentBounds = map?.current?.getBounds();
			if(currentBounds)
			map?.current?.setMaxBounds(currentBounds);
		});
		map.current.on("move", function () {
			setIsModalVisible(false);
			setIsResultsVisible(false);
		});
		const geocoder = new MapboxGeocoder({
			accessToken: process.env.NEXT_PUBLIC_MAPBOX || "",
			// @ts-ignore
			mapboxgl,
			language: "es",
			placeholder: "Ingresa tu estado",
			marker: false,
			countries: "MX",
		});

		map.current.on("load", function () {
			if (map.current && geoIdElement.current) {
				geoIdElement.current.appendChild(geocoder?.onAdd(map.current));
			}
		});
		geocoder.on("result", (e) => {
			const textSearch = e?.result?.text;
			const idRegex: RegExp = /^(\d+)_(\w+)_mexico_data$/;

			handleSelectContent(
				{
					...blockDataLayer,
					content_type: "BuscarMapa",
					content_name: textSearch,
					section: path,
				},
				position,
				path || ""
			);
			map.current?.once("moveend", function () {
				const point = map?.current?.project(e?.result?.center);
				if(!point) return;
				const features = map?.current?.queryRenderedFeatures(point) || [];
				const displayProperties = ["properties"];
				const displayFeatures: any = features?.map((feat: any) => {
					const displayFeat: any = {};
					displayProperties.forEach((prop: any) => {
						displayFeat[prop] = feat[prop];
						const year = feat?.layer?.id.match(idRegex)?.[1] || 0;
						const typeOfMap = feat?.layer?.id.match(idRegex)?.[2] || "";

						displayFeat.properties.year = year;
						displayFeat.properties.typeOfMap = typeOfMap;
					});
					return displayFeat;
				});
				const trueFeature = displayFeatures?.find(
					(element: any) =>
						Boolean(element?.properties?.FIRST_CANDIDATE_NAME) === true
				);

				const isInfo = Boolean(trueFeature);

				if (!isInfo) return;
				const hasFoto: boolean = trueFeature?.properties?.FOTO;
				const candidatesInfo: CandidateProps[] = [
					{
						isImage: hasFoto,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.FIRST_IMAGE
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(trueFeature?.properties?.FIRST_CANDIDATE_NAME),
						party:
							trueFeature?.properties?.FIRST_COALITION_PARTIES === "0"
								? trueFeature?.properties?.FIRST_PARTY?.split("-").join(" ")
								: trueFeature?.properties?.FIRST_COALITION_PARTIES?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(trueFeature?.properties?.FIRST_PERCENTAGE)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.FIRST_PARTY?.toUpperCase()
						),
					},
					{
						isImage:
							trueFeature?.properties?.typeOfMap === "gobernadores"
								? false
								: true,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.SECOND_IMAGE || " "
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(trueFeature?.properties?.SECOND_CANDIDATE_NAME),
						party:
							trueFeature?.properties?.SECOND_COALITION_PARTIES === "0"
								? trueFeature?.properties?.SECOND_PARTY?.split("-").join(" ")
								: trueFeature?.properties?.SECOND_COALITION_PARTIES?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(trueFeature?.properties?.SECOND_PERCENTAGE)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.SECOND_PARTY?.toUpperCase()
						),
					},
					{
						isImage:
							trueFeature?.properties?.typeOfMap === "gobernadores"
								? false
								: true,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.THIRD_IMAGE || " "
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(trueFeature?.properties?.THIRD_CANDIDATE_NAME),
						party:
							trueFeature?.properties?.THIRD_COALITION_PARTIES === "0"
								? trueFeature?.properties?.THIRD_PARTY?.split("-").join(" ")
								: trueFeature?.properties?.THIRD_COALITION_PARTIES?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(trueFeature?.properties?.THIRD_PERCENTAGE)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.THIRD_PARTY?.toUpperCase()
						),
					},
					{
						isImage:
							trueFeature?.properties?.typeOfMap === "gobernadores"
								? false
								: true,
						name: splitName(trueFeature?.properties?.FOURTH_CANDIDATE_NAME),
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.FOURTH_IMAGE || " "
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						party:
							trueFeature?.properties?.FOURTH_COALITION_PARTIES === "0"
								? trueFeature?.properties?.FOURTH_PARTY?.split("-").join(" ")
								: trueFeature?.properties?.FOURTH_COALITION_PARTIES?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(trueFeature?.properties?.FOURTH_PERCENTAGE)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.FOURTH_PARTY?.toUpperCase()
						),
					},
				];
				const resultsInfo: CandidateProps[] = [
					{
						isImage: hasFoto,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.FIRST_IMAGE_RESULT
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(
							trueFeature?.properties?.FIRST_CANDIDATE_NAME_RESULT
						),
						party:
							trueFeature?.properties?.FIRST_COALITION_PARTIES === "0"
								? trueFeature?.properties?.FIRST_PARTY_RESULT?.split("-").join(
										" "
								  )
								: trueFeature?.properties?.FIRST_COALITION_PARTIES_RESULT?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(
							trueFeature?.properties?.FIRST_PERCENTAGE_RESULT
						)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.FIRST_PARTY_RESULT?.toUpperCase()
						),
						totalVotes: trueFeature?.properties?.FIRST_VOTE_RESULT,
					},
					{
						isImage: hasFoto,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.SECOND_IMAGE_RESULT
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(
							trueFeature?.properties?.SECOND_CANDIDATE_NAME_RESULT
						),
						party:
							trueFeature?.properties?.SECOND_COALITION_PARTIES === "0"
								? trueFeature?.properties?.SECOND_PARTY_RESULT.split("-").join(
										" "
								  )
								: trueFeature?.properties?.SECOND_COALITION_PARTIES_RESULT?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(
							trueFeature?.properties?.SECOND_PERCENTAGE_RESULT
						)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.SECOND_PARTY_RESULT?.toUpperCase()
						),
						totalVotes: trueFeature?.properties?.SECOND_VOTE_RESULT,
					},
					{
						isImage: hasFoto,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.THIRD_IMAGE_RESULT
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(
							trueFeature?.properties?.THIRD_CANDIDATE_NAME_RESULT
						),
						party:
							trueFeature?.properties?.THIRD_COALITION_PARTIES === "0"
								? trueFeature?.properties?.THIRD_PARTY_RESULT?.split("-").join(
										" "
								  )
								: trueFeature?.properties?.THIRD_COALITION_PARTIES_RESULT?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(
							trueFeature?.properties?.THIRD_PERCENTAGE_RESULT
						)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.THIRD_PARTY_RESULT?.toUpperCase()
						),
						totalVotes: trueFeature?.properties?.THIRD_VOTE_RESULT,
					},
					{
						isImage: hasFoto,
						urlImage: `https://static-mapbox.nmas.com.mx/${
							trueFeature?.properties?.typeOfMap
						}/${trueFeature?.properties?.year}/${
							trueFeature?.properties?.typeOfMap === "presidenciales"
								? trueFeature?.properties?.FOURTH_IMAGE_RESULT
								: trueFeature?.properties?.ENTIDAD || " "
						}.webp`,
						name: splitName(
							trueFeature?.properties?.FOURTH_CANDIDATE_NAME_RESULT
						),
						party:
							trueFeature?.properties?.FOURTH_COALITION_PARTIES === "0"
								? trueFeature?.properties?.FOURTH_PARTY_RESULT?.split("-").join(
										" "
								  )
								: trueFeature?.properties?.FOURTH_COALITION_PARTIES_RESULT?.split(
										"-"
								  ).join(" "),
						percent: parseFloat(
							trueFeature?.properties?.FOURTH_PERCENTAGE_RESULT
						)
							?.toFixed(2)
							?.toString(),
						partyColor: getPartyColor(
							trueFeature?.properties?.FOURTH_PARTY_RESULT?.toUpperCase()
						),
						totalVotes: trueFeature?.properties?.FOURTH_VOTE_RESULT,
					},
				];

				setInfoModal({
					year: trueFeature?.properties?.STATE_RACE_YR || "0",
					state: trueFeature?.properties?.ESTADO || "",
					data: candidatesInfo,
					resultsData: resultsInfo,
				});
				setIsResultsVisible(false);
				setIsModalVisible(true);
				geocoder?.clear();
				if (widthWindow <= 834) {
					setIsOpenWindow(false);
				}
			});
		});
		geocoder.on("results", () => {
			setIsResultsVisible(false);
			setIsModalVisible(false);
		});
		map.current.on("load", function () {
			if (map.current) {
				const typeOfMap = restData.mapType;
				const typeOfMapRegex: RegExp = new RegExp(
					`(\\d+)_(${typeOfMap})_mexico_data$`
				);
				const layers = map.current.getStyle()?.layers || []; // get all layers

				const truelyLayers = layers.map((layer) => {
					const elementos = layer.id.match(typeOfMapRegex);
					if (elementos) {
						return elementos[1];
					} else {
						return "";
					}
				});

				const years = truelyLayers.filter(
					(element) => Boolean(element) === true
				);
				const orderYears = years.sort((a, b) => parseInt(b) - parseInt(a));
				setButtonsArray(orderYears);
			}
		});
		// return () => {
		// 	if (map.current) {
		// 		map.current?.remove();
		// 		map.current = null;
		// 		geocoder.onRemove();
		// 	}
		// };
	}, [
		lat,
		lng,
		maxZoom,
		minZoom,
		zoom,
		path,
		position,
		blockDataLayer,
		styleUrl,
		restData.mapType,
	]);

	useEffect(() => {
		if (window) setWidthWindow(window.innerWidth);
	}, []);

	useEffect(() => {
		if (!map.current) return;
		const widthWindow = window.innerWidth;
		map.current.on("mousemove", function (event: any) {
			if (map?.current?.loaded()) {
				const features = map.current.queryRenderedFeatures(event.point, {
					layers: [typeStates],
				});

				map.current.getCanvas().style.cursor = features.length ? "pointer" : "";
			}
		});

		map?.current?.on("click", typeStates, function (e: any) {
			const stateFeature: any = e?.features?.[0] || {};
			const typeOfMap = restData.mapType;
			const typeOfMapRegex: RegExp = new RegExp(
				`(\\d+)_(${typeOfMap})_mexico_data$`
			);
			const data = typeStates.match(typeOfMapRegex) || [];
			const year: string = data?.[1];
			const hasFoto: boolean = stateFeature?.properties?.FOTO;
			// const typeOfMap: string = data[2].toLowerCase();

			handleSelectContent(
				{
					...blockDataLayer,
					content_type: "estado",
					content_name: `${year}_${stateFeature?.properties?.ESTADO}`,
					section: path,
				},
				position,
				path || ""
			);
			if (stateFeature?.properties?.FIRST_PARTY === "0") return;
			const candidatesInfo: CandidateProps[] = [
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						(typeOfMap === "presidenciales"
							? stateFeature?.properties?.FIRST_IMAGE
							: stateFeature?.properties?.ENTIDAD) || " "
					}.webp`,
					name: splitName(stateFeature?.properties?.FIRST_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.FIRST_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FIRST_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.FIRST_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent: parseFloat(stateFeature?.properties?.FIRST_PERCENTAGE)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FIRST_PARTY?.toUpperCase()
					),
				},
				{
					isImage: typeOfMap === "gobernadores" ? false : true,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						(typeOfMap === "presidenciales"
							? stateFeature?.properties?.SECOND_IMAGE || " "
							: stateFeature?.properties?.ENTIDAD) || " "
					}.webp`,
					name: splitName(stateFeature?.properties?.SECOND_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.SECOND_COALITION_PARTIES === "0"
							? stateFeature?.properties?.SECOND_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.SECOND_COALITION_PARTIES?.split(
									"-"
							  ).join(" "),
					percent: parseFloat(stateFeature?.properties?.SECOND_PERCENTAGE)
						.toFixed(2)
						.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.SECOND_PARTY?.toUpperCase()
					),
				},
				{
					isImage: typeOfMap === "gobernadores" ? false : true,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						typeOfMap === "presidenciales"
							? stateFeature?.properties?.THIRD_IMAGE || " "
							: stateFeature?.properties?.ENTIDAD || " "
					}.webp`,
					name: splitName(stateFeature?.properties?.THIRD_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.THIRD_COALITION_PARTIES === "0"
							? stateFeature?.properties?.THIRD_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.THIRD_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent: parseFloat(stateFeature?.properties?.THIRD_PERCENTAGE)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.THIRD_PARTY?.toUpperCase()
					),
				},
				{
					isImage: typeOfMap === "gobernadores" ? false : true,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						typeOfMap === "presidenciales"
							? stateFeature?.properties?.FOURTH_IMAGE || " "
							: stateFeature?.properties?.ENTIDAD || " "
					}.webp`,
					name: splitName(stateFeature?.properties?.FOURTH_CANDIDATE_NAME),
					party:
						stateFeature?.properties?.FOURTH_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FOURTH_PARTY?.split("-")?.join(" ")
							: stateFeature?.properties?.FOURTH_COALITION_PARTIES?.split(
									"-"
							  )?.join(" "),
					percent: parseFloat(stateFeature?.properties?.FOURTH_PERCENTAGE)
						.toFixed(2)
						.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FOURTH_PARTY?.toUpperCase()
					),
				},
			];
			const resultsInfo: CandidateProps[] = [
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						typeOfMap === "presidenciales"
							? stateFeature?.properties?.FIRST_IMAGE_RESULT
							: stateFeature?.properties?.ENTIDAD || " "
					}.webp`,
					name: splitName(
						stateFeature?.properties?.FIRST_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.FIRST_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FIRST_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.FIRST_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent: parseFloat(stateFeature?.properties?.FIRST_PERCENTAGE_RESULT)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FIRST_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.FIRST_VOTE_RESULT,
				},
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						typeOfMap === "presidenciales"
							? stateFeature?.properties?.SECOND_IMAGE_RESULT
							: stateFeature?.properties?.ENTIDAD || " "
					}.webp`,
					name: splitName(
						stateFeature?.properties?.SECOND_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.SECOND_COALITION_PARTIES === "0"
							? stateFeature?.properties?.SECOND_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.SECOND_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent: parseFloat(
						stateFeature?.properties?.SECOND_PERCENTAGE_RESULT
					)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.SECOND_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.SECOND_VOTE_RESULT,
				},
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						typeOfMap === "presidenciales"
							? stateFeature?.properties?.THIRD_IMAGE_RESULT
							: stateFeature?.properties?.ENTIDAD || " "
					}.webp`,
					name: splitName(
						stateFeature?.properties?.THIRD_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.THIRD_COALITION_PARTIES === "0"
							? stateFeature?.properties?.THIRD_PARTY_RESULT?.split("-").join(
									" "
							  )
							: stateFeature?.properties?.THIRD_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent: parseFloat(stateFeature?.properties?.THIRD_PERCENTAGE_RESULT)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.THIRD_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.THIRD_VOTE_RESULT,
				},
				{
					isImage: hasFoto,
					urlImage: `https://static-mapbox.nmas.com.mx/${typeOfMap}/${year}/${
						typeOfMap === "presidenciales"
							? stateFeature?.properties?.FOURTH_IMAGE_RESULT
							: stateFeature?.properties?.ENTIDAD || " "
					}.webp`,
					name: splitName(
						stateFeature?.properties?.FOURTH_CANDIDATE_NAME_RESULT
					),
					party:
						stateFeature?.properties?.FOURTH_COALITION_PARTIES === "0"
							? stateFeature?.properties?.FOURTH_PARTY_RESULT.split("-").join(
									" "
							  )
							: stateFeature?.properties?.FOURTH_COALITION_PARTIES_RESULT?.split(
									"-"
							  ).join(" "),
					percent: parseFloat(
						stateFeature?.properties?.FOURTH_PERCENTAGE_RESULT
					)
						?.toFixed(2)
						?.toString(),
					partyColor: getPartyColor(
						stateFeature?.properties?.FOURTH_PARTY_RESULT?.toUpperCase()
					),
					totalVotes: stateFeature?.properties?.FOURTH_VOTE_RESULT,
				},
			];

			setInfoModal({
				year: stateFeature?.properties?.STATE_RACE_YR || "0",
				state: stateFeature?.properties?.ESTADO || "",
				data: candidatesInfo,
				resultsData: resultsInfo,
			});
			setIsResultsVisible(false);
			setIsModalVisible(true);
			setIsOpenWindow(false);
		});

		map?.current?.on("zoomend", (e: any) => {
			const typeOfZoom =
				(e?.originalEvent?.target as HTMLElement)?.getAttribute("title") || "";
			if (!typeOfZoom) return;
			handleSelectContent(
				{
					...blockDataLayer,
					content_type: "ButtonMapa",
					content_name: "ZoomMapasGobernadores",
				},
				position,
				path || ""
			);
		});
		return () => {};
	}, [typeStates, blockDataLayer, path, position, restData.mapType]);

	return (
		<>
			<div className={mapboxStyle["mapboxMap__wrapperMapboxMap"]}>
				<h2
					className={mapboxStyle["mapboxMap__title"]}
					style={{
						borderTop: `${
							restData.lineColor ? `4px solid ${restData.lineColor}` : ""
						}`,
						paddingTop: `${restData.lineColor ? "8px" : ""}`,
					}}
				>
					{restData.title}
				</h2>
				<div className={mapboxStyle["mapboxMap__wrapper"]}>
					<div
						className={`${mapboxStyle["mapboxMap__background"]}`}
						onClick={handleRemoveBackground}
					>
						Haz clic para interactuar con el mapa
					</div>

					<div
						ref={mapContainer}
						className={mapboxStyle["mapboxMap__map-container"]}
					/>
					{/* Start Modal */}
					<div
						className={`${mapboxStyle["mapboxMap__modal"]} ${
							isModalVisible
								? mapboxStyle["mapboxMap__modal-open"]
								: mapboxStyle["mapboxMap__modal-close"]
						}`}
					>
						<div className={`${mapboxStyle["mapboxMap__modal__wrapperHead"]}`}>
							<div
								className={`${mapboxStyle["mapboxMap__modal__wrapperInfo"]}`}
							>
								<div
									className={`${mapboxStyle["mapboxMap__modal__stateName"]}`}
								>
									{infoModal.state}
								</div>
								<span
									onClick={() => {
										setIsModalVisible(false);
									}}
								>
									<Icon
										name="close"
										className={`${mapboxStyle["mapboxMap__modal__closeIcon"]}`}
									/>
								</span>
							</div>

							<div
								className={`${mapboxStyle["mapboxMap__modal__wrapperInfo"]}`}
							>
								<div className={`${mapboxStyle["mapboxMap__modal__yearInfo"]}`}>
									{infoModal.year}
								</div>
								{restData?.mapType === "presidenciales" && (
									<button
										onClick={() => {
											handleViewResults();
										}}
										className={`${mapboxStyle["mapboxMap__modal__results"]}`}
									>
										Ver resultados nacionales
									</button>
								)}
							</div>
						</div>
						{Children.toArray(
							infoModal.data.map((candidate, index, array) => {
								return (
									<div
										className={`${mapboxStyle["mapboxMap__modal__wrapperCandidate"]}`}
										style={{
											display: `${
												candidate.name[0] === "0" || !candidate?.name?.[0]
													? "none"
													: ""
											}`,
											borderRadius: `${
												index === array.length - 1 ? "8px" : ""
											}`,
										}}
									>
										<div
											className={`${mapboxStyle["mapboxMap__modal__candidate"]}`}
										>
											<div
												className={`${mapboxStyle["mapboxMap__modal__infoCandidate"]}`}
											>
												<div
													style={{
														display: `${
															!candidate.urlImage || !candidate?.isImage
																? "none"
																: ""
														}`,
													}}
												>
													{!candidate.isImage ? (
														<></>
													) : (
														<Image
															src={candidate?.urlImage || ""}
															alt={candidate.name[0]}
															width={50}
															height={50}
															className={`${mapboxStyle["mapboxMap__modal__image"]}`}
														/>
													)}
												</div>
												<div
													className={`${mapboxStyle["mapboxMap__modal__wrapperCandidate"]}`}
												>
													<div
														className={`${mapboxStyle["mapboxMap__modal__paryName"]}`}
														style={{
															color: `${candidate?.partyColor || "gray"}`,
														}}
													>
														{candidate.party}
													</div>

													<span
														className={`${mapboxStyle["mapboxMap__modal__candidateName"]}`}
													>
														{candidate.name[0]} {candidate.name[1]}
													</span>
												</div>
											</div>
											<div>
												<div
													className={`${mapboxStyle["mapboxMap__modal__wrapperPercent"]}`}
												>
													<div
														className={`${mapboxStyle["mapboxMap__modal__numberPercent"]}`}
													>
														{candidate.percent}
													</div>
													<div
														className={`${mapboxStyle["mapboxMap__modal__percent"]}`}
													>
														%
													</div>
												</div>
											</div>
										</div>
										<div
											className={`${mapboxStyle["mapboxMap__modal__wrapperLine"]}`}
										>
											<div
												className={`${mapboxStyle["mapboxMap__modal__lineColor"]}`}
												style={{
													backgroundColor: `${candidate?.partyColor || "gray"}`,
													width: `${candidate?.percent || 0}%`,
												}}
											/>
										</div>
									</div>
								);
							})
						)}
					</div>
					{/* Finish Modal */}

					{/* Result Modal */}
					<div
						className={`${mapboxStyle["mapboxMap__modal"]} ${
							isResultsVisible
								? mapboxStyle["mapboxMap__modal-open"]
								: mapboxStyle["mapboxMap__modal-close"]
						}`}
					>
						<div className={`${mapboxStyle["mapboxMap__modal__wrapperHead"]}`}>
							<div
								className={`${mapboxStyle["mapboxMap__modal__wrapperInfo"]}`}
							>
								<div
									className={`${mapboxStyle["mapboxMap__modal__stateName"]}`}
								>
									Resultados Nacionales
								</div>
								<span
									onClick={() => {
										setIsResultsVisible(false);
									}}
								>
									<Icon
										name="close"
										className={`${mapboxStyle["mapboxMap__modal__closeIcon"]}`}
									/>
								</span>
							</div>

							<div
								className={`${mapboxStyle["mapboxMap__modal__wrapperInfo"]}`}
							>
								<div className={`${mapboxStyle["mapboxMap__modal__yearInfo"]}`}>
									{infoModal.year}
								</div>
							</div>
						</div>
						{Children.toArray(
							infoModal?.resultsData?.map((candidate, index, array) => {
								return (
									<div
										className={`${mapboxStyle["mapboxMap__modal__wrapperCandidate"]}`}
										style={{
											display: `${
												candidate.name[0] === "0" || !candidate?.name?.[0]
													? "none"
													: ""
											}`,
											borderRadius: `${
												index === array.length - 1 ? "8px" : ""
											}`,
										}}
									>
										<div
											className={`${mapboxStyle["mapboxMap__modal__candidate"]}`}
										>
											<div
												className={`${mapboxStyle["mapboxMap__modal__infoCandidate"]}`}
											>
												<div
													style={{
														display: `${
															!candidate.urlImage || !candidate?.isImage
																? "none"
																: ""
														}`,
													}}
												>
													{!candidate.isImage ? (
														<></>
													) : (
														<Image
															src={candidate?.urlImage || ""}
															alt={candidate.name[0]}
															width={50}
															height={50}
															className={`${mapboxStyle["mapboxMap__modal__image"]}`}
														/>
													)}
												</div>
												<div
													className={`${mapboxStyle["mapboxMap__modal__wrapperCandidate"]}`}
												>
													<div
														className={`${mapboxStyle["mapboxMap__modal__paryName"]}`}
														style={{
															color: `${candidate?.partyColor || "gray"}`,
														}}
													>
														{candidate.party}
													</div>

													<span
														className={`${mapboxStyle["mapboxMap__modal__candidateName"]}`}
													>
														{candidate.name[0]} {candidate.name[1]}
													</span>
												</div>
											</div>
											<div>
												<div
													className={`${mapboxStyle["mapboxMap__modal__wrapperPercent"]}`}
												>
													<div
														className={`${mapboxStyle["mapboxMap__modal__numberPercent"]}`}
													>
														{candidate.percent}
													</div>
													<div
														className={`${mapboxStyle["mapboxMap__modal__percent"]}`}
													>
														%
													</div>
												</div>
												<div
													className={`${mapboxStyle["mapboxMap__modal__noVotes"]}`}
												>
													{toCommaNotation(candidate?.totalVotes || 0)}
												</div>
											</div>
										</div>
										<div
											className={`${mapboxStyle["mapboxMap__modal__wrapperLine"]}`}
										>
											<div
												className={`${mapboxStyle["mapboxMap__modal__lineColor"]}`}
												style={{
													backgroundColor: `${candidate?.partyColor || "gray"}`,
													width: `${candidate?.percent || 0}%`,
												}}
											/>
										</div>
									</div>
								);
							})
						)}
					</div>
					{/* Finish Result Modal */}
					<Image
						className={mapboxStyle["mapboxMap__logo-bottom-left"]}
						src={"/assets/Logo/Logo-N+-Neutro-Oscuro.svg"}
						alt="nmas"
						width={50}
						height={50}
					/>
					<div className={`${mapboxStyle["mapboxMap__frameInfo"]}`}>
						<div className={`${mapboxStyle["mapboxMap__wrapperInfoFrame"]}`}>
							<span className={`${mapboxStyle["mapboxMap__sourceInfo"]}`}>
								{/* {restData?.source} */}
							</span>
							<div
								ref={geoIdElement}
								className={`${mapboxStyle["mapboxMap__search"]}`}
							/>
							{/* Start Buttons */}
							<div
								className={`${mapboxStyle["mapboxMap__wrapperContainerButtons"]}`}
							>
								<div className={`${mapboxStyle["mapboxMap__wrapperButtons"]}`}>
									{Children.toArray(
										buttonsArray?.map((year, index) => {
											return (
												<>
													<button
														key={index}
														className={`${
															mapboxStyle["mapboxMap__yearButton"]
														}  ${
															index === 0
																? mapboxStyle["mapboxMap__active"]
																: ""
														}`}
														onClick={(event: MouseEvent<HTMLButtonElement>) => {
															handleSelectYear(event);
															handleSelectContent(
																{
																	...blockDataLayer,
																	content_name: (
																		event.target as HTMLButtonElement
																	).innerText,
																	section: path,
																	content_type: "ButtonMapa",
																},
																position,
																path || "/"
															);
														}}
													>
														{year}
													</button>
												</>
											);
										})
									)}
								</div>
							</div>

							{/* Finish Buttons */}
							<div
								className={`${mapboxStyle["mapboxMap__wrapperDescription"]} ${
									isOpenWindow
										? mapboxStyle["mapboxMap__openWindow"]
										: mapboxStyle["mapboxMap__closeWindow"]
								}`}
							>
								<p
									className={`${mapboxStyle["mapboxMap__searchDescription"]} `}
								>
									{restData?.description}
								</p>
							</div>
							<div className={`${mapboxStyle["mapboxMap__line"]}`} />
							<div
								className={`${mapboxStyle["mapboxMap__wrapperInstructions"]}`}
							>
								<span
									className={`${mapboxStyle["mapboxMap__infoInstruction"]}`}
									style={{
										textDecoration: `${restData?.newsUrl ? "underline" : ""}`,
									}}
								>
									{restData?.newsUrl ? (
										<InteractiveLink
											blockData={{
												...blockDataLayer,
												content_type: "ButtonMapa",
												content_name: "Conoce la Metodologia N+",
											}}
											url={restData?.newsUrl || "/"}
											isLink={true}
										>
											Conoce la metodología
										</InteractiveLink>
									) : (
										<>Conoce la metodología</>
									)}
								</span>
								<div
									className={`${mapboxStyle["mapboxMap__openIcon"]} ${
										isOpenWindow
											? mapboxStyle["mapboxMap__arrowUp"]
											: mapboxStyle["mapboxMap__arrowDown"]
									}`}
									onClick={() => {
										handleOpenModal();
										handleSelectContent(
											{
												...blockDataLayer,
												content_type: "ButtonMapa",
												content_name: "MasInfo",
											},
											position,
											path || ""
										);
									}}
								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										width="24"
										height="25"
										viewBox="0 0 24 25"
										fill="none"
									>
										<path
											d="M7 14.6506L12 9.65063L17 14.6506H7Z"
											fill="white"
										/>
									</svg>
								</div>
							</div>
						</div>
					</div>
					<button
						className={`${mapboxStyle["mapboxMap__recenterButton"]}`}
						onClick={handleRecenter}
					/>
				</div>
			</div>
		</>
	);
};

export default MapboxMap;
