"use client";

import { Dispatch, SetStateAction } from "react";

import styles from "@/app/styles/Grid/Grid.module.scss";

import { FormatHour } from "@/app/helpers/timeHelpers/timeHelpers";

import type { CurrentShow } from "@/app/types";

export const TimeDisplay = ({
	currentShow,
	setViewGrid,
	viewGrid,
}: {
	currentShow: CurrentShow;
	setViewGrid: Dispatch<SetStateAction<boolean>>;
	viewGrid: boolean;
}) => {
	return (
		<div className={styles.wrapper_video_detail}>
			<div className={styles.live_description}>
				<p className={styles.live_label}>EN VIVO:</p>
				<h2 className={styles.description_program}>
					<span className={styles.title_video_program}>
						{currentShow?.title}
					</span>
					<span className={styles.time_video_program}>
						{`${FormatHour(currentShow?.airtime?.slice(0, 5) || "")}
                    - ${FormatHour(
											currentShow?.airtimeEnd?.slice(0, 5) || ""
										)}`}
					</span>
				</h2>
			</div>
			<button
				className={styles.button_minimize}
				aria-label="Minimizar grilla de programas"
				onClick={() => {
					setViewGrid(!viewGrid);
				}}
			>
				<svg
					width="20"
					height="17"
					viewBox="0 0 20 17"
					xmlns="http://www.w3.org/2000/svg"
				>
					<path d="M18 0.166626H2C0.9 0.166626 0 1.10413 0 2.24996V14.75C0 15.8958 0.9 16.8333 2 16.8333H18C19.1 16.8333 20 15.8958 20 14.75V2.24996C20 1.10413 19.1 0.166626 18 0.166626ZM6 14.75H2V9.54163H6V14.75ZM6 7.45829H2V2.24996H6V7.45829ZM12 14.75H8V9.54163H12V14.75ZM12 7.45829H8V2.24996H12V7.45829ZM18 14.75H14V9.54163H18V14.75ZM18 7.45829H14V2.24996H18V7.45829Z" />
				</svg>
				<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
					<path d="m7 14 5-5 5 5Z" />
				</svg>
			</button>
		</div>
	);
};
