import {
	Dispatch,
	SetStateAction,
	ReactNode,
	useState,
	createContext,
} from "react";
type Props = {
	children: ReactNode;
};
type InitialProps = {
	loadMoreIndex: number;
	setIsLoadMoreIndex: Dispatch<SetStateAction<number>>;
};
export const LoadMoreContentContext = createContext<InitialProps>({
	loadMoreIndex: 0,
	setIsLoadMoreIndex: () => {},
});

export const LoadMoreContentProvider = ({ children }: Props) => {
	const [loadMoreIndex, setIsLoadMoreIndex] = useState(0);
	return (
		<LoadMoreContentContext.Provider
			value={{
				loadMoreIndex,
				setIsLoadMoreIndex,
			}}
		>
			{children}
		</LoadMoreContentContext.Provider>
	);
};
