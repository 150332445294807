import { gql } from "@apollo/client";

export default gql`
	query GetDetailVideo($getDetailVideoId: String!) {
		getDetailVideo(id: $getDetailVideoId) {
			video {
				title
				titleTag
				summary
				channel
				cmsId
				date
				dateTime
				uploadDate
				dateModified
				duration
				id
				isVideo
				path
				contentUrl
				categoryId
				priorizedIds
				term {
					name
					url
				}
				thumbnail {
					alt
					width
					height
					imageUrl {
						webp
					}
				}
				hasProgramAd
			}
			isFullVersion
		}
	}
`;
