"use client";
import style from "@/app/styles/Molecules/Carousel.module.scss";
import Icon from "@/app/components/atoms/Icon/Icon";
import TooltipIcon from "@/app/components/molecules/tooltipedIcon/tooltipIcon";
import PlayerVodV2 from "@/app/components/molecules/PlayerVodV2/PlayerVodV2";

interface Props {
	item: any;
	activeIndex: number;
	isMuted: boolean;
	togglePlay: any;
	audioVideo: any;
	onVideoEnded: any;
	index: number;
	modalID: string;
	isDiscover?: boolean;
}

export default function VideoComponent({
	item,
	activeIndex,
	isMuted,
	togglePlay,
	audioVideo,
	onVideoEnded,
	index,
	modalID,
	isDiscover,
}: Props) {
	return (
		<div className={`${style["carousel__videoWrapper"]}`}>
			<div className={`${style["carousel__playerWrapper"]}`}>
				<div className={`${style["carousel__wrapperContent"]} `}>
					{index === activeIndex && (
						<div className={`${style["carousel__wrapperButton"]}`}>
							<button
								className={`${style["carousel__buttons"]} ${style["carousel__buttons-volume"]}`}
								onClick={() => audioVideo(activeIndex, !isMuted)}
							>
								<Icon name={isMuted ? "VolumeOff" : "VolumeUp"} />
							</button>
							<div className={`${style["carousel__buttons"]}`}>
								<TooltipIcon
									variant="share"
									variantDiscover={true}
									isDarkMode={true}
									dataCardDiscover={item}
									isDiscover={isDiscover}
								/>
							</div>
						</div>
					)}
				</div>
				<div className={`${style["carousel__videoPlayer"]}`}>
					<PlayerVodV2
						idVideo={item?.cmsid}
						autoplay={activeIndex === 0 ? true : false}
						mute
						controls={false}
						loop
						cmsid
						manualVodId={`${modalID}-${index}`}
						onVideoEnded={() => {
							onVideoEnded();
						}}
						currentPlaceholder={item?.image?.imageUrl}
					/>
					<span
						className="overlay"
						onClick={() => {
							togglePlay(activeIndex);
						}}
					/>
				</div>
			</div>
		</div>
	);
}
