"use client";
import { Children } from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import programs from "@/app/styles/Blocks/BloqueProgramas.module.scss";
import { FieldTab } from "./interfaces/ProgramasResponse";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

export const NavPrograms = ({ fieldTabs }: { fieldTabs: FieldTab[] }) => {
	const pathName = usePathname();

	// * Remove the NavProgram
	if (pathName?.includes("por-el-planeta") || pathName?.includes("nmas-focus"))
		return null;

	// * Set the order to display
	const specificOrder = ["N+", "FORO", "N+ Local", "N+ Media"];

	fieldTabs?.sort((a, b) => {
		const indexA = specificOrder.indexOf(a.real_name);
		const indexB = specificOrder.indexOf(b.real_name);
		return indexA - indexB;
	});

	const blockDataLayer = {
		event: "select_content",
		clickBloque: 0,
		colorBloque: "Dark",
		content_name: "",
		contenidosBloque: `${fieldTabs?.length} contenidos`,
		countWidget: 1,
		descripcionBloque: "",
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: 1,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: "TabsDeProgramas",
		tipoElemento: "LinkText",
		tituloBloque: "",
	};
	return (
		<>
			<ul className={`${programs["programs__nav"]}`}>
				{Children.toArray(
					fieldTabs?.map(({ real_name, url }, i) => {
						blockDataLayer.content_name = `SHOW>${url}`
						blockDataLayer.descripcionBloque = `BloqueProgramas${real_name}`
						blockDataLayer.tituloBloque = real_name;
						return (
							<InteractiveLink 
								url={url} className={`${programs["programs__nav--hover"]} ${programs["programs__nav__item"]} ${`${pathName}` === url ? programs["programs__nav--active"] : ""}`} 
								title={real_name}
								blockData={{...blockDataLayer, contentTitle: real_name}}
								cardPosition={i}
							>
								{real_name}
							</InteractiveLink>
						);
					})
				)}
			</ul>
			<div
				style={{
					height: "1px",
					width: "100%",
					backgroundColor: "#224C60",
					marginBottom: "16px",
				}}
			/>
		</>
	);
};
