import { gql } from "@apollo/client";

export default gql`
query GetPolicyBlock($policyBlockId: String!, $blockType: String!) {
  getPolicyBlock(PolicyBlockId: $policyBlockId, BlockType: $blockType) {
    title
    body
    hasBreadcrumb
  }
}
`