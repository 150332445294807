import BlockDetailVideo from "./BlockDetailVideo";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetDetailVideo from "@/app/queries/getDetailVideo";
import NewsArticle from "@/app/site-tools/structured-data/NewsArticle";
import { VideoObject } from "@/app/site-tools/structured-data/VideoObject";

export const BlockDetailVideoLoader = async({ id, position=0, total, type }: any) => {
	const { data } = await fetchApolloData(GetDetailVideo, {getDetailVideoId: id});
	const mainVid = data?.getDetailVideo?.video;
	return (
		<>
			<VideoObject mainVid={mainVid} />
			<NewsArticle mainNews={mainVid} />
			<BlockDetailVideo
				id="1"
				videos={{mainVideo: mainVid}}
				isNotStatic={true}
				position={position}
				total={total}
				type={type}
				isFullVersion={data?.getDetailVideo?.isFullVersion}
			/>
		</>
	);
}
export default BlockDetailVideoLoader;