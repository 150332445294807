import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetLiveBlogStructuredData from "@/app/queries/getLiveBlogStructuredData";
import replaceUnderscoresInKeys from "@/app/helpers/replaceUnderscores/replaceUnderscores";

export const LiveBlog = async ({entity}: any) => {
	const { data, ...rest } = await fetchApolloData(GetLiveBlogStructuredData, {
		path: entity.path,
		});

	if (rest?.error) {
		console.error(rest?.error);
	}

	let LiveBlog = replaceUnderscoresInKeys(data?.getLiveBlogStructuredData);

	const removeNull: any = (obj: Record<string, any>) => {
		if (obj === null) {
			return obj;
		}

		return Object.fromEntries(
			Object.entries(obj || {})
				.filter(([_, value]) => value !== null)
				.map(([key, value]) => [
					key,
					value === Object(value) ? removeNull(value) : value,
				])
		);
	};

	LiveBlog = removeNull(LiveBlog);

	const entries = LiveBlog?.liveBlogUpdate
		? Object.values(LiveBlog.liveBlogUpdate)
		: [];

	if (LiveBlog) {
		LiveBlog.liveBlogUpdate = entries;
	}

	return (
		<script
			type="application/ld+json"
			dangerouslySetInnerHTML={{
				__html: JSON.stringify(LiveBlog) ?? "",
			}}
		/>
	);	
}

export default LiveBlog;