import GetStories from '@/app/queries/getStories';
import styles from '@/app/styles/Blocks/BloqueWebStories.module.scss';
import GwsContainer from '../../molecules/CarouselVOD/GwsContainer/GwsContainer';
import fetchApolloData from '@/app/helpers/fetchApolloData/fetchApolloData';
import { BlockProps } from '@/app/types/BlockProps.type';
import { upperCamelCase } from '@/app/helpers/upperCamelCase/upperCamelCase';
export const BloqueWebStories = async ({type, id, position = 0, total}:BlockProps) => {
  const {data, ...rest} = await fetchApolloData(GetStories, {getStoriesId: id});

  if(rest?.errors){
    console.error(rest?.errors);
  }
  const blockStories = data?.getStories;
  const {isRound = false, relationships} = blockStories;
  const {stories} = relationships;

  const blockType = type
		.replace("block_content--", "")
		.split("_")
		.join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: `${blockStories?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${stories?.length} contenidos`,
		countWidget: total,
		content_name: "GWS-",
		descripcionBloque: blockStories?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockStories?.blockTitle ?? undefined,
	};
  return (
    <section className={isRound ? `${styles?.gws_container}` : `${styles?.gws_container} ${styles?.gws_container_square}`}>
      <GwsContainer gwstories={stories} isRound={isRound} blockData={blockDataLayer} />
    </section>
  );
}
export default BloqueWebStories;