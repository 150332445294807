import GetVideoNewsBlock from "@/app/queries/getVideoNewsBlock";
import styles from "@/app/styles/Blocks/BloqueVod.module.scss";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
import { BlockPropsStructuredData } from "@/app/types/BlockProps.type";
import CarouselVOD from "../../molecules/CarouselVOD/CarouselVOD";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

export const BloqueVOD = async ({ type, id, position = 0, total, enabledStructureData = false }: BlockPropsStructuredData) => {
	const { data, ...rest } = await fetchApolloData(GetVideoNewsBlock, { fromSite: "video", videoNewsBlockId: id });

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const blockData = data?.getVideoNewsBlock;
	const blockTitle = blockData?.blockTitle;
	const blockSummary = blockData?.blockDescription;
	const hasLink = blockData?.loadMoreLink?.uri !== null ? true : false;
	const loadMoreText = blockData?.loadMoreLink?.title ?? "Ver más";
	const loadMoreUri = blockData?.loadMoreLink?.uri ?? "/";
	const { relationships = {} } = blockData ?? {};
	const videos = relationships?.field_videos;
	const isDark = blockData?.isDark;
	const border = blockData?.borderColor;
	const isTopPos = (position === 0) ? true : false;
	const blockType = type
		.replace("block_content--", "")
		.split("_")
		.join(" ");
	const blockDataLayer = {
		event: "select_content",
		// clickBloque: 0,
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${videos?.length} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
		contentTitle: undefined,
		date: videos?.date || videos?.dateTime,
	};
	const withTrailingSlash =
		loadMoreUri.replace("internal:", "").at(-1) === "/"
			? loadMoreUri.replace("internal:", "")
			: loadMoreUri.replace("internal:", "") + "/";


	return (
		<section className={(isDark && isTopPos) ? `${styles?.block_video_vod} ${styles?.dark} ${styles?.block_top_pos}` : (!isTopPos && isDark) ? `${styles?.block_video_vod} ${styles?.dark}` : (isTopPos && !isDark) ? `${styles?.block_video_vod} ${styles?.block_top_pos}` : `${styles?.block_video_vod}`}>
			<div className={styles?.block_inner_wrapper}>
				<WidgetTitle title={blockTitle} text={blockSummary} lineColor={border} isDark={isDark} />
				<CarouselVOD
					videos={videos}
					isDark={isDark}
					blockData={blockDataLayer}
					enabledStructureData={enabledStructureData}
				/>
				{hasLink && (
					<div className={styles?.btn_cta}>
						<InteractiveLink
							url={ withTrailingSlash }
							title={loadMoreText}
							blockData={{...blockDataLayer, content_type: "widget", content_name: "LINK>"}}
							isLink={true}
						>
							{loadMoreText}
						</InteractiveLink>
					</div>
				)}
			</div>
		</section>
	);
}
export default BloqueVOD;
