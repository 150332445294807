import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetMapboxMap from "@/app/queries/getMapboxMap";
import { GetMapboxMapType } from "./interfaces/MapboxMap.interface";
import MapboxMap from "./MapboxMap";
type ComponentProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};

export const BloqueMapboxMap = async ({
	id,
	type,
	position = 0,
	total,
}: ComponentProps) => {
	const { data, ...rest } = await fetchApolloData(GetMapboxMap, {
		mapboxId: id,
	});
	const { styleUrl, ...propertyRest }: GetMapboxMapType =
		data.getMapboxMap || {};
	return (
		<>
			<MapboxMap
				restData={propertyRest}
				styleUrl={styleUrl}
				type={type}
				position={position}
				total={total}
			/>
		</>
	);
};
