"use client";
import React, { useRef, useEffect, useCallback } from "react";

interface InfiniteScrollProps {
	loadMore: () => void;
	hasMore: boolean;
	isLoading: boolean;
	children?: React.ReactNode;
	root?: HTMLElement | null;
	rootMargin?: string;
	threshold?: number;
}

function InfiniteScroll({
	loadMore,
	hasMore,
	isLoading,
	children,
	root = null,
	rootMargin = "100px",
	threshold = 0.1,
}: InfiniteScrollProps) {
	const sentinelRef = useRef<HTMLDivElement | null>(null);

	const handleLoadMore = useCallback(async () => {
		await loadMore();
	}, [loadMore]);

	useEffect(() => {
		const options = {
			root,
			rootMargin,
			threshold,
		};

		const observer = new IntersectionObserver((entries) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting && hasMore && !isLoading) {
					handleLoadMore();
				}
			});
		}, options);

		const currentSentinel = sentinelRef.current;

		if (currentSentinel) {
			observer.observe(currentSentinel);
		}

		return () => {
			if (currentSentinel) {
				observer.unobserve(currentSentinel);
			}
		};
	}, [handleLoadMore, hasMore, isLoading, root, rootMargin, threshold]);

	return (
		<>
			{children}
			{hasMore && <div ref={sentinelRef} />}
			{isLoading && (
				<div style={{ textAlign: "center", margin: "20px 0" }}>
					<div className="spinner">
						<div />
						<div />
						<div />
						<div />
					</div>
				</div>
			)}
		</>
	);
}

export default InfiniteScroll;
