import { gql } from "@apollo/client";
export default gql`
query GetProgramTab($programTabId: String!, $pagePath: String!) {
	getProgramTab(programId: $programTabId, pagePath: $pagePath) {
	   title
	   cmsName
	  field_programas {
		field_horario
		path
		title
		alt_image
		image_16_9
		image_16_9_desktop
	  }
	  field_tabs {
			real_name
			url
			name
	  }
	}
}
`;
