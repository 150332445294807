import { NavPrograms, ProgramCards } from "./";
import programs from "@/app/styles/Blocks/BloqueProgramas.module.scss";
import { FieldPrograma, FieldTab } from "./interfaces/ProgramasResponse";
import { BlockDataType } from "@/app/types/BlockDataType";

function Programs({
	fieldProgramas,
	fieldTabs,
	title,
	loading,
	...rest
}: {
	fieldProgramas: FieldPrograma[];
	fieldTabs: FieldTab[];
	title: string;
	loading: "eager" | "lazy";
	blockData: BlockDataType
}) {
	return (
		<>
			<h1 className={`${programs["programs__title"]}`}>{title}</h1>
			<NavPrograms fieldTabs={fieldTabs} />
			<ProgramCards fieldPrograms={fieldProgramas} loading={loading} blockData={rest?.blockData}/>
		</>
	);
}

export default Programs;
