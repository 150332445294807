import { gql } from "@apollo/client";

export default gql`
  query GetProgramDetail($getProgramDetailId: String!) {
    getProgramDetail(id: $getProgramDetailId) {
      type
      id
      blockTitle
      blockSummary
      channelName
      showId
      thumbnail {
        imageUrl {
          webp
        }
        alt
        caption
        width
        height
      }
      schedule
      position
      borderColor
      category
      showAnchor
      anchors {
        id
        firstName
        lastName
        path
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
      }
      showAsEpisodes
      showAllEpisodes
      episodes {
        id
        title
        titleTag
        summary
        duration
        date
        dateTime
        cmsId
        channel
        path
        term {
          name
          url
        }
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
        isVideo
      }
      showInTabs
      customVideos {
        id
        title
        titleTag
        summary
        duration
        date
        dateTime
        cmsId
        channel
        path
        term {
          name
          url
        }
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
        isVideo
      }
      datePublished
      customBlocks {
        data {
          type
          id
          position
        }
      }
    }
  }
`;