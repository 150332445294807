"use client";

import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import Link from "next/link";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { usePathname } from "next/navigation";
declare const window: WindowWithDataLayer;
type InteractiveLinkProps = {
	url?: any;
	blockData?: any;
	title?: any;
	children?: any;
	cardPosition?: any;
	isLink?: any;
	className?: string;
	target?: string;
	params?: string;
	action: (event: React.MouseEvent<HTMLButtonElement>, index: number) => void;
};

export default function ButtonOpen({
	blockData,
	title,
	children,
	cardPosition,
	className = "",
	action,
}: InteractiveLinkProps) {
	blockData.contentTitle = title;
	const path = usePathname();
	return (
		<button
			title={title || "Noticia N+"}
			onClick={(event) => {
				handleDataLayerOnClick(blockData, cardPosition, path, false);
				action(event, cardPosition);
			}}
			className={className}
		>
			{children}
		</button>
	);
}

