import { BlockProps } from "@/app/types/BlockProps.type";
import GetHorizontalButtons from "@/app/queries/getHorizontalButtons";
// import { HorizontalButtons } from "./types/BloqueBotoneraHorizontal";
import styles from "@/app/styles/Blocks/BloqueBotoneraHorizontal.module.scss";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import LinkSimple from "../../atoms/LinkSimple/LinkSimple";
import { LinkInterface } from "@/app/types/Link.type";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";

export const BloqueBotoneraHorizontal = async ({
	type,
	id,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetHorizontalButtons, {
		getHorizontalButtonsId: id,
	});
	if (rest?.errors) {
		// console.error(rest?.errors);
		rest?.errors.forEach((err: any) => {
			console.error(err);
			throw new Error(err?.extensions?.stacktrace);
		});
	}
	const blockData = data?.getHorizontalButtons; // :HorizontalButtons
	const { hasTitle, links, isDark, variant } = blockData;

	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		content_name: "LINK>",
		content_type: undefined,
		contenidosBloque: `${links?.length} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
	};

	const isNmedia = variant === "N+Media" ? true : false;

	const trueDark = isDark && !isNmedia ? true : false;

	return (
		<section
			className={
				trueDark
					? `${styles?.horizbtns} ${styles?.horizbtns__dark}`
					: (isNmedia && trueDark) || (isNmedia && !trueDark)
					? `${styles?.horizbtns} ${styles?.horizbtns__nmediaSection}`
					: `${styles?.horizbtns}`
			}
			style={{
				paddingTop: `${position === 0 ? "0px" : ""}`,
			}}
		>
			<div className={styles?.horizbtns__innerwrapper}>
				{hasTitle && !isNmedia && (
					<WidgetTitle
						title={blockData?.blockTitle}
						text={blockData?.blockDescription}
						lineColor={blockData?.borderColor}
						isDark={isDark}
					/>
				)}
				<nav className={styles?.horizbtns__navigation}>
					<ul
						className={
							isNmedia
								? `${styles?.horizbtns__navlist} ${styles?.horizbtns__navlist__nmediaList}`
								: `${styles?.horizbtns__navlist}`
						}
					>
						{links.map((link: LinkInterface, i: number) => {
							
							return (
								<li
									key={link?.id}
									className={
										isNmedia
											? `${styles?.horizbtns__navitem} ${styles?.horizbtns__navitem__nmedia}`
											: `${styles?.horizbtns__navitem}`
									}
								>
									<LinkSimple
										link={link}
										blockData={{ ...blockDataLayer, contentTitle: link?.title }}
										cardPosition={i + 1}
										isNmas={isNmedia}
									/>
								</li>
							);
						})}
					</ul>
				</nav>
			</div>
		</section>
	);
};
export default BloqueBotoneraHorizontal;