"use client";

import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

import PlayerStreaming from "../../PlayerStreaming/PlayerStreaming";
import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";

import styles from "@/app/styles/Cards/CardLiveMinuteToMinute.module.scss";

import {
	FormatHour,
	secondsToMinutes,
} from "@/app/helpers/timeHelpers/timeHelpers";
import PlayerVodV2 from "../../PlayerVodV2/PlayerVodV2";
import InteractiveLink from "@/app/components/organisms/BloqueHeroGrid/InteractiveLink";
import SendDataLayer from "@/app/hooks/analytics/SendDataLayer";
import Icon from "@/app/components/atoms/Icon/Icon";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import BaseSnackbar from "../../Snackbar/BaseSnackbar";
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton,
} from "react-share";
import { ThreadsButton } from "@/app/components/buttons/ButtonShareSocial/ThreadsButton";
import { usePathname } from "next/navigation";

export default function CardLiveMinuteToMinute({
	cardData = {},
	isLive,
	setMaxHeight,
	blockDataLayer,
}: any) {
	const { colorBloque = 'light' } = blockDataLayer;
	const { liveChannel, showBreaking, mainContent, showTitle } = cardData ?? {};
	const [isPlaying, setPlaying] = useState(false);
	const isVideoSP = !!mainContent?.cmsID;
	const [isSocialOpen, setIsSocialOpen] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const path = usePathname();
	const modalShare = useRef<any>(null);
	const BASE_URL =
		process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "") ||
		"https://www.nmas.com.mx/";
	const handleClose = () => setIsOpen(false);

	const liveData = {
		ui: liveChannel?.channelUI,
		path: liveChannel?.channelBS,
		uid: liveChannel?.channelUID,
	};

	const wrapperRef = useRef<any>(null);

	useEffect(() => {
		if (wrapperRef.current) {
			const h2 = wrapperRef?.current?.offsetHeight;
			setMaxHeight(() => h2);
		}
	});
	useEffect(() => {
		if (isSocialOpen) {
			document.addEventListener("click", handleOutClick);
		} else {
			document.removeEventListener("click", handleOutClick);
		}

		return () => {
			document.removeEventListener("click", handleOutClick);
		};
	}, [isSocialOpen]);
	const handleOutClick = (e: any) => {
		if (modalShare.current && !modalShare.current?.contains(e.target)) {
			setIsSocialOpen(false);
		}
	};
	const handleShareSocial = (socialName: string) => {
		handleDataLayerOnClick(
			{
				...blockDataLayer,
				content_name: `${socialName}`,
				tipoElemento: `${socialName}`,
			},
			1,
			shareUrl?.replace(BASE_URL, ""),
			true
		);
	};
	const handleOpenShare = (update: boolean) => {
		setIsSocialOpen(update);
	};
	const endHour = (hour: any, duration: any) => {
		if (!hour) return "00:00";
		const [hourly, minute, second] = hour?.split(":")?.map(Number) ?? [0, 0, 0];
		// Calcular la nueva hora en segundos
		const totalSeconds = hourly * 3600 + minute * 60 + second + duration * 60;

		// Obtener las nuevas horas, minutos y segundos
		const newHour = Math.floor(totalSeconds / 3600);
		const newMinute = Math.floor((totalSeconds % 3600) / 60);
		const newSecond = totalSeconds % 60;

		// Formatear la nueva hora
		return `${String(newHour).padStart(2, "0")}:${String(newMinute).padStart(
			2,
			"0"
		)}:${String(newSecond).padStart(2, "0")}`;
	};
	const copyToClipboard = () => {
		setIsOpen(true);
		setTimeout(() => {
			setIsOpen(false);
		}, 2000);

		handleDataLayerOnClick(
			{
				...blockDataLayer,
				content_name: "CopyLink",
				tipoElemento: "CopyLink",
				content_type: "CopyLink",
			},
			1,
			shareUrl?.replace(BASE_URL, ""),
			true
		);
	};

	const shareUrl = `${BASE_URL}${cardData?.mainContent?.link}`;
	const titleShareUrl = cardData?.mainContent?.title;

	return (
		<>
			<div
				className={`${styles.card_live__breaking}
				`}
				style={{
					...(cardData?.lineColor && !showBreaking
						? { borderTop: `4px solid ${cardData?.lineColor}` }
						: null),
				}}
			>
				{showBreaking && (
					<span className={styles.card_live__breaking__badge}>
						{cardData?.breakingText}
					</span>
				)}
				{showTitle && (
					<>
						{cardData?.blockTitle && (
							<h1 className={styles.card_live__breaking__heading}>
								{cardData?.blockTitle}
							</h1>
						)}
						{cardData?.blockDescription && (
							<p className={styles.card_live__breaking__description}>
								{cardData?.blockSubtitle}
							</p>
						)}
					</>
				)}
			</div>
			<figure
				className={`${styles.card_live__wrapper} ${
					cardData?.isDark ? styles.card_live__dark : ""
				}`}
				ref={wrapperRef}
			>
				<div className={styles.card_live__wrapper__image}>
					{isLive ? <PlayerStreaming {...liveData} /> : null}
					{!isLive && !isVideoSP ? (
						<InteractiveLink
							url={mainContent?.link ?? "/"}
							title={mainContent?.title}
							blockData={{
								...blockDataLayer,
								content_name: "NEW>",
							}}
							cardPosition={1}
						>
							<div
								style={{
									position: "relative",
									width: "100%",
									height: "100%",
									overflow: "hidden",
									aspectRatio: "16/9",
								}}
							>
								<Image
									src={mainContent?.image?.url ?? "/assets/placeholder_.svg"}
									width={344}
									height={193.11}
									style={{ width: "100%", height: "auto" }}
									alt={mainContent?.image?.alt ?? "Imagen default"}
									sizes="(max-width: 768px) 100vw, (max-width: 1200px) 88vw, 50vw"
								/>
							</div>
						</InteractiveLink>
					) : null}
					{!isLive && isVideoSP && (
						<>
							<SendDataLayer
								blockData={{
									...blockDataLayer,
									eventAction: "tap_to_play",
									content_name: "VOD>",
								}}
								cardPosition={1}
							>
								{!isPlaying ? (
									<div
										style={{
											position: "relative",
											width: "100%",
											height: "100%",
											overflow: "hidden",
											aspectRatio: "16/9",
										}}
										onClick={() => setPlaying(true)}
									>
										<Image
											src={mainContent?.image?.url}
											alt={mainContent?.image?.alt}
											width={397}
											height={222.93}
											sizes="(max-width: 768px) 100vw, (max-width: 1200px) 88vw, 50vw"
										/>
									</div>
								) : (
									<PlayerVodV2
										idVideo={mainContent?.cmsID}
										type="clips"
										cmsid={true}
										mute={true}
										autoplay={true}
										currentPlaceholder={mainContent?.image?.url}
									/>
								)}
							</SendDataLayer>
							{!isPlaying && (
								<span
									className={styles.card_live__wrapper__button}
									onClick={() => setPlaying(true)}
									style={{ content: "url" }}
									data-dynamic-content={secondsToMinutes(
										mainContent?.duration ?? 0
									)}
								>
									{secondsToMinutes(mainContent?.duration ?? 0)}
									{/* <PlayArrowSharpIcon /> */}
								</span>
							)}
						</>
					)}
				</div>
				<figcaption
					className={`${styles.card_live__wrapper__info} ${
						isLive ? styles.card_live__wrapper__info_live : ""
					}`}
				>
					{isLive && (
						<p className={styles.card_live__wrapper__info__detail}>
							<>
								<span
									className={styles.card_live__wrapper__info__detail__live}
								/>
								{"EN VIVO: "}
								<span
									className={styles.card_live__wrapper__info__detail__program}
								>
									{sanitizeText({
										text: liveChannel?.currentShow?.name,
										isTruncated: true,
										maxCharacters: 35,
									})}
								</span>
								<span className={styles.card_live__wrapper__info__detail__hour}>
									{`${FormatHour(
										liveChannel?.currentShow?.hour?.slice(0, 5) || ""
									)}
									- ${FormatHour(
										endHour(
											liveChannel?.currentShow?.hour,
											liveChannel?.currentShow?.duration
										).slice(0, 5) || ""
									)}`}
								</span>
							</>
						</p>
					)}
					{!isLive && (
						<p className={styles.card_live__wrapper__info__caption}>
							{sanitizeText({
								text: mainContent?.term ?? "",
								maxCharacters: 35,
								isTruncated: true,
								isShowEmptyPlaceholder: true,
								isShowEllipsis: true,
							})}
						</p>
					)}

					<div className={styles.card_live__wrapper_info_lineclamp}>
						<h2
							className={`${
								styles.card_live__wrapper__info__lineclamp__title
							} ${
								isLive
									? styles.card_live__wrapper__info__lineclamp__title_live
									: ""
							}`}
							onClick={() => null}
						>
							{mainContent?.link !== null ? (
								<InteractiveLink
									url={mainContent?.link ?? "/"}
									title={mainContent?.title}
									cardPosition={0}
									blockData={{
										...blockDataLayer,
										content_name: ` ${!isLive && isVideoSP ? "VOD>" : "NEW>"}`,
									}}
								>
									{cardData?.liveTitle ?? cardData?.mainContent?.title}
								</InteractiveLink>
							) : (
								<>{cardData?.liveTitle ?? cardData?.mainContent?.title}</>
							)}
						</h2>
					</div>
					<p className={`${styles.card_live__wrapper__info__body}`}>
						{mainContent?.link !== null ? (
							<InteractiveLink
								url={mainContent?.link ?? "#"}
								title={mainContent?.title}
								cardPosition={0}
								blockData={{
									...blockDataLayer,
									content_name: ` ${!isLive && isVideoSP ? "VOD>" : "NEW>"}`,
								}}
							>
								{sanitizeText({
									text:
										cardData?.liveDescription ??
										cardData?.mainContent?.description,
									isTruncated: true,
									maxCharacters: undefined,
									isShowEmptyPlaceholder: true,
								})}
							</InteractiveLink>
						) : (
							<>
								{sanitizeText({
									text:
										cardData?.liveDescription ??
										cardData?.mainContent?.description,
									isTruncated: true,
									maxCharacters: undefined,
									isShowEmptyPlaceholder: true,
								})}
							</>
						)}
					</p>

					{!isLive && (
						<SendDataLayer
							className={styles.card_live__tooltip}
							blockData={{
								...blockDataLayer,
								content_name: "NEW>",
							}}
							cardPosition={0}
						>
							{/* <TooltipIcon
								variant="share"
								variantDiscover={true}
								isDarkMode={cardData?.isDark}
								dataCardDiscover={cardData?.mainContent}
							/> */}

							{!isSocialOpen ? (
								<Icon
									name="share"
									className={`${styles["card_live__share__icon"]} ${
										colorBloque?.toLowerCase() === "light"
											? styles["card_lice__share__icon--light"]
											: styles["card_live__share__icon--dark"]
									}`}
									onClick={() => {
										handleOpenShare(true);
									}}
								/>
							) : (
								<Icon
									name="close"
									className={`${styles["card_live__share__icon"]} ${
										styles["card_live__share__icon--close"]
									} ${
										colorBloque?.toLowerCase() === "light"
											? styles["card_lice__share__icon--light"]
											: styles["card_live__share__icon--dark"]
									}`}
									onClick={() => {
										handleOpenShare(false);
									}}
									style={{
										"--lineColorClose": `${
											colorBloque?.toLowerCase() === "light" ? "#000" : "#fff"
										}`,
									}}
								/>
							)}

							<div
								className={`${styles["card_live__share__wrapperIcons"]} ${
									isSocialOpen && styles["card_live__share__wrapperIcons--open"]
								}`}
								ref={modalShare}
							>
								{/* Copy */}
								<div>
									<CopyToClipboard
										text={shareUrl || "/"}
										onCopy={() => {
											copyToClipboard();
										}}
									>
										<Icon
											name="chain"
											className={`${
												colorBloque?.toLowerCase() === "light"
													? styles["card_lice__share__icon--light"]
													: styles["card_live__share__icon--dark"]
											}`}
										/>
									</CopyToClipboard>
								</div>
								{/* Whats */}
								<div>
									<WhatsappShareButton
										url={shareUrl || ""}
										onClick={() => {
											handleShareSocial("whatsapp");
										}}
									>
										<Icon
											name="WhatsApp"
											className={`${
												colorBloque?.toLowerCase() === "light"
													? styles["card_lice__share__icon--light"]
													: styles["card_live__share__icon--dark"]
											}`}
										/>
									</WhatsappShareButton>
								</div>
								{/* Facebook */}
								<div>
									<FacebookShareButton
										url={shareUrl || ""}
										onClick={() => {
											handleShareSocial("facebook");
										}}
									>
										<Icon
											name="Facebook"
											className={`${
												colorBloque?.toLowerCase() === "light"
													? styles["card_lice__share__icon--light"]
													: styles["card_live__share__icon--dark"]
											}`}
										/>
									</FacebookShareButton>
								</div>
								{/* Twitter */}
								<div>
									<TwitterShareButton
										url={shareUrl || ""}
										onClick={() => {
											handleShareSocial("twitter");
										}}
										title={titleShareUrl}
										via={"nmas"}
									>
										<Icon
											name="Twitter"
											className={`${styles['card_live__share__icon--twitter']} ${
												colorBloque?.toLowerCase() === "light"
													? styles["card_live__share__icon--light"]
													: styles["card_live__share__icon--dark"]
											}`}
										/>
									</TwitterShareButton>
								</div>
								{/* Threads */}
								<div>
									<ThreadsButton
										shareUrl={shareUrl || ""}
										text=""
										className={`${styles["card_live__share__icon"]} ${
											colorBloque?.toLowerCase() === "light"
												? styles["card_lice__share__icon--light"]
												: styles["card_live__share__icon--dark"]
										}`}
										isLogo={true}
										dataLayer={{
											...blockDataLayer,
											content_name: blockDataLayer?.entry?.ctNode?.[0]?.title
												? blockDataLayer?.entry?.ctNode?.[0]?.title
												: blockDataLayer?.title,
										}}
										isLiveBlogLayer={false}
										path={path || ""}
										isLight={false}
										title={titleShareUrl}
									/>
								</div>
								
							</div>
						</SendDataLayer>
					)}
				</figcaption>
				<BaseSnackbar
					label="¡Enlace copiado al portapapeles!"
					isOpenState={isOpen}
					handleCloseFunction={handleClose}
				/>
			</figure>
		</>
	);
}
