import { gql } from "@apollo/client";

export default gql`
	query GetStories($getStoriesId: String!) {
		getStories(id: $getStoriesId) {
			id
			type
			blockTitle
			blockDescription
			isRound
			cmsName
			variant
			relationships {
				stories {
					title
					storyImg
					storyID
					url
					id
					type
				}
			}
		}
	}
`;
