import {
	useState,
	createContext,
	ReactNode,
	SetStateAction,
	Dispatch,
} from "react";

type ToggleProps = {
	children: ReactNode;
};
type InitialProps = {
	isToggle: boolean;
	setToggle: Dispatch<SetStateAction<boolean>>;
};

export const ToggleContext = createContext<InitialProps>({
	isToggle: false,
	setToggle: () => {},
});
export const ToggleProvider = ({ children }: ToggleProps) => {
    const [isToggle, setToggle] = useState(false);


	return (
		<ToggleContext.Provider
			value={{
				isToggle,
				setToggle,
			}}
		>
			{children}
		</ToggleContext.Provider>
	);
};
