import Link from "next/link";

import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
import { BlockProps } from "@/app/types/BlockProps.type";
import GetCarouselNews from "@/app/queries/getCarouselNews";
import { GenericBlockType } from "@/app/types/GenericBlockType.type";
import CarouselVOD from "../../molecules/CarouselVOD/CarouselVOD";
import styles from "@/app/styles/Blocks/BlockCarouselNews.module.scss";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";
import Icon from "../../atoms/Icon/Icon";
import CarouselList from "./CarouselList";

export const BlockCarouselNews = async ({
	id,
	type,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetCarouselNews, {
		getCarouselNewsId: id,
	});
	if (rest?.errors) {
		console.error(rest?.errors);
	}
	const blockData: GenericBlockType = data?.getCarouselNews;
	const hasTitle = blockData?.hasTitle;
	const isDark = blockData?.isDark;
	const hasLink =
		blockData?.loadMoreLink?.uri !== null ||
		blockData?.loadMoreLink?.uri !== undefined
			? true
			: false;
	const blockType = type?.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		clickBloque: undefined,
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${blockData?.blockContent.length} contenidos`,
		countWidget: total,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
	};
	return (
		<section
			className={
				isDark
					? `${styles?.carouselNews} ${styles?.dark}`
					: `${styles?.carouselNews}`
			}
		>
			<div className={styles?.carouselNews__innerWrapper}>
				{hasTitle && (
					<WidgetTitle
						title={blockData?.blockTitle}
						text={blockData?.blockSummary}
						lineColor={blockData?.borderColor}
						isDark={isDark}
					/>
				)}
					

				<CarouselList
					blockData={blockDataLayer}
					data={blockData?.blockContent}
					isDark={isDark}
				/>
				{hasLink && (
					<div className={styles?.btn_cta}>
						<Link
							href={
								blockData?.loadMoreLink?.uri.replace("internal:", "") ?? "/"
							}
							title={blockData?.loadMoreLink?.title}
							prefetch={false}
						>
							{blockData?.loadMoreLink?.title}
							<Icon
								name="arrowRight_2"
								className={`${styles["carouselNews__rightIcon"]}`}
							/>
						</Link>
					</div>
				)}
			</div>
		</section>
	);
};
export default BlockCarouselNews;