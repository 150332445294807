"use client";
import { useRef, Children } from "react";

import ImageLink from "../../atoms/ImageLink/ImageLink";
import LinkSimple from "../../atoms/LinkSimple/LinkSimple";
import { usePosition } from "@/app/hooks/usePosition";
import ButtonSlider from "../../atoms/ButtonSlider/ButtonSlider";
import InteractiveLink from "../../organisms/BloqueHeroGrid/InteractiveLink";

import styles from "@/app/styles/Molecules/ShowsList.module.scss";

import type { BlockDataType, PostCard } from "@/app/types";

export default function ShowsList({
	shows,
	loading,
	blockData,
	isDark,
	isTalentPage,
}: {
	shows: PostCard[];
	loading: "eager" | "lazy";
	blockData: BlockDataType;
	isDark: boolean;
	isTalentPage: boolean;
}) {
	const elRef = useRef(null);
	const { scrollRight, scrollLeft, hasItemL, hasItemR } = usePosition(elRef);

	return (
		<div className={`${styles["showWrapper__container"]} ${isTalentPage ? styles['showWrapper__containerGrid'] :''}`}>
			<div
				className={`${styles?.showWrapper}  ${styles["showWrapper__scrollableContainer"]}`}
				ref={elRef}
			>
				{Children.toArray(
					shows?.map((show: any, i: number) => {
						return (
							<figure key={show?.id} className={`${styles?.showWrapper__showCard }`}>
								<div className={styles?.showWrapper__showImgWrapper}>
									{/* ATENCIÓN A ESTOS LINKS ANIDADOS */}
									<InteractiveLink
										url={show?.path}
										title={show?.title}
										cardPosition={i + 1}
										blockData={{ ...blockData, contentTitle: show?.title }}
										isLink={false}
									>
										<ImageLink
											post={show}
											cardPosition={i}
											loading={loading}
											blockData={{ ...blockData, contentTitle: show?.title }}
										/>
									</InteractiveLink>
								</div>
								<figcaption
									className={styles?.showWrapper__showCaption}
									style={
										{
											"--textColor": `${isDark ? "#EDF4F8" : "#001621"}`,
										} as React.CSSProperties
									}
								>
									<LinkSimple
										link={{
											id: show?.id ?? "0",
											attr: "_self",
											title: show?.title,
											url: show?.path,
										}}
										blockData={{ ...blockData, contentTitle: show?.title }}
										cardPosition={i}
										className={`${styles['showWrapper__showCaption__caption']}`}
									/>
									<span className={styles?.showWrapper__showSchedule}>
										{show?.schedule}
									</span>
								</figcaption>
							</figure>
						);
					})
				)}
			</div>
			{hasItemL && <ButtonSlider icon="arrowLeft" onClick={scrollLeft} />}
			{hasItemR && <ButtonSlider icon="arrowRight" onClick={scrollRight} />}
		</div>
	);
}
