import React from "react";
import style from "@/app/styles/BloqueMxM/BloqueMxM.module.scss";
import { gql } from "@apollo/client";
import BloqueMinutoXMinutoProvider from "./BloqueMinutoXMinutoProvider";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import { upperCamelCase } from "../../../helpers/upperCamelCase/upperCamelCase";

const GetMinuteToMinuteMain = gql`
	query GetMinuteToMinuteMain($getMinuteToMinuteMainId: String!) {
		getMinuteToMinuteMain(id: $getMinuteToMinuteMainId) {
			id
			type
			showTitle
			blockTitle
			blockSubtitle
			blockDescription
			visualType
			liveTitle
			liveDescription
			showBreaking
			breakingText
			showMxmLink
			link
			linkText
			datePublished
			dateModified
			isDark
			lineColor
			cmsName
			liveChannel {
				channelCode
				channelName
				channelBS
				channelUI
				channelUID
				currentShow {
					hour
					name
					duration
				}
			}
			mainContent {
				title
				term
				link
				cmsID
				description
				duration
				image {
					url
					urlDesktop
					width
					height
					alt
					caption
				}
			}
		}
	}
`;
export const BloqueMinutoXMinuto = async ({
	id,
	type,
	total,
	position,
}: any) => {
	const { data } = await fetchApolloData(GetMinuteToMinuteMain, {
		getMinuteToMinuteMainId: id,
	});

	const heroData = data?.getMinuteToMinuteMain;
	const isLive = heroData?.visualType === "Señal en vivo" ? true : false;

	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";

	const blockDataLayer = {
		colorBloque: heroData?.isDark ? "dark" : "light",
		contenidosBloque: `2 contenidos`,
		descripcionBloque: heroData?.cmsName,
		countWidget: total ?? 0,
		estiloBloque: undefined,
		event: "select_content",
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer || ""),
		tipoElemento: "widget",
		tituloBloque: heroData?.blockTitle || "",
	};

	return (
		<section
			className={`${style.mxm} ${heroData?.isDark ? style.mxm_dark : ""}`}
		>
			<div className={style.mxm__wrapper}>
				<BloqueMinutoXMinutoProvider
					heroData={heroData}
					isLive={isLive}
					id={id}
					data={data}
					blockDataLayer={blockDataLayer}
				/>
			</div>
		</section>
	);
};
export default BloqueMinutoXMinuto;