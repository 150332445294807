import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetSlideShow from "@/app/queries/getSlideShow";
import { GetSlideShowInterface } from "./interfaces/SlideShow.interface";
import slideShowStyle from "@/app/styles/Blocks/BlockSlideShow.module.scss";
import SlideShow from "../../molecules/SlideShow/SlideShow";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";

type SlideShowProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};
export const BlockSlideShow = async ({
	type,
	id,
	position = 0,
	total,
}: SlideShowProps) => {
	const { data, ...rest } = await fetchApolloData(GetSlideShow, {
		slideShowId: id,
	});
	if (rest?.error) console.error(rest?.error);
	const {
		cmsName,
		lineColor,
		subtitle,
		...slideShowRest
	}: GetSlideShowInterface = data?.getSlideShow;

	const blockTypeLayer =
		type?.replace("block_content--", "")?.split("_")?.join(" ") || "";

	const blockDataLayer = {
		contenidosBloque: `${slideShowRest?.imagesNews?.length} contenidos`,
		countWidget: `${total}` ?? "0",
		estiloBloque: slideShowRest.aspectRatio,
		event: "select_content",
		eventAction: "click",
		posicionBloque: position + 1 ? `${position + 1}` : "0",
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockTypeLayer || "") ?? undefined,
		tipoElemento: "widget",
		tituloBloque: slideShowRest?.blockTitle ?? undefined,
		descripcionBloque: cmsName,
		colorBloque: slideShowRest?.theme,
		clickBloque: undefined,
		content_type: undefined,
		section: undefined,
		contentTitle: undefined,
	};
	const loading =
		parseInt(blockDataLayer?.posicionBloque) === 0 ||
		parseInt(blockDataLayer?.posicionBloque) === 1 ||
		parseInt(blockDataLayer?.posicionBloque) === 2
			? "eager"
			: "lazy";
	return (
		<div
			className={`${slideShowStyle["slideShow--background"]}`}
			style={
				{
					"--background-theme": `${
						slideShowRest.theme === "light" ? "" : "#00283B"
					}`,
				} as React.CSSProperties
			}
		>
			<section
				className={`${slideShowStyle.slideShow}`}
				style={
					{
						"--background-theme": `${
							slideShowRest.theme === "light" ? "" : "#00283B"
						}`,
					} as React.CSSProperties
				}
			>
				{slideShowRest.blockTitle && (
					<div className={`${slideShowStyle["slideShow__wrapperHeader"]}`}>
						<h2
							className={`${slideShowStyle["slideShow__title"]}`}
							style={
								{
									"--headerLine": `${
										lineColor && slideShowRest?.blockTitle && subtitle
											? `4px solid ${lineColor}`
											: lineColor && slideShowRest?.blockTitle && !subtitle
											? `4px solid ${lineColor}`
											: ""
									}`,
									"--text-color": `${
										slideShowRest?.theme === "light" ? "#00283b" : "#EDF4F8"
									}`,
								} as React.CSSProperties
							}
						>
							{slideShowRest?.blockTitle || ""}
						</h2>
						{subtitle && (
							<h3
								className={`${slideShowStyle["slideShow__subtitle"]}`}
								style={
									{
										"--text-color": `${
											slideShowRest?.theme === "light" ? "#00283b" : "#EDF4F8"
										}`,
									} as React.CSSProperties
								}
							>
								{subtitle}
							</h3>
						)}
					</div>
				)}
				<SlideShow
					{...slideShowRest}
					dataLayer={blockDataLayer}
					loading={loading}
				/>
			</section>
		</div>
	);
};
