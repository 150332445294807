import { gql } from "@apollo/client";

export default gql`
	query GetPressRoomHome($pressRoomId: String!) {
		getPressRoomHome(pressRoomId: $pressRoomId) {
			banner_image_desktop
			banner_image_mobile
			blockTitle
			cmsName
			isPrincipalContent
			blockSubtitle
			editorialTitle
			path
			pdf
			published
			created
			changed
			metaData {
				description
				og_description
				title
			}

			relationContent {
				banner_image_desktop
				banner_image_mobile
				corte_1_1_desktop
				created
				title
				editorialTitle
				changed
				path
			}
		}
	}
`;
