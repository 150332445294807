'use client';
import { useEffect, useMemo, useState } from "react";

import { PostCard } from "@/app/types/PostCard.type";
import SimpleLiLink from "../../atoms/SimpleLiLink/SimpleLiLink";
import { BlockDataType } from "@/app/types/BlockDataType";

import styles from "@/app/styles/Molecules/EpisodesList.module.scss";

interface EpsListProps {
  episodes: [PostCard]
  blockData: BlockDataType
}

export default function EpisodesList({episodes, blockData}:EpsListProps){
  const initialEps = useMemo(() => (episodes && episodes?.length) ? episodes.filter((item, i) => i < 6) : [], [episodes]);
  const hasEps = initialEps.length > 0 ? true : false;
  const [filteredArr, setFilteredArr] = useState(initialEps);
  const [count, setCount] = useState<number>(6);
  /**
   * Se encarga de cargar más contenidos en la UI
   * Toma el array original y le resta los que ya están en UI
   * Toma los siguientes 6 y actualiza el count
   * Repite la operación hasta que el count llega a 18
   */
  const handleLoadMore = () => {
    if(count < 18){
      setCount((prev) => prev + 6)
      const range = episodes.slice(count, count+6);
      setFilteredArr((prev) => {
        return [...prev, ...range];
      })
    }
  }

  useEffect(() => {
    setFilteredArr(initialEps);
    setCount(prev => {
      if(prev >= 18){
        return 0
      }
      return prev;
    });
  }, [initialEps]);
  return (
    <>
      {
        hasEps &&
        <div className={styles?.episodes}>
          <ul className={styles?.episodes__episodesList}>
            {
              filteredArr.map((episode:PostCard, i:number) => (
                <SimpleLiLink key={`${episode?.id}-${i}`} item={episode} blockData={blockData} cardPosition={i}/>
                ))
              }
          </ul>
          <button role="button" onClick={handleLoadMore} className={styles?.episodes__btnLoadMore}>
            Cargar más videos
          </button>
        </div>
      }
    </>
  );
}