import { secondsToISO8601 } from "../../helpers/timeHelpers/timeHelpers";
import { NewsInfo as ParamsSchema } from "../../components/organisms/BloqueHeroGrid/interfaces/HeroGridResponse";

/**
 * Declaracion de los tipos a utilizar en TS.
 */
type MediaType = "VideoObject" | "NewsArticle";

interface HeadItem {
	"@context": string;
	"@type": MediaType;
	position: number;
	url: string;
}

type ItemPublisher = {
	"@type": string;
	name: string;
	logo: string;
}

type VideoSchema = HeadItem & {
	name: string;
	duration: string;
	uploadDate: string;
	thumbnailUrl: Array<string>;
	description: string;
	embedUrl: string;
	contentUrl: string;
}

type ArticleSchema = HeadItem & {
	headline: string;
	articleBody: string;
	image: Array<string>;
	mainEntityOfPage: string;
	publisher: ItemPublisher;
}

type MediaSchema = ArticleSchema | VideoSchema;

type SchemaResponse = {
	"@context": string;
	"@type": string;
	"itemListElement": Array<MediaSchema>;
}

/**
 * Declaracion de contantes con valores por defecto.
 */
const DEFAULT_NODE_TYPE = "node--video";
const CONTEXT = "https://schema.org";
const ORGANIZATION_NAME = "Redacción N+";
const ORGANIZATION_LOGO = "https://www.nmas.com.mx/assets/png/192.png";
const VIDEO_BASE_URL = "https://www.nmas.com.mx/preview-video/?idvideo=";


/**
 * Permite la creacion de los items que se mostraran en el schema.
 *
 * @return  {MediaSchema}  [return description]
 */
const getItem = ({
	editorialTitle,
	title,
	type,
	image_16_9_desktop,
	image_4_3_desktop,
	videoDuration,
	cmsId,
	description,
	newsUrl,
	created,
}: ParamsSchema, position: number): MediaSchema => {

	const IS_VIDEO = DEFAULT_NODE_TYPE === type;
	const ITEM: HeadItem = {
		"@context": CONTEXT,
		"@type": IS_VIDEO ? "VideoObject" : "NewsArticle",
		position,
		url: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${newsUrl}`,
	};

	const IMAGES = [
		image_16_9_desktop || "",
		image_4_3_desktop || ""
	].filter(Boolean);
	;

	let schemaItem;

	if (IS_VIDEO) {
		schemaItem = {
			...ITEM,
			"name": title || editorialTitle,
			"duration": secondsToISO8601(videoDuration || 0),
			"thumbnailUrl": IMAGES,
			"description": description || editorialTitle,
			"embedUrl": cmsId ? `${VIDEO_BASE_URL}${cmsId}` : undefined,
			"contentUrl":  ITEM.url,
			"uploadDate": created || ""
		};
	} else {
		schemaItem = {
			...ITEM,
			headline: title || editorialTitle,
			articleBody: description || editorialTitle,
			image: IMAGES,
			mainEntityOfPage: ITEM.url,
			publisher: {
				"@type": "Organization",
				name: ORGANIZATION_NAME,
				logo: ORGANIZATION_LOGO
			},
			author: {
				"@type": "Person",
				name: ORGANIZATION_NAME,
				url: ITEM.url,
			},
		};
	}

	return (schemaItem as MediaSchema);
};

/**
 * Nomraliza la data proporcionada por el CMS.
 *
 * @param {Array<any>} data  Data proporcionada por el CMS.
 * @return {Array<any>}
 */
const transformData = (data: Array<any>):Array<any> => {
	const NEW_ITEM = data.map((item: any) => {
		const {
			summary: description,
			title,
			duration: videoDuration,
			isVideo,
			path: newsUrl,
			thumbnail,
			cmsId = undefined,
			date
		} = item;
		return {
			description,
			title,
			newsUrl,
			videoDuration,
			image_16_9_desktop: thumbnail?.imageUrl?.webp,
			image_4_3_desktop: thumbnail?.imageUrl?.jpeg,
			type: isVideo ? "node--video" : "node--article",
			cmsId,
			editorialTitle: title,
			created: date || "",
		};
	});

	return NEW_ITEM;
};

/**
 * Permite la creacion de un schema de tipo ItemList.
 *
 * @param   {Array<ParamsSchema>} data   Data proporcionada por el CMS.
 * @param   {boolean}             isVod  Permite identificar si el contenido es VOD.
 *
 * @return  {ReactComponentElement}                [return description]
 */
const ListItem = (data: Array<any> = [], isVOD: boolean = false) => {
	const IS_NOT_EMPTY = data.length > 0;
	const SCHEMA:SchemaResponse = {
		"@context": CONTEXT,
		"@type": "ItemList",
		"itemListElement": []
	};

	let Response = <></>;

	if (IS_NOT_EMPTY) {
		const ITEMS = isVOD ? transformData(data) : data;
		SCHEMA.itemListElement = ITEMS.map((item: ParamsSchema, index: number) => getItem(item, index+1));
		Response = (
			<script
				type="application/ld+json"
				dangerouslySetInnerHTML={{ __html: JSON.stringify(SCHEMA, null, 2) ?? "" }}
			/>
		);
	}
	return Response;
};

export default ListItem;
