"use client";
import { singleDateStringFirstMonth } from "@/app/helpers/timeHelpers/timeHelpers";
import styles from "@/app/styles/Atoms/Date.module.scss";

type props = {
	datePublished?: string | any;
	dateModified?: string | any;
	typeMedia?: string;
};

const Date = ({ datePublished, dateModified, typeMedia }: props) => {
	const fecha = singleDateStringFirstMonth(datePublished);
	const change = dateModified;
	const re = /\s/;

	return (
		<time
			dateTime={!re?.test(change) ? change : fecha}
			className={`${styles?.date} ${typeMedia && typeMedia === "multiVideo" ? styles["date__liveblog__multiVideo"] : typeMedia && typeMedia === "multiImage" ? styles["date__liveblog__multiImage"] : ""}`}
		>
			Publicado el {fecha} CST
		</time>
	);
};

export default Date;
