'use client'
import style from "@/app/styles/Molecules/CardSingleVideo.module.scss"
import Image from "next/image";

const CardSingleVideo = ({ image, onClick, position }) => {
	const lazy = position > 1 ? "lazy" : "eager";
	const priority = position > 1 ? false : true;
	return (
		<figure className={`${style["singleVideo__wrapper"]}`} onClick={onClick}>
			<Image
				src={image?.imageUrl ?? "/assets/placeholder_.svg"}
				alt={image?.imageAlt}
				width="205"
				height="363"
				sizes="100vw"
				style={{ width: "100%", height: "auto" }}
				priority={priority}
				quality={50}
				loading={lazy}
			/>
			{/* TODO: Esto de momento no va a existir.
				<figcaption className={`${style["singleVideo__themeTitle"]}`}>CDMX</figcaption> 
			*/}
		</figure>
	);
};
export default CardSingleVideo;
