import { gql } from "@apollo/client";

export default gql`
	query GetVideoNewsBlock($fromSite: String!, $videoNewsBlockId: String!) {
		getVideoNewsBlock(
			fromSite: $fromSite
			VideoNewsBlockId: $videoNewsBlockId
		) {
			id
			type
			loadMoreLink {
				title
				uri
				target
			}
			isDark
			hasTitle
			blockTitle
			blockDescription
			cmsName
			borderColor
			relationships {
				field_videos {
					id
					title
					dateTime
					date
					summary
					duration
					channel
					path
					term {
						name
					}
					thumbnail {
						imageUrl {
							webp
						}
						alt
						caption
						width
						height
					}
					isVideo
				}
			}
		}
	}
`;
