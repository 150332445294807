export const hourly = [
	<span key={"000A.M"}>0:00 A.M</span>,
	<span key={"030A.M"}>0:30 A.M</span>,
	<span key={"100A.M"}>1:00 A.M</span>,
	<span key={"130A.M"}>1:30 A.M</span>,
	<span key={"200A.M"}>2:00 A.M</span>,
	<span key={"230A.M"}>2:30 A.M</span>,
	<span key={"300A.M"}>3:00 A.M</span>,
	<span key={"330A.M"}>3:30 A.M</span>,
	<span key={"400A.M"}>4:00 A.M</span>,
	<span key={"430A.M"}>4:30 A.M</span>,
	<span key={"500A.M"}>5:00 A.M</span>,
	<span key={"530A.M"}>5:30 A.M</span>,
	<span key={"600A.M"}>6:00 A.M</span>,
	<span key={"630A.M"}>6:30 A.M</span>,
	<span key={"700A.M"}>7:00 A.M</span>,
	<span key={"730A.M"}>7:30 A.M</span>,
	<span key={"800A.M"}>8:00 A.M</span>,
	<span key={"830A.M"}>8:30 A.M</span>,
	<span key={"900A.M"}>9:00 A.M</span>,
	<span key={"930A.M"}>9:30 A.M</span>,
	<span key={"1000A.M"}>10:00 A.M</span>,
	<span key={"1030A.M"}>10:30 A.M</span>,
	<span key={"1100A.M"}>11:00 A.M</span>,
	<span key={"1130A.M"}>11:30 A.M</span>,
	<span key={"1200P.M"}>12:00 P.M</span>,
	<span key={"030P.M"}>12:30 P.M</span>,
	<span key={"1300P.M"}>13:00 P.M</span>,
	<span key={"1330P.M"}>13:30 P.M</span>,
	<span key={"1400P.M"}>14:00 P.M</span>,
	<span key={"1430P.M"}>14:30 P.M</span>,
	<span key={"1500P.M"}>15:00 P.M</span>,
	<span key={"1530P.M"}>15:30 P.M</span>,
	<span key={"1600P.M"}>16:00 P.M</span>,
	<span key={"1630P.M"}>16:30 P.M</span>,
	<span key={"1700P.M"}>17:00 P.M</span>,
	<span key={"1730P.M"}>17:30 P.M</span>,
	<span key={"1800P.M"}>18:00 P.M</span>,
	<span key={"1830P.M"}>18:30 P.M</span>,
	<span key={"1900P.M"}>19:00 P.M</span>,
	<span key={"1930P.M"}>19:30 P.M</span>,
	<span key={"2000P.M"}>20:00 P.M</span>,
	<span key={"2030P.M"}>20:30 P.M</span>,
	<span key={"2100P.M"}>21:00 P.M</span>,
	<span key={"2130P.M"}>21:30 P.M</span>,
	<span key={"2200P.M"}>22:00 P.M</span>,
	<span key={"2230P.M"}>22:30 P.M</span>,
	<span key={"2300P.M"}>23:00 P.M</span>,
	<span key={"2330P.M"}>23:30 P.M</span>,
];
