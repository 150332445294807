import { useCallback, useEffect, useState } from "react";
import { singleDateStringFirstDay, singleDateStringFirstMonth } from "@/app/helpers/timeHelpers/timeHelpers";

const useChangedDate = ({ changed }:any) => {
	const [formattedTime, setFormattedTime] = useState("Cargando...");

	const timeManagement = useCallback((diff:any) => {
		const seconds = Math.floor(diff / 1000);

		// Dejarlo a 4 horas
		if (seconds < 60) {
			return "hace un momento";
		} else if (seconds < 3600) {
			const minutes = Math.floor(seconds / 60);
			return `hace ${minutes} ${minutes === 1 ? "minuto" : "minutos"}`;
		} else if (seconds < 14400) { // 4 horas
			const horas = Math.floor(seconds / 3600);
			return `hace ${horas} ${horas === 1 ? "hora" : "horas"}`;
		} else {
			return `${singleDateStringFirstMonth(changed)} CST`;
		}
	}, [changed]);

	useEffect(() => {
		const updateText = () => {
			// Cuando la fecha es undefined, significa que el contenido aún se está cargando
			if (!changed) {
				return;
			}
			const modified:any = new Date(changed);
			const currentDate:any = new Date();
			const diff = currentDate - modified;
			const formattedText = timeManagement(diff);

			setFormattedTime(formattedText);
		};

		updateText();

		const timer = setInterval(updateText, 10000);
		return () => clearInterval(timer);
	
	}, [changed, timeManagement]);

	return formattedTime;
};

export default useChangedDate;
