'use client';
import YouTube from 'react-youtube';

export default function CardYoutube({data}:any){
  const youtubeID = data.youtubeID;
  const blocktitle = data.blocktitle;

  const options = {
      width:640,
      height:390,
      playerVars: {
          autoplay: 1,
          start: 0
      }
  }

  function autoplayVideo(event:any){
      event.target.playVideo();
      event.target.mute();
  }

  return(
    <>
      <YouTube 
          className="vid__youtube_wrapper"
          videoId={youtubeID} id={`video-${youtubeID}`} 
          title={blocktitle} opts={options} 
          onReady={autoplayVideo}
      />
    </>
  );
}