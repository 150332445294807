import styles from "@/app/styles/Molecules/SquareImage.module.scss";
import { ImgProps } from "@/app/types/ImgProps";
import ImageLinkUniversal from "../../atoms/ImageLink/ImageLinkUniversal";
import ClientImage from "../../atoms/ClientImage/ClientImage";

export default function SquareImage({img, link, size, blockData, cardPosition}:any){
  const w = size === "S" ? 192 : size === "M" ? 296 : 608;
  const h = size === "S" ? 192 : size === "M" ? 296 : 608;
  const hasLink = link?.uri !== "/";
  const sizes = "(min-width: 768px) 45.3vw, (min-width: 540px) 40vw, (min-width: 414px) 50vw, 40vw";

  img.imageUrl = {webp: img.imageUrl?.webp};

  return(
    <div className={styles?.imageWrapper}>
      {hasLink ?
        <ImageLinkUniversal 
          link={link} img={img} 
          blockData={blockData} 
          cardPosition={cardPosition} w={w} h={h} 
          sizes={sizes} 
        /> 
        :
        <ClientImage thumbnail={img} customsizes={sizes} />
        // <Image 
        //   src={img?.imageUrl?.webp ?? "/assets/placeholder_.svg"} 
        //   alt={img?.alt ?? "Imagen de N+"} width={w} height={h} 
        //   sizes={sizes}
        // />
      }
    </div>
  );
}