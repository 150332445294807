import {Libre_Franklin, Merriweather} from 'next/font/google';
import GetAperturaInformativa from "@/app/queries/getAperturaInformativa";
import { BlockAperturaInf } from "./types/BloqueAperturaInf.type";
import styles from "@/app/styles/Blocks/BloqueAperturaInf.module.scss";
import { BlockProps } from "@/app/types/BlockProps.type";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";

const franklin = Libre_Franklin({
    subsets: ['latin'],
    display: 'swap',
    weight: ['700'],
    style: ['normal'],
    variable: '--font-franklin'
  });
  
  const merri = Merriweather({
    subsets: ['latin'],
    display: 'swap',
    style: ['normal', 'italic'],
    weight: ['400', '700'],
    variable: '--font-merry'
  });

export const BloqueAperturaInf = async ({type, id, position}:BlockProps) => {

    const {data, ...rest} = await fetchApolloData(GetAperturaInformativa, {breakingId: id }); 
  
    if(rest?.errors) {
      console.error(rest?.errors);
    }
    const blockData : BlockAperturaInf = data?.getBreaking ?? null;
    const isDark = blockData?.isDark;
    const isBreaking = blockData?.isLive;
    const alignment = blockData?.alignment;
    const isNMedia = (blockData?.lookAlternate === "N+ Media");

    return (
        <section className={isDark ? `block__apertinf ${styles?.aperturaInf} ${styles?.dark}` : `block__apertinf ${styles?.aperturaInf}`}>
            <div className={styles?.aperturaInf__inner_wrapper}>
                <div className={alignment === "center" ? `${styles?.aperturaInf_center}` : alignment === "left" ? `${styles?.aperturaInf_left}` : `${styles?.aperturaInf_right}`}>
                    { isBreaking && <div className={styles?.aperturaInf_breaking}><span>{blockData?.textBreaking}</span></div> }
                    {position === 0 ? 
                        <h1 className={!isNMedia ? `${styles?.aperturaInf_title}` : `${styles?.aiMedia} ${franklin?.variable}`}>
                            {blockData?.blockTitle}
                        </h1> :
                        <h2 className={!isNMedia ? `${styles?.aperturaInf_title}` : `${styles?.aiMedia} ${franklin?.variable}`}>
                            {blockData?.blockTitle}
                        </h2>
                    }
                    <p className={!isNMedia ? `${styles?.aperturaInf_subtitle}` : `${styles?.aiMediaP} ${merri?.variable}`}>
                        {blockData?.blockDescription}
                    </p>
                </div>
            </div>
        </section>
    );
}
export default BloqueAperturaInf;