import { gql } from "@apollo/client";

export default gql`
  query GetBlockNewsletter($getBlockNewsletterId: String!) {
    getBlockNewsletter(id: $getBlockNewsletterId) {
      id
      type
      htmlbody
    }
  }
`;