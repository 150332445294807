import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetDiscoverVideos from "@/app/queries/getDiscoverVideos";
import styles from "@/app/styles/Discover/Discover.module.scss";
import Discover from "./Discover";

export const DiscoverLoader = async({ params }: any) => {
	const { data, ...rest } = await fetchApolloData(GetDiscoverVideos, {
		sectionPath: "/descubre/",
		id: params?.id,
	});
	return (
		<section className={`${styles.discover__container}`}>
			<Discover data={data?.getDiscoverVideos} />
		</section>
	);
}
export default DiscoverLoader;