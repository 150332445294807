"use client";
import { useEffect, useMemo, useState } from "react";
import CardListVideo from "../../molecules/cards/CardListVideo/CardListVideo";
import GetVideoDetailRelatedVideos from "@/app/queries/getVideoDetailRelatedVideos";
import styles from "@/app/styles/Blocks/BlockDetailVideo.module.scss";
import { print } from "graphql";
import {
	getMonthString,
	secondsToISO8601,
} from "@/app/helpers/timeHelpers/timeHelpers";

export default function RelatedPlaylist({
	setVideoList,
	videoList,
	currentVideo,
	mainVideo,
	isPeP = false,
	updatedVod,
	setCurrentPath,
	setPlaying,
	isNotStatic,
	videoIsPlaying,
	title,
	showEndCard,
	setShowEndCard,
	canonical,
}: any) {
	//  En este effect se hace el query para traer los videos relacionados al video principal
	useEffect(() => {
		const reqheaders = new Headers();
		reqheaders.append("Content-Type", "application/json");
    reqheaders.append("X-Secure-Environment", `${process.env.NEXT_PUBLIC_APOLLOSEC}`);
		let tot = 21; 
		if(!isPeP){
			tot = currentVideo?.priorizedIds?.length >= 6 ? currentVideo?.priorizedIds?.length : 7;
		}
		const graphReq = JSON.stringify({
			query: print(GetVideoDetailRelatedVideos),
			variables: {
				category: currentVideo?.categoryId,
				priorized: currentVideo?.priorizedIds,
				totalVids: tot,
			},
		});
		const reqOpts = {
			method: "POST",
			headers: reqheaders,
			body: graphReq,
			next: { revalidate: 5 },
		};
		const endpoint =
			process.env.NEXT_PUBLIC_APOLLO_SERVER_URL ??
			"https://apollo.nmas.live/graphql";
		fetch(endpoint, reqOpts)
			.then((res) => {
				if (res.status === 200) return res.json();
				return true;
			})
			.then((data) => {
				const relatedVideos =
					data?.data?.getVideoDetailRelatedVideos?.relatedVideos;
				const indexDuplicated = relatedVideos?.findIndex(
					(video: any) => video.cmsId === mainVideo.cmsId
				);
				if (indexDuplicated >= 0) {
					relatedVideos.splice(indexDuplicated, 1);
				}
				setVideoList([currentVideo, ...relatedVideos]);
				return true;
			})
			.catch((err) => {
				return {
					data: null,
					error: err,
				};
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const vidList = videoList?.map((element: any, i: number) => {
		// const date = `${element?.date?.replace(" | ", " ")?.replace(" hrs", "")} UTC-6`;
		// const replaceWith = date?.split(" ")[0]?.toLowerCase();
		const re = /(<([^>]+)>)/g;
		return {
			"@context": "https://schema.org",
			"@type": "VideoObject",
			position: i + 1,
			name: element?.title,
			url: `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}${element?.path}`,
			duration: secondsToISO8601(element?.duration),
			uploadDate: element?.dateTime,
			thumbnailUrl: element?.thumbnail?.imageUrl?.jpeg?.includes(".jpg") ? [element?.thumbnail?.imageUrl?.jpeg?.replace(/-\d+x\d+/, "")] : [ `${process.env.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "")}/assets/placeholder_.svg` ],
			description: element?.summary?.replaceAll(re, ""),
			embedUrl: element?.cmsId ? `https://www.nmas.com.mx/preview-video/?idvideo=${element?.cmsId}` : undefined,
			contentUrl: element?.contentUrl ? element?.contentUrl : undefined
		};
	});
	vidList.shift();
	const itemList = useMemo(() => {
		return {
			"@context": "https://schema.org",
			"@type": "ItemList",
			itemListElement: vidList,
		};
	}, [vidList]);

	useEffect(() => {
		const currScript = document.getElementById("ItemListObj");
		if (currScript) currScript.remove();
		const ldScript = document.createElement("script");
		ldScript.setAttribute("id", "ItemListObj");
		ldScript?.setAttribute("type", "application/ld+json");
		ldScript.textContent = JSON?.stringify(itemList);
		if (itemList) {
			document.head.appendChild(ldScript);
		}
	}, [itemList]);
	return (
		<div
			className={
				isPeP
					? `${styles.video__cell_list__card_list} ${styles.video__cell_list__card_list__pep}`
					: `${styles.video__cell_list__card_list}`
			}
		>
			{videoList?.map((card: any, key: any) => {
				return (
					<CardListVideo
						{...card}
						key={key}
						endpoint={card?.url ?? card?.endpoint ?? card?.path}
						currentVideo={currentVideo}
						setCurrentVideo={updatedVod}
						setCurrentPath={setCurrentPath}
						isLazy={key > 5}
						isPeP={isPeP}
						thumbnail={card?.thumbnail}
						publisher={card?.channel}
						index={key}
						isPlaying={videoIsPlaying(card)}
						setPlaying={setPlaying}
						isStatic={!isNotStatic}
						titleUrl={title}
						terms={card?.term}
						summary={card?.summary}
						alt={card?.thumbnail?.alt}
						showEndCard={showEndCard}
						setShowEndCard={setShowEndCard}
						dateTime={card?.dateTime}
						contentUrl={card?.contentUrl}
					/>
				);
			})}
		</div>
	);
}
