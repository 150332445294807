'use client'
import Icon from "@/app/components/atoms/Icon/Icon"
import style from "@/app/styles/Atoms/ButtonSliders.module.scss";
import { button } from "./types/button";

export default function ButtonSlider({ icon, onClick, className }:button) {
	const buttonStyle = icon === 'arrowRight' ? `${style["buttonSlider__buttonRight"]}` : `${style["buttonSlider__buttonLeft"]}`
	const iconStyle = icon === 'arrowRight' ? `${style["buttonSlider__iconRight"]}` : `${style["buttonSlider__iconLeft"]}`
	const handleClick = () => {
    onClick();
  };

	return (
		<button
			className={`${buttonStyle} ${className}`}
			type="button"
			onClick={handleClick}
		>
			<Icon className={iconStyle} name={icon} />
		</button>
	);
};