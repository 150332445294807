'use client'
import { CardsIndependFull } from "./CardsIndependFull";
import { ButtonMoreIndependFull } from "./ButtonMoreIndependFull";
import { NewsInfo } from "./types/IndependetResponse.interface";
import { LoadMoreContentProvider } from "@/app/context/LoadMoreFull";
import { BlockDataType } from "@/app/types/BlockDataType";
import { usePathname } from "next/navigation";
type Props = {
	newsInfo: NewsInfo[];
	theme: string;
	loading: 'lazy' | 'eager'
	blockData: BlockDataType
};
const IndependentFullSize = ({ newsInfo, theme, loading, ...rest }: Props) => {
	const pathName = usePathname();
	return (
		<>
        <LoadMoreContentProvider >
					<CardsIndependFull newsInfo={newsInfo} theme={theme} loading={loading} blockData={rest?.blockData}/>
					<ButtonMoreIndependFull newsInfo={newsInfo} theme={theme} blockData={rest?.blockData} pathName={pathName} />
        </LoadMoreContentProvider>
		</>
	);
};

export default IndependentFullSize;
