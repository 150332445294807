import { BlockProps } from "@/app/types/BlockProps.type";
import GetDetailTalent from "@/app/queries/getTalentDetail";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import Breadcrumb from "../../molecules/Breadcrumb/Breadcrumb";
import Image from "next/image";
import WidgetTitle from "../../molecules/WidgetTitle/WidgetTitle";
import TabSelector from "../../molecules/TabSelector/TabSelector";
import styles from "@/app/styles/Blocks/BloqueDetailTalent.module.scss";
import ShowsList from "../../molecules/ShowsList/ShowsList";
import SocialShareButtons from "../../molecules/SocialShareButtons/SocialShareButtons";
import { upperCamelCase } from "@/app/helpers/upperCamelCase/upperCamelCase";

export const BloqueDetailTalent = async ({
	id,
	type,
	position = 0,
	total,
}: BlockProps) => {
	const { data, ...rest } = await fetchApolloData(GetDetailTalent, {
		getDetailTalentId: id,
	});

	if (rest?.errors) {
		console.error(rest?.errors);
	}

	const blockData = data?.getDetailTalent;
	const fullName = `${blockData?.firstName} ${blockData?.lastName}`;
	const loading =
		position === 0 || position === 1 || position === 2 ? "eager" : "lazy";

	const blockType = type.replace("block_content--", "").split("_").join(" ");
	const blockDataLayer = {
		event: "select_content",
		// clickBloque: 0,
		channel:
			blockData?.channels?.length > 0
				? upperCamelCase(blockData?.channels[0]?.name) ?? undefined
				: "N+",
		colorBloque: `${blockData?.isDark ? "dark" : "light"}`,
		contenidosBloque: `${blockData?.shows?.length} contenidos`,
		countWidget: total ?? undefined,
		descripcionBloque: blockData?.cmsName,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: position + 1 ? position + 1 : 0,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: upperCamelCase(blockType) ?? undefined,
		tipoElemento: "widget",
		tituloBloque: blockData?.blockTitle ?? undefined,
	};

	return (
		<section className={styles?.mainSection}>
			<div className={styles?.mainSection__innerSection}>
				<header className={styles?.mainSection__mainHeader}>
					<Breadcrumb specialPath={fullName || ""} getLasted={true} />
					<h1 className={`${styles["mainSection__mainHeader__talentName"]}`}>
						{fullName}
					</h1>
				</header>
				<figure className={styles?.mainSection__talentFig}>
					<Image
						src={
							blockData?.thumbnail?.imageUrl?.webp ?? "/assets/placeholder_.svg"
						}
						alt={blockData?.thumbnail?.alt}
						width={blockData?.thumbnail?.width ?? 1}
						height={blockData?.thumbnail?.height ?? 1}
						className={`${styles["mainSection__talentFig__image"]}`}
					/>
					<div className={`${styles["mainSection__talentFig__info"]}`}>
						<figcaption className={`${styles["mainSection__talentCaption"]}`}>
							{blockData?.summary}
						</figcaption>
						<div className={styles?.mainSection__shares}>
							<SocialShareButtons
								isVariantDetailTalent={true}
								isVariantStoryPage={false}
								isVariantVideoPlayerList={false}
								title={blockData?.title}
								link={blockData?.path}
							/>
						</div>
					</div>
				</figure>
				<section className={styles?.mainSection__innerSection}>
					<div className={`${styles["mainSection__wrapperTitle"]}`}>
						<WidgetTitle
							title={"Programas"}
							text={"EXPLORA LOS EPISODIOS RECIENTES"}
							lineColor={"#ff3640"}
							areH1={false}
							isDark={true}
						/>
					</div>
					<ShowsList
						shows={blockData?.shows}
						loading={loading}
						blockData={blockDataLayer}
						isDark={true}
						isTalentPage={true}
					/>
				</section>
				<section
					className={`${styles?.mainSection__innerSection} ${styles?.mainSection__epsSection}`}
				>
					<TabSelector shows={blockData?.shows} blockData={blockDataLayer} />
				</section>
			</div>
		</section>
	);
};
export default BloqueDetailTalent;
