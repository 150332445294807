import { gql } from "@apollo/client";
export default gql`
	query GetMapboxMap($mapboxId: String!) {
		getToggleMap(mapboxId: $mapboxId) {
			styleUrl
			source
			title
			description
			newsUrl
			lineColor
		}
	}
`;
