"use client";
import React from "react";
import style from "@/app/styles/Molecules/WidgetTitle.module.scss";

interface Props {
	title?: string;
	text?: string;
	lineColor?: string;
	areH1?: boolean;
	isDark?: boolean;
}

export default function WidgetTitle({
	title,
	text,
	lineColor,
	areH1,
	isDark,
}: Props) {
	if (!title) {
		return null;
	}

	const heading = areH1 ? "h1" : "h2";

	return (
		<header
			className={`${style["widgetTitle__wrapper"]}`}
			style={
				{
					"--textColor": `${isDark ? "#EDF4F8" : "#00283B"}`,
				} as React.CSSProperties
			}
		>
			<div className={`${style["widgetTitle__wrapperSection"]}`}>
				{lineColor && (
					<span
						className={`${style["widgetTitle__line"]}`}
						style={{ backgroundColor: lineColor }}
					/>
				)}
				{title &&
					React.createElement(
						heading,
						{ className: `${style["widgetTitle__title"]}` },
						title
					)}
				{text && <p className={`${style["widgetTitle__text"]}`}>{text}</p>}
			</div>
		</header>
	);
}
