"use client";

import sanitizeText from "@/app/helpers/sanitizers/sanitizeText";
// import { Skeleton } from "@mui/material";
import { usePathname } from "next/navigation";
import styles from "@/app/styles/Cards/CardItemMinuteToMinute.module.scss";
import InteractiveLink from "@/app/components/organisms/BloqueHeroGrid/InteractiveLink";

export default function CardItemMinuteToMinute({
	cardData,
	isLoading = true,
	widgetTitle = undefined,
	indexCard = undefined,
	isStandalone = false,
	blockDataLayer,
	cardPosition,
}: any) {
	const typeArticleGTM: string = !cardData?.type
		? "NEW>"
		: cardData?.type === "Video"
		? "VOD>"
		: "NEW>";

	const pathname = usePathname();

	const dateStr = !isLoading ? new Date(cardData?.hour ?? "") : new Date();

	const AM_PATTERN = /a\.?\s*m\.?/gi;
	const PM_PATTERN = /p\.?\s*m\.?/gi;
	const theHour =
		cardData?.hour !== null
			? dateStr
					.toLocaleString("es-MX", {
						hour: "2-digit",
						minute: "2-digit",
						hour12: true,
					})
					.replace(AM_PATTERN, "AM")
					.replace(PM_PATTERN, "PM")
			: "00:00";

	if (!cardData?.title && !cardData?.description) return null;

	return (
		<div className={`${styles.mxm} ${!isStandalone ? styles.mxm__block : ""}`}>
			<div
				className={`${styles.mxm__wrapper} ${
					isStandalone ? styles.mxm__wrapper__standalone : ""
				}`}
			>
				<>
					{isLoading ? (
						<></>
						// <Skeleton variant="text" width={"100%"} />
					) : (
						<span className={styles.mxm__wrapper__date}>{theHour}</span>
					)}
				</>
				{isLoading ? (
					<></>
					// <Skeleton variant="text" width={"100%"} />
				) : (
					<>
						<div className={styles.mxm__wrapper__title}>
							{cardData?.title && (
								<h4 className={styles.mxm__wrapper__title_headline}>
									{cardData?.title}
								</h4>
							)}
						</div>
						{cardData?.description && (
							<p className={styles.mxm__wrapper__description}>
								{cardData?.description}
							</p>
						)}
						{cardData?.link && (
							<div className={styles.mxm__wrapper__link}>
								<InteractiveLink
									url={cardData?.link ?? "/"}
									className="min_item__link"
									blockData={{
										...blockDataLayer,
										tipoElemento: "LinkText",
										content_name: "NEW>",
									}}
									cardPosition={cardPosition}
								>
									Ver nota completa
								</InteractiveLink>
								<svg
									width="4"
									height="9"
									viewBox="0 0 4 9"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M0.125 8.125L3.875 4.375L0.125 0.625L0.125 8.125Z"
										fill="#474B4D"
									/>
								</svg>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
}
