import { gql } from "@apollo/client"

export default gql`
  query GetHorizontalButtons($getHorizontalButtonsId: String!) {
    getHorizontalButtons(id: $getHorizontalButtonsId) {
      hasTitle
      blockTitle
      borderColor
      blockDescription
      id
      type
      isDark
      cmsName
      variant
      links {
        id
        attr
        title
        url
      }
    }
  }
`;