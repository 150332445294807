import { gql } from "@apollo/client";

export default gql`
  query GetBlockTitleDark($getBlockTitleDarkId: String!) {
    getBlockTitleDark(id: $getBlockTitleDarkId) {
      type
      id
      isDark
      title
      description
    }
  }
`;