import { gql } from "@apollo/client";

export default gql`
  query GetCarouselNews($getCarouselNewsId: String!) {
    getCarouselNews(id: $getCarouselNewsId) {
      id
      type
      hasTitle
      blockTitle
      blockSummary
      isDark
      borderColor
      cmsName
      loadMoreLink {
        title
        uri
      }
      blockContent {
        id
        title
        summary
        path
        term {
          name
          url
        }
        thumbnail {
          alt
          caption
          width
          height
          imageUrl {
            webp
          }
        }
        isVideo
      }
    }
  }
`;