"use client";
import useChangedDate from "@/app/hooks/useChangedDate";
import styles from "@/app/styles/Atoms/Update.module.scss";
import Icon from "../Icon/Icon";

interface props {
	className?: string,
	dateModified: string | any;
	live?: string;
	version?: string | "lastminute" | "coverage" | "live" | "gray" | "pinned" | 'share_modal';
}

const Update = ({ dateModified, version, className }: props) => {
	const tiempoFormateado = useChangedDate({ changed: dateModified });
	return (
		<>
			<p
				className={
					version === "live"
						? styles?.update + " " + styles?.live + " " + className
						: version === "coverage"
						? styles?.update + " " + styles?.coverage + " " + className
						: version === "lastminute"
						? styles?.update + " " + styles?.lastminute + " " + className
						: version === "gray"
						? styles?.update + " " + styles?.gray + " " + className
						: version === "pinned"
						? styles?.update + " " + styles?.pinned + " " + className
						: version === 'share_modal'
						? styles['update__modal']
						: styles?.update + " " + styles?.default + " " + className
				}
			>
				<Icon name="update" className={`${styles['update__icon']}`}/> <time dateTime={dateModified}>{tiempoFormateado}</time>
			</p>
		</>
	);
};

export default Update;
