import { gql } from "@apollo/client";

export default gql`
  query GetProgramsHome($getProgramsHomeId: String!) {
    getProgramsHome(id: $getProgramsHomeId) {
      type
      id
      blockTitle
      blockSummary
      blockLink
      blockLinkText
      isDark
      hasTitle
      borderColor
      cmsName
      variant
      shows {
        id
        type
        title
        schedule
        path
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
      }
    }
  }
`;