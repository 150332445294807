// import ErrorMessage from '@/components/atoms/ErrorMessage/ErrorMessage'; // DEPENDENCY WITH THE OLD WORLD

export const validateGrid = ({ status, key, message }) => {
	if (
		(!status[key] && key === 0) ||
		(!status[key] && key === 1 && status[0]) ||
		(!status[key] && key === 2 && status[1]) ||
		(!status[key] && key === 3 && status[2])
	) {
		return <>ERROR</>;
	}
};
