import { gql } from "@apollo/client";

export default gql`
    query GetBlockTopBanner($blockId: String!) {
        getTopBanner(blockId: $blockId) {    
            id
            type
            drupalID
            blockTitle
            blockSubtitle
            bannerType
            blockColor
            hasPadding
            bannerLeft
            bannerTitle
            bannerSubtitle
            hasBlink
            hasSVG
            svgCode
            bannerSvgCode
            loadMoreLink {
                title
                uri
            }
            bannerLink {
                title
                uri
            }
            content {
                format
                processed
                value
            }
            images {
                desktop {
                    height
                    url
                    width
                }
                full {
                    height
                    url
                    width
                }
                mobile {
                    url
                    width
                    height
                }
                tablet {
                    url
                    width
                    height
                }
                logo {
                    url
                    width
                    height
                }
                circle {
                    url
                    width
                    height
                }
            }
        }
    }
`;