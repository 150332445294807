import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import styles from "@/app/styles/Atoms/VideoDuration.module.scss";
type DurType = {
	duration: any;
	isPlay?: boolean;
	isSmall?: boolean;
	isTopFive?: boolean;
};
export default function VideoDuration({ duration, isPlay, isSmall, isTopFive }: DurType) {
	return (
		<time
			className={`
        ${styles?.fig_card_vod_duration} ${isPlay ? styles?.tapToPlay : ""}
        ${isTopFive ? styles?.topFive : ""}
        ${isSmall ? styles?.fig_card_vod_duration__small : ""}
      `}
			suppressHydrationWarning
		>
			{secondsToMinutes(duration)}
		</time>
	);
}
