import { gql } from "@apollo/client";

export default gql`
	query GetTopFive($getTopFiveId: String!) {
		getTopFive(id: $getTopFiveId) {
			id
			type
			blockTitle
			blockSummary
			borderColor
			hasTitle
			cmsName
			isDark
			variant
			blockContent {
				id
				isVideo
				title
				summary
				term {
					name
				}
				path
				duration
				cmsId
				status
				isLiveblog
				thumbnail {
					alt
					caption
					width
					height
					imageUrl {
						webp
						shapes {
							rect
							square
							vintage
						}
					}
					imageDesktopUrl {
						webp
						shapes {
							rect
							square
							vintage
						}
					}
				}
			}
		}
	}
`;
