'use client';
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import Link from "next/link";
import { usePathname } from "next/navigation";
// import handleSelectContent from "@/app/helpers/analytics/handleSelectContent";
import { SimpleLinkType } from "@/app/types/SimpleLinkType";
import { Merriweather } from 'next/font/google';
import styles from "../../../styles/Atoms/LinkSimple.module.scss";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";


const merri = Merriweather({
  subsets: ['latin'],
  display: 'swap',
  style: ['normal', 'italic'],
  weight: ['400', '700'],
  variable: '--font-merry'
});

declare const window: WindowWithDataLayer;
export default function LinkSimple({link, blockData, cardPosition, isNmas, className}:SimpleLinkType){
  const pathName = usePathname();
  const withTrailingSlash = {
    ...link,
    url: link?.url.at(-1) === '/'
      ? link?.url
      : link?.url + "/",
  };
  return (
		<Link
			href={withTrailingSlash?.url}
			title={link?.title}
			target={link?.attr}
			onClick={() =>
				handleDataLayerOnClick(blockData, cardPosition, pathName, true)
			}
			prefetch={false}
			className={`${isNmas ? `${styles?.linkSimple} ${merri?.variable}` : ""} ${
				className ? className : ""
			}`}
		>
			{link?.title}
		</Link>
	);
}