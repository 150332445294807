"use client";

import styles from "@/app/styles/Discover/Discover.module.scss";
import CardDiscover from "../cards/CardDiscover/CardDiscover";
import { isValidArray } from "@/app/helpers/arrays/array";
import Link from "next/link";

export default function RelatedContentDiscover({
	data,
	seeRelated = false,
	heightDocument = 800,
}: any) {
	return (
		<div className={styles.discover__related}>
			<div className={styles.discover__related__wrapper}>
				<div className={styles.discover__related__wrapper__container}>
					<h3 className={styles.discover__related__title}>
						Descubre más sobre:
					</h3>
					<div className={styles.discover__related__wrapper__container__cards}>
						{data?.related_cards
							?.slice(0, 2)
							?.map((cardData: any, index: number) => {
								return <CardDiscover key={index} cardData={cardData} />;
							})}
					</div>
					{isValidArray(data?.related_themes) && (
						<>
							<h3 className={styles.discover__category__title}>
								Te podría interesar:
							</h3>
							<div className={styles.discover__category__wrapper}>
								{data?.related_themes?.map((theme: any, index: number) => {
									return (
										theme?.title && (
											<Link href={theme?.url} prefetch={false} key={`LinkWrapper-${index}`}>
												<button className={styles.discover__category__button}>
													{theme?.title}
												</button>
											</Link>
										)
									);
								})}
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
