const dayjs = require('dayjs');

const getTotalSeconds = (date) => {
	return date.hour() * 3600 + date.minute() * 60 + date.second();
};

const widthProgressLive = (duration, startHour, actualHour) => {
	const initialHourFormatted = dayjs(
		actualHour.year() +
			'-' +
			(actualHour.month() + 1) +
			'-' +
			actualHour.date() +
			' ' +
			startHour,
		'YYYY-MM-DD h:mm:ss'
	);

	const initialSeconds = getTotalSeconds(initialHourFormatted);
	const actualSeconds = getTotalSeconds(actualHour);

	const diffTime = actualSeconds - initialSeconds;

	if (diffTime <= duration * 60 && diffTime > 0) {
		const width = (diffTime * 100) / ((duration * 60) + 1);
		return width;
	}
};

export default widthProgressLive;
