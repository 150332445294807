'use client'

import { sendNewsletterForm } from "@/app/helpers/newsletter/newsletterHelper";
import { FormEvent, useState } from "react";
import styles from "@/app/styles/Molecules/NewsletterForm.module.scss";

export default function NewsletterForm(){
  const [loadingForm, setloading] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [isResponse, setIsResponse] = useState<boolean>(false);
  const [succesResp, setSuccesResp] = useState<boolean | null>(null);
  const emailExpression = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)/;

  const onSetForm = async (event: FormEvent) => {
    if (!loadingForm) {
      event.preventDefault();
      // console.log(email);
			if (emailExpression.test(email)) {
        // console.log("form");
				setIsResponse(false);
				setloading(true);
				sendNewsletterForm(email)
					.then((sus: any) => {
						if (sus?.status !== 200) {
							return Promise.reject(new Error("Invalid response"));
						} else {
							setEmail("");
							setSuccesResp(true);
							setloading(false);
							setIsResponse(true);

						}
					})
					.catch((error) => {
            console.error(error);
						setSuccesResp(false);
						setloading(false);
						setIsResponse(true);
					});
			}
		}
	};

  return(
    <div id="mc_embed_shell">  
      <div id="mc_embed_signup">
          <form action="https://nmas.us1.list-manage.com/subscribe/post?u=39397b35b96bb5653b3c35da2&amp;id=efaa737a46&amp;f_id=00f679e2f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_self">
              <div id="mc_embed_signup_scroll" className={styles?.nl_form__box}>
                  <div>
                    <input className={styles?.nl_form__input} type="email" name="EMAIL" id="mce-EMAIL" placeholder="Escribe tu dirección de correo aquí..."/>
                  </div>
                  <div>
                    <input className={styles?.nl_form__submit} type="submit" name="subscribe" id="mc-embedded-subscribe" value="Enviar" aria-label="Enviar"/>
                  </div>
                  <div aria-hidden="true" className={styles?.nl_form__hidden}>
                    <input type="text" name="b_39397b35b96bb5653b3c35da2_efaa737a46" />
                  </div>
              </div>
          </form>
      </div>
    </div>
  )
}