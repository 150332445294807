import { useContext } from "react";
import { NewsInfo } from "./types/IndependetResponse.interface";
import { LoadMoreContentContext } from "@/app/context/LoadMoreFull";
import buttonMore from "@/app/styles/Blocks/BloqueIndependiente.module.scss";
import Icon from "../../atoms/Icon/Icon";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import { BlockDataType } from "@/app/types/BlockDataType";

type Props = {
	theme: string;
	newsInfo: NewsInfo[];
	blockData: BlockDataType;
	pathName: string | null;
};
export const ButtonMoreIndependFull = ({ theme, newsInfo, blockData, pathName }: Props) => {
	const increment = 4;
	const min = (index: number) => increment * index - increment;
	const max = (index: number) => increment * index;

	const { loadMoreIndex, setIsLoadMoreIndex } = useContext(
		LoadMoreContentContext
	);
	const generalStyles: any = {
		"--buttonMoreColor": `${theme === "light" ? "#083043" : "#fff"}`,
	};

	const handleButtonClick = () => {
		blockData.content_name = "undefined";
		blockData.contentTitle = "undefined";
		handleDataLayerOnClick(blockData, undefined, pathName, true);
		setIsLoadMoreIndex(loadMoreIndex + 1)
	}
	return (
		<>
			{newsInfo.length > max(loadMoreIndex + 1) && (
				<button
					className={`${buttonMore.buttonMore}`}
					style={generalStyles}
					onClick={handleButtonClick}
				>
					Cargar más sobre el tema 
					<Icon name="ArrowDown" />
				</button>
			)}
		</>
	);
};
