import { gql } from "@apollo/client";

export default gql`
    query GetAperturaInformativa($breakingId: String!) {
        getBreaking(breakingId: $breakingId) {
            id
            type
            drupalID
            blockTitle
            blockDescription
            isDark
            lookAlternate
            hasBreaking
            textBreaking
            alignment
        }
    }
`;