import { GetMapboxMapType } from "@/app/types/ToggleMap.interface";

import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import GetToggleMap from "@/app/queries/getToggleMap";
import { ToggleMap } from "./ToggleMap";

type ComponentProps = {
	type: string;
	id: string;
	position?: number;
	total?: number;
};

export const BlockToggleMap = async ({
	id,
	type,
	position = 0,
	total,
}: ComponentProps) => {
	const { data, ...rest } = await fetchApolloData(GetToggleMap, {
		mapboxId: id,
	});
	const { styleUrl, ...propertyRest }: GetMapboxMapType =
		data.getToggleMap || {};
	return (
		<>
			<ToggleMap
				position={position}
				type={type}
				total={total}
				restData={propertyRest}
				styleUrl={styleUrl}
			/>
		</>
	);
};
