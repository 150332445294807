import Image from "next/image";
import styles from "@/app/styles/Molecules/RectImage.module.scss";
import { ImgProps } from "@/app/types/ImgProps";
import ImageLinkUniversal from "../../atoms/ImageLink/ImageLinkUniversal";

export default function RectImage({img, link, size, cardPosition, blockData}: ImgProps){
  let w = 0;
  let h = 0;
  const hasLink = link?.uri !== "/";
  let imgSizes = "25vw";
  switch(size){
    case "S":
      // console.log("small");
      // SMALL
      w = 240;
      h = 135;
      imgSizes = "(min-width: 768px) 25vw, (min-width:390px) 22vw, 27vw";
    break;
    case "M":
      // console.log("medium");
      // MEDIUM
      w = 296;
      h = 208;
      imgSizes="(min-width:1366px) 45vw, (min-width:1280px) 40vw, (min-width:768px) 25vw, (min-width: 540px) 20vw, (min-width: 414px) 25vw, (min-width: 290px) 20vw, 26vw";
    break;
    default: 
      // console.log("large");
      // LARGE
      w = 752;
      h = 424;
      imgSizes = "(min-width: 1440px) 80vw, (min-width: 820px) 50vw, (min-width: 768px) 45vw, (min-width: 540px) 40vw, (min-width:414px) 35vw (min-width:390px) 30vw, 50vw";
    break;
  }
  
  return(
    <div className={styles?.imageWrapper}>
      {hasLink ?
      <ImageLinkUniversal link={link} img={img} blockData={blockData} cardPosition={cardPosition} w={w} h={h} sizes={imgSizes} />
      :
        <Image 
          src={img?.imageUrl?.webp ?? "/assets/placeholder_.svg"}
          alt={img?.alt ?? "Imagen de N+"} width={w} height={h}
          sizes={imgSizes}
        />
      }
    </div>
  );
}