'use client'
import React from 'react';
import style from "@/app/styles/Molecules/WidgetTitleMedia.module.scss";
import {Libre_Franklin, Merriweather} from 'next/font/google';

interface Props {
	title?: string;
	text?: string;
	lineColor?: string;
	isH1?: boolean;
}

const franklin = Libre_Franklin({
  subsets: ['latin'],
  display: 'swap',
  weight: ['700'],
  style: ['normal'],
  variable: '--font-franklin'
});

const merri = Merriweather({
  subsets: ['latin'],
  display: 'swap',
  style: ['normal', 'italic'],
  weight: ['400', '700'],
  variable: '--font-merry'
});

export default function WidgetTitleMedia({ title, text, isH1 }: Props) {
	const heading = isH1 ? 'h1' : 'h2';

	return (
		<header className={style?.widgetTitle}>
			<div className={style?.widgetTitle__wrapperSection}>
				{title && React.createElement(
					heading,
					{ className: `${style?.widgetTitle__title} ${franklin?.variable}` },
					title
				)}
				{text && (
					<p className={`${style?.widgetTitle__text} ${merri?.variable}`}>
						{text}
					</p>
				)}
			</div>
		</header>
	);
};