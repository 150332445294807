import { gql } from "@apollo/client";

export default gql`
	query GetStoryPages($sectionPath: String!) {
		getStoryPages(sectionPath: $sectionPath) {
			title
			editorialTitle
			summary
			author
			cropType
			path
			published
			dateTime
			dateModified
			changed
			typeMedia
			metaData {
				title
				description
				og_title
				og_description
				og_image
				twitter_cards_description
				twitter_cards_title
			}
			thumbnail {
				imageUrl {
					webp
				}
				imageDesktopUrl {
					webp
				}
				alt
				caption
				height
				width
			}
			images {
				image {
					corte_16_9
					corte_1_1
					corte_9_16
					caption
					alt
					width
					height
				}
			}
			videoStory {
				cmsid
				aspect
				title
				description
				duration
				path
				image {
					imageUrl
					imageAlt
				}
			}
			relatedVideos {
				cmsid
				aspect
				title
				description
				duration
				path
				image {
					imageUrl
					imageAlt
				}
			}
			adultContent
			enhancement {
				divAds
				facebook
				instagram
				nmas
				spotify
				tiktok
				twitter
				youtube
			}
			term {
				id
				url
				name
			}
			body
			relationships {
				field_block_custom {
					data {
						id
						type
					}
				}
			}
			teads
			seedTag
		}
	}
`;

export const GetRelatedStoryPage = gql`
	query GetStoryPagesRelated($sectionPath: String!, $termIds: [String]) {
		getStoryPagesRelated(sectionPath: $sectionPath, termIds: $termIds) {
			title
			datePublish
			path
			editorialTitle
			thumbnail {
				imageUrl {
          shapes {
            square
          }
        }
				alt
				width
				height
			}
			term {
				id
				name
				url
			}
			duration
			typeMedia
			isVideo
		}
	}
`;

export const GetBlocksStoryPage = gql`
	query GetStoryPagesBlocks($sectionPath: String!) {
		getStoryPagesBlocks(sectionPath: $sectionPath) {
			relationships {
				field_block_custom {
					data {
						id
						type
					}
				}
				field_widgets_asociados {
					data {
						id
						type
					}
				}
			}
		}
	}
`;
