export const webPage = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    url: "https://www.nmas.com.mx/",
    name: "N+",
    description: "Noticias de México y el mundo",
    image: "https://www.nmas.com.mx/screen_n+.png",
    keywords: [
        "noticias",
        "méxico",
        "mundo",
        "n+",
        "noticieros televisa",
        "Noticias de México",
        "Noticias en Vivo",
        "Noticias",
        "N+",
        "Nmas",
        "N+ En Vivo",
        "Noticieros",
        "N+ Noticias",
        "Noticias N+",
        "Noticias en Vivo de México y el Mundo N+",
        "Últimas Noticias,Noticias",
        "última Hora",
        "Noticias En Vivo",
        "FOROtv en Vivo",
        "FORO en Vivo",
        "Noticieros en Vivo",
        "Reacciones",
        "Nmas",
        "N+",
        "n más",
        "Noticieros Televisa",
        "Televisa",
    ],
};