import Image from "next/image";
import Link from "next/link";

import cardIndepend from "@/app/styles/Blocks/BloqueIndependiente.module.scss";
import { NewsInfo } from "./types/";
import { secondsToMinutes } from "@/app/helpers/timeHelpers/timeHelpers";
import { BlockDataType } from "@/app/types/BlockDataType";
import InteractiveLink from "../BloqueHeroGrid/InteractiveLink";

// TODO GTM Event
export const SingleCard = ({
	news,
	themeColor,
	loading,
	blockData,
	...rest
}: {
	news: NewsInfo;
	themeColor: string;
	loading: "lazy" | "eager";
	blockData: BlockDataType;
	cardPosition: number;
}) => {
	const generalStyles: any = {
		"--cardIndependTheme": `${themeColor === "light" ? "#fff" : "#00283b"}`,
		"--cardCaption": `${themeColor === "light" ? "#2D5D75" : "#BFD1DA"}`,
		"--fontColor": `${themeColor === "light" ? "#103d53" : "#edf4f8"}`,
		"--isVideo": `${!news?.videoDuration ? "300px" : ""}`,
		"--maxHeightVideo": `${news?.videoDuration ? "260px" : ""}`,
	};
	const typeMedia = news.type.split("--")[1];

	return (
		<div
			className={`${cardIndepend["cardIndepend__wrapperCard"]}`}
			style={generalStyles}
		>
			<div className={`${cardIndepend["cardIndepend__doubleColumn"]}`}>
				<figure
					className={`${cardIndepend["cardIndepend__wrapperImage"]}`}
					style={generalStyles}
				>
					<>
						<div className={`${cardIndepend["cardIndepend__wrapperContent"]}`}>
							<InteractiveLink title={news?.editorialTitle || news?.title} url={news?.newsUrl} blockData={blockData} cardPosition={rest?.cardPosition}>
								{typeMedia === "video" ? (
									<>
										<span
											className={`${cardIndepend["cardIndepend__mediaIcon"]}`}
										>
											{secondsToMinutes(news?.videoDuration)}
											<svg
												width={"21.8px"}
												height={"21.8px"}
												focusable="false"
												aria-hidden="true"
												viewBox="0 0 24 24"
												data-testid="PlayArrowSharpIcon"
												fill="#fff"
											>
												<path d="M8 5v14l11-7L8 5z" />
											</svg>
										</span>
									</>
								) : (
									<></>
								)}
								<Image
									className={`${cardIndepend["cardIndepend__image"]}`}
									src={
										news.desktopImage1 ||
										news.desktopImage2 ||
										"/assets/placeholder_.svg"
									}
									width={500}
									height={500}

									alt={news?.editorialTitle || news?.title}
									sizes="(max-width: 834px) 45.5vw 45.5vw, (max-width: 1440px) 21.8vw 21.8vw 21.8vw 21.8vw"
									loading={loading}
								/>
							</InteractiveLink>
						</div>
						<figcaption
							className={`${cardIndepend["cardIndepend__wrapperInfo"]}`}
							style={generalStyles}
						>
							<InteractiveLink title={news?.tagBlock} url={news?.newsUrl} blockData={blockData} >
								<span
									className={`${cardIndepend["cardIndepend__caption"]}`}
									style={generalStyles}
									title={news?.tagBlock}
								>
									{news?.tagBlock || ""}
								</span>
							</InteractiveLink>
							<InteractiveLink title={news?.editorialTitle || news?.title} url={news?.newsUrl} blockData={blockData} >
								<p
									className={`${cardIndepend["cardIndepend__headLine"]}`}
									style={generalStyles}
									title={news?.editorialTitle || news?.title || ""}
								>
									{news?.editorialTitle || news?.title || ""}
								</p>
							</InteractiveLink>
						</figcaption>
					</>
				</figure>
			</div>
		</div>
	);
};
