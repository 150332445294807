import { gql } from "@apollo/client";
export default gql`
	query GetMapboxMap($mapboxId: String!) {
		getMapboxMap(mapboxId: $mapboxId) {
			styleUrl
			source
			title
			description
			mapType
			initialYear
			newsUrl
			lineColor
		}
	}
`;
