"use client";
import { useResponsive } from "@/app/hooks/useResponsive";
import { usePathname } from "next/navigation";
import Advertising from "@/app/components/atoms/AdContainer/Advertising";
import Pixel from "@/app/components/atoms/AdContainer/Pixel";
import style from "@/app/styles/Atoms/Advertising.module.scss";
import indexAds from "@/app/components/atoms/Ads/Ads";

export default function BoxAds({
	withAd,
	position = 1,
	isDark,
	isGray,
	isLight,
	isAside = false,
	isLastBoxAds = false,
	typeAd = "themes",
}: any) {
	const themesDesktopCombo =
		position >= 5 ? indexAds.desktop.comboMaster : indexAds.desktop.comboSuper;
	const themesMobileCombo = isLastBoxAds
		? indexAds.mobile.comboMobile
		: position >= 4
		? indexAds.mobile.combo
		: indexAds.mobile.combo3;
	const { isDesktop, isTablet } = useResponsive();
	const pathName = usePathname() || "";
	// Se definen los combos según el tipo de ad a mostrar
	const aside = isDesktop ? indexAds.desktop.combo2 : indexAds.mobile.combo;
	const storypage = isDesktop
		? indexAds?.desktop?.combo
		: indexAds?.mobile?.combo;
	const themes = isDesktop ? themesDesktopCombo : themesMobileCombo;
	const banner =
		isDesktop || isTablet
			? indexAds.desktop.comboBanner
			: indexAds.mobile.banner;
	const pixel = indexAds.desktop.pixel;
	const pixelTwo = indexAds.desktop.pixelTwo;

	const isPixel = typeAd === "pixel" || typeAd === "pixelTwo";

	return (
		<div
			className={`
				${!isPixel ? style["advertising__boxAd"] : style["advertising__pixel"]}
				${withAd && !isPixel ? style["advertising__boxAd--withAd"] : ""}
				${isDark && !isPixel ? style["advertising__boxAd--isDark"] : ""}
				${isGray && !isPixel ? style["advertising__boxAd--isGray"] : ""}
				${isLight && !isPixel ? style["advertising__boxAd--isLight"] : ""}
			`}
		>
			{isPixel ? (
				<Pixel
					typeAd={typeAd === "pixel" ? pixel : pixelTwo}
					urlPath={pathName}
				/>
			) : (
				<Advertising
					typeAd={
						typeAd === "banner"
							? banner
							: typeAd === "storypage"
							? storypage
							: typeAd === "aside"
							? aside
							: themes
					}
					contentType={typeAd}
					urlPath={pathName}
					position={position}
					isAside={isAside}
				/>
			)}
		</div>
	);
}
