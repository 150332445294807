import { gql } from "@apollo/client";

export default gql `
  query GetDetailTalent($getDetailTalentId: String!) {
    getDetailTalent(id: $getDetailTalentId) {
      firstName
      lastName
      id
      type
      path
      shouldList
      summary
      cmsName
      thumbnail {
        imageUrl {
          webp
        }
        alt
        caption
        width
        height
      }
      channels {
        name
      }
      shows {
        id
        type
        title
        schedule
        path
        thumbnail {
          imageUrl {
            webp
          }
          alt
          caption
          width
          height
        }
        episodes {
          id
          date
          path
        }
      }
    }
  }
`;