"use client";
import styles from "@/app/styles/Molecules/CarouselVOD.module.scss";
import CardVideoNews from "../CardVideoNews/CardVideoNews";
import { BlockDataType } from "@/app/types/BlockDataType";
import Slider from "@/app/components/molecules/Slider/Slider";
import ListItem from "@/app/site-tools/structured-data/ListItem";

type Props = {
	videos: any;
	isDark: boolean;
	blockData: BlockDataType;
	enabledStructureData?: boolean;
};

export default function CarouselVOD({ videos, isDark, blockData, enabledStructureData = false }: Props) {

	return (
		<div className={styles.carousel_container}>
			<Slider
				slidesPerView={4.5}
				spaceBetween={15}
				breakpoints={{
					320: { slidesPerView: 1.2 },
					430: { slidesPerView: 2.2 },
					600: { slidesPerView: 2.5 },
					744: { slidesPerView: 3 },
					834: { slidesPerView: 3 },
					1000: { slidesPerView: 4.5 },
					1200: { slidesPerView: 4.5 },
					1440: { slidesPerView: 4.5 },
				}}
			>
				{videos &&
					videos.map((video: any, i: number) => {
						return (
							<CardVideoNews
								key={`fig-${video.id}-${i}`}
								post={video}
								cardPosition={i + 1}
								isDark={isDark}
								blockData={blockData}
							/>
						);
					})}
			</Slider>
			{ enabledStructureData ? ListItem(videos, true) : "" }
		</div>
	);
}
