'use client';
import { useState } from "react";
import EpisodesList from "../EpisodesList/EpisodesList";
import styles from "@/app/styles/Blocks/BloqueDetailTalent.module.scss";
import { BlockDataType } from "@/app/types/BlockDataType";

export default function TabSelector({shows, blockData}:{shows:any, blockData: BlockDataType}){
  const allEps = shows?.map((show:any) => {
    if(typeof show?.episodes !== "undefined" && show?.episodes?.length > 0){
      return {
        show: show?.title?.toLowerCase().replaceAll(" ", ""),
        episodes: show?.episodes
      };
    }
    return null;
  });
  const [episodes, setEpisodes] = useState(allEps?.[0]?.episodes);

  const handleChangeEps = (e:any) => {
    const filtered = allEps?.filter((ep:any) => ep?.show === e.target.value);
    e.currentTarget.classList.toggle("active");
    setEpisodes(() => filtered[0]?.episodes);
  }

  return (
    <div>
      <div className={`${styles['mainSection__epsSection__wrapperButtons']}`}>

      {
        shows?.filter((show:any) => show?.episodes?.length > 0)?.map((show:any) => {
          return (
            <button key={show?.id} role="button" onClick={handleChangeEps} value={show?.title?.toLowerCase().replaceAll(" ", "")} className={styles?.mainSection__epsSection__epsButton}>
              {show?.title}
            </button>
          )
        })
      }
      </div>
      <EpisodesList episodes={episodes} blockData={blockData} />
    </div>
  );
}