"use client";
import React from "react";

import CardTalent from "@/app/styles/Molecules/CardTalent.module.scss";
import { BlockDataType } from "@/app/types/BlockDataType";
import InteractiveLink from "@/app/components/organisms/BloqueHeroGrid/InteractiveLink";

type ComponentProps = {
	talentInfo: any;
	blockData: BlockDataType;
	cardPosition: number;
};

export default function TalentTabs({
	talentInfo,
	blockData,
	cardPosition,
}: ComponentProps) {
	const { name, lastName, talentPath, relationships } = talentInfo;
	return (
		<figure className={`${CardTalent["cardTalent__wrapper"]}`}>
			<div className={`${CardTalent["cardTalent__wrapperImage"]}`}>
				<InteractiveLink
					url={talentPath}
					title={`${name} ${lastName}`}
					blockData={blockData}
					cardPosition={cardPosition}
				>
					<img
						src={relationships?.field_foto?.attributes?.uri?.url}
						alt={`${name} ${lastName}`}
					/>
				</InteractiveLink>
			</div>
			<figcaption>
				<h2 className={`${CardTalent["cardTalent__name"]}`}>
					<InteractiveLink
						url={talentPath}
						title={`${name} ${lastName}`}
						blockData={blockData}
						cardPosition={cardPosition}
					>
						{name}
					</InteractiveLink>
				</h2>
				<span className={`${CardTalent["cardTalent__lastName"]}`}>
					<InteractiveLink
						url={talentPath}
						title={`${name} ${lastName}`}
						blockData={blockData}
						cardPosition={cardPosition}
					>
						{lastName}
					</InteractiveLink>
				</span>
			</figcaption>
		</figure>
	);
}
