import { useContext } from "react";
import { ToggleContext } from "@/app/context/ToggleContext";

import heroGrid from "@/app/styles/Blocks/BloqueHeroGrid.module.scss";
import Icon from "../Icon/Icon";

const ButtonLoadMore = ({ noContent, textBtn="Cargar más sobre el tema" }: { textBtn?: string, noContent?: string }) => {
	const { isToggle, setToggle } = useContext(ToggleContext);
	const handleToogleVisible = () => {
		setToggle((prev) => !prev);
	};

	return (
		<>
			<button
				className={`${heroGrid[`heroGrid${noContent}__moreButton`]} ${
					heroGrid[`heroGrid`]
				} ${
					!isToggle
						? ""
						: `${heroGrid[`heroGrid${noContent}__moreButton--hidden`]}`
				}`}
				onClick={() => handleToogleVisible()}
			>
				
				{textBtn}
				<Icon name="ArrowDown" className="" />
			</button>
		</>
	);
};

export default ButtonLoadMore;
