
import { Children } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Keyboard } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import style from "@/app/styles/Molecules/Slider.module.scss";

type Slide = {
	children: any;
	slidesPerView?: number;
	spaceBetween?: number;
	breakpoints?: any;
};

export default function Slider({ children, slidesPerView, spaceBetween, breakpoints }: Slide) {
	return (
		<div className={`${style["slider__wrapper"]}`}>
			<Swiper
				modules={[Navigation, Keyboard]}
				keyboard={{
					enabled: true,
				}}
				navigation
				grabCursor
				slidesPerView={slidesPerView}
				spaceBetween={spaceBetween}
				breakpoints={breakpoints}
			>
				{Children.map(children, (child, index) => (
					<SwiperSlide key={`swiper-slide-${index}`}>{child}</SwiperSlide>
				))}
			</Swiper>
		</div>
	);
}
