import styles from "@/app/styles/Atoms/Titles/Title.module.scss";

interface props {
	className?: string;
	head?: string;
	secondTitle?: boolean;
	textTitle?: string;
	typeMedia?: string;
}

const TitleHead = ({
	className = "",
	head = "h2",
	secondTitle,
	textTitle,
	typeMedia,
}: props) => {
	let headTitle = (
		<h2
			className={`${
				secondTitle
					? styles?.secondTitle
					: className
						? className
						: styles?.title
			}`}
		>
			{textTitle}
		</h2>
	);
	switch (head) {
		case "h1":
			headTitle = (
				<h1
					className={` ${
						secondTitle
							? styles?.secondTitle
							: className
								? className
								: styles?.title
					}						
						${typeMedia && typeMedia === "multiVideo" ? styles["title__liveblog--multiVideo"] : typeMedia && typeMedia === "multiImage" ? styles["title__liveblog--multiImage"] : ""}	
						`}
				>
					{textTitle}
				</h1>
			);
			break;
		case "h3":
			headTitle = (
				<h3
					className={
						secondTitle
							? styles?.secondTitle
							: className
							? className
							: styles?.title
					}
				>
					{textTitle}
				</h3>
			);
			break;
		case "p":
			headTitle = (
				<p
					className={
						secondTitle
							? styles?.secondTitle
							: className
							? className
							: styles?.title
					}
				>
					{textTitle}
				</p>
			);
			break;
		case "accordeon":
			headTitle = (
				<h3
					className={
						secondTitle
							? styles?.secondTitle
							: className
							? className
							: styles?.title
					}
				>
					{textTitle}
				</h3>
			);
			break;
	}
	return <> {headTitle} </>;
};

export default TitleHead;
