"use client";
import Head from "next/head";
import styles from "@/app/styles/TimeLine/TimeLine.module.scss";
import { Skeleton } from "@mui/material";
import CardMinuteToMinute from "../cards/CardItemMinuteToMinute/CardItemMinuteToMinute";
import { isValidArray } from "@/app/helpers/arrays/isValidArray";
import { gql, useQuery, useSubscription } from "@apollo/client";

const SUBSCRIPTION = gql`
	subscription SubscribeMinuteToMinute($subscribeMinuteToMinuteId: String!) {
		subscribeMinuteToMinute(id: $subscribeMinuteToMinuteId) {
			description
			hour
			link
			term
			title
			type
		}
	}
`;

const QUERY = gql`
	query GetTimelineItem($getTimelineItemId: String!) {
		getTimelineItem(id: $getTimelineItemId) {
			description
			hour
			link
			term
			title
			type
		}
	}
`;

export default function TimeLineDynamic({
	id,
	isStandalone,
	mainContent,
	blockDataLayer,
}: any) {
	const { data: { subscribeMinuteToMinute } = {} } = useSubscription(
		SUBSCRIPTION,
		{
			variables: { subscribeMinuteToMinuteId: id },
			context: {
				headers: {
					"X-Secure-Environment": `${process.env.NEXT_PUBLIC_APOLLOSEC}`,
				},
			},
		}
	);

	const {
		data: { getTimelineItem } = {},
		error,
		loading,
	} = useQuery(QUERY, {
		variables: { getTimelineItemId: id },
		context: {
			headers: {
				"X-Secure-Environment": `${process.env.NEXT_PUBLIC_APOLLOSEC}`,
			},
		},
	});

	if (error) console.error(error);

	const parsedData = !loading
		? isValidArray(subscribeMinuteToMinute)
			? subscribeMinuteToMinute.reverse()
			: isValidArray(getTimelineItem)
			? getTimelineItem
			: []
		: [];

	const minuteToMinuteCardsArray = !loading ? parsedData ?? [] : [];
	const isLive = mainContent?.visualType === "Señal en vivo" ? true : false;
	let datePublished = new Date();
	let dateModified = new Date();
	if (mainContent?.datePublished && mainContent?.dateModified) {
		datePublished = new Date(mainContent?.datePublished);
		dateModified = new Date(mainContent?.dateModified);
	}

	return (
		<>
			{loading ? (
				<Skeleton variant="rectangular" width={540} height={56} />
			) : (
				<h3 className={`${styles.timeline__heading} heading_timeline`}>
					Minuto a Minuto
				</h3>
			)}

			{loading ? (
				<Skeleton variant="rectangular" width={"100%"} height={464} />
			) : (
				<div className={styles.timeline__wrapper}>
					{minuteToMinuteCardsArray.map((cardData: any, index: any) => {
						return (
							<CardMinuteToMinute
								key={index}
								cardData={cardData}
								isLoading={loading}
								isStandalone={isStandalone}
								blockDataLayer={blockDataLayer}
								cardPosition={index + 2}
							/>
						);
					})}
				</div>
			)}
		</>
	);
}
