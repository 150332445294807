import ButtonImage from '@/app/components/atoms/ButtonImage/ButtonImage';
import styles from '@/app/styles/Blocks/BloqueWebStories.module.scss';
import { GWStoriesType } from '@/app/types/GWStoriesType';
import { StoriesBlockType } from '@/app/types/StoriesBlockType';

export default function GwsContainer({gwstories, isRound, blockData}:StoriesBlockType){
  const width = isRound ? 100 : 173;
  const height = isRound ? 100 : 230;

  return (
    <ul className={isRound ? `${styles?.gws_list}` : `${styles?.gws_list} ${styles?.gws_list_square}`}>
      {
        gwstories?.map((story:GWStoriesType, i:number) => (
          <li key={`gws-${story?.storyID}-${i}`} className={isRound ? `${styles?.gws_item}` : `${styles?.gws_item} ${styles?.square}`}>
            <ButtonImage story={story} gwstories={gwstories} width={width} height={height} blockData={blockData} cardPosition={i+1} />
          </li>
        ))
      }
    </ul>
  );
}