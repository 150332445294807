"use client";
import { useContext, useState } from "react";
import Icon from "@/app/components/atoms/Icon/Icon";
import SocialShareDialog from "@/app/components/molecules/Dialogs/SocialShareDialog/SocialShareDialog";
import BaseSnackbar from "@/app/components/molecules/Snackbar/BaseSnackbar";
import { ToggleContext } from "@/app/context/ToggleContext";

const VARIANTS = {
	copy: "copy",
	related: "related",
	share: "share",
};

type TooltipIconType = {
	clickFunction?: () => void;
	variant?: "copy" | "related" | "share" | undefined;
	tooltip?: string;
	isDarkMode?: boolean;
	baseCardModel?: any;
	placement?:
		| "top"
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
		| undefined;
	variantDiscover?: boolean;
	dataCardDiscover?: any;
	isDiscover?: boolean;
};

type TooltipVariantItem = {
	color?: string;
	tooltip?: string;
	isShowingSnackbar?: boolean;
	snackbarLabel?: string;
};

export default function TooltipIcon({
	variant = undefined,
	isDarkMode = false,
	baseCardModel,
	clickFunction = () => {},
	variantDiscover = false,
	dataCardDiscover,
	isDiscover,
}: TooltipIconType) {
	// ? Set the toggleContext to close de share modal when the slide transition start . The <MainDiscover> component is setting the setIsOpenShareDialog(false)
	const { isToggle: isOpenShareDialog, setToggle: setIsOpenShareDialog } =
		useContext(ToggleContext);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const openSnackbar = () => setIsOpen(true);
	const closeSnackbar = () => setIsOpen(false);
	const openShareDialog = () => setIsOpenShareDialog(true);
	const closeShareDialog = () => setIsOpenShareDialog(false);
	const variantItem: TooltipVariantItem = getVariant({
		variant,
		isDarkMode,
	});

	return (
		<>
			<div
				onClick={() => {
					clickFunction();
					if (VARIANTS.copy === variant) openSnackbar();
					if (VARIANTS.share === variant) openShareDialog();
				}}
				className={(!isDiscover && `shareButton`) || ""}
			>
				{VARIANTS.copy === variant ? (
					<Icon name="chain" />
				) : VARIANTS.related === variant ? (
					<Icon name="relatedVideo" />
				) : VARIANTS.share === variant ? (
					<Icon
						name="share"
						className={
							variantItem?.color === "white" ? "icon__white" : "icon__dark"
						}
					/>
				) : (
					<div />
				)}
			</div>
			{variantItem?.isShowingSnackbar && (
				<BaseSnackbar
					label={variantItem?.snackbarLabel}
					isOpenState={isOpen}
					handleCloseFunction={closeSnackbar}
				/>
			)}
			<SocialShareDialog
				isOpenState={isOpenShareDialog}
				handleCloseFunction={closeShareDialog}
				shareTitle={
					!variantDiscover
						? baseCardModel?.title
						: dataCardDiscover?.principal_content?.title ??
						  dataCardDiscover?.title
				}
				shareDescription={
					!variantDiscover
						? baseCardModel?.shortDescription
						: dataCardDiscover?.principal_content?.description ??
						  dataCardDiscover?.description
				}
				shareUrl={
					!variantDiscover
						? baseCardModel?.url
						: dataCardDiscover?.principal_content?.url ||
						  dataCardDiscover?.link ||
						  dataCardDiscover?.path
				}
				variantDiscover={variantDiscover}
				isDiscover={isDiscover}
			/>
		</>
	);
}

const getVariant = ({
	variant = "",
	isDarkMode = false,
}): TooltipVariantItem => {
	const themeColor: string = isDarkMode ? "white" : "black";

	switch (variant) {
		case VARIANTS.copy:
			return {
				color: themeColor,
				tooltip: "Copiar enlace",
				isShowingSnackbar: true,
				snackbarLabel: "¡Enlace copiado al portapapeles!",
			};
		case VARIANTS.related:
			return {
				color: themeColor,
				tooltip: "Videos Relacionados",
			};
		case VARIANTS.share:
			return {
				color: themeColor,
				tooltip: "Compartir",
			};
		default:
			return {};
	}
};

export type { TooltipIconType };
