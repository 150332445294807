export const newsOrganization = {
    "@context": "https://schema.org",
    "@type": "NewsMediaOrganization",
    name: "N+",
    url: "https://www.nmas.com.mx/",
    logo: "https://www.nmas.com.mx/screen_n+.png",
    contact: {
        "@type": "ContactPoint",
        telephone: "+52 55 5224 5000",
        email: "contacto@nmas.com.mx",
    },
    datePublished: "2023-10-27",
    publisher: {
        "@type": "Organization",
        name: "N+",
        sameAs: [
            "https://www.instagram.com/n.mas/",
            "https://twitter.com/nmas",
            "https://www.youtube.com/channel/UCUsm-fannqOY02PNN67C0KA",
            "https://www.tiktok.com/@n.mas?lang=es",
            "https://www.facebook.com/nmas.com.mx",
        ],
    },
    sameAs: [
        "https://www.instagram.com/n.mas/",
        "https://twitter.com/nmas",
        "https://www.youtube.com/channel/UCUsm-fannqOY02PNN67C0KA",
        "https://www.tiktok.com/@n.mas?lang=es",
        "https://www.facebook.com/nmas.com.mx",
    ],
};