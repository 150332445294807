import { gql } from "@apollo/client";

export default gql`
	query GetMosaico($mosaicoId: String!) {
		getMosaico(mosaicoId: $mosaicoId) {
			id
			uuid
			title
			subTitle
			description
			name
			type
			hasTitle
			isDark
			borderColor
			cmsName
			configurationType
			criteria
			noContent
			content {
				id
				type
				title
				topic
				duration
				path
				thumbnail {
					alt
					width
					height
					url {
						webp
					}
				}
			}
		}
	}
`;
