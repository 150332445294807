// import { getClient } from "@/app/lib/client";
// import { gql } from "@apollo/client";
// import '@/app/styles/Blocks/WidgetTitle.scss';

export const BloqueWidgetTitle = async ({type, id}:any) => {
  /*
  const QueryWidgetTitle = gql`
    query GetOpeningBlock($type: String!, $getWidgetTitleId: String!) {
      getWidgetTitle(type: $type, id: $getWidgetTitleId) {
        attributes {
          field_description
          field_title_block
          field_type_visualization
        }
      }
    }
  `;
  const {data, ...rest} = await getClient().query({
    query: QueryWidgetTitle, 
    variables: {
      type,
      getWidgetTitleId: id
    },
    context:{
      fetchOptions: {
        next: {
          revalidate: 5
        }
      }
    }
  }).catch((err:any) => {
    return {
      data: null,
      error: err
    }
  });

  if (rest?.error) {
    console.error(rest?.error);
	}

  const titleData = data?.getWidgetTitle;
  const {attributes} = titleData;
  const visualType:string = attributes?.field_type_visualization;
  const title:string = attributes?.field_title_block;
  const description:string = attributes?.field_description ?? "";
  const hasDescription:boolean = description !== "" ? true : false;
  */
  return(
    <></>
    // <div className={`widget_title_wrapper`}>
    //   <div className={`widget_title_inner_wrapper ${visualType}`} >
    //     <h2 className="widget_title_heading">{title}</h2>
    //     { hasDescription && <span className="widget_title_description">{description?.toUpperCase()}</span> }
    //   </div>
    // </div>
  );
}

export default BloqueWidgetTitle;