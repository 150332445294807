import { gql } from "@apollo/client";

export default gql`
	query GetIndependentFullSize(
		$getIndependentFullSizeId: String!
		$type: String!
		$fromSite: String
	) {
		getIndependentFullSize(
			id: $getIndependentFullSizeId
			type: $type
			fromSite: $fromSite
		) {
			blockTitle
			id
			configurationType
			loadCriterion
			theme
			subTitle
			lineColor
			cmsName
			newsInfo {
				altImage
				description
				desktopImage1
				desktopImage2
				editorialTitle
				id
				mobileImage1
				newsUrl
				tagBlock
				title
				videoDuration
				type
			}
		}
	}
`;
