/* eslint-disable no-sparse-arrays */
"use client";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";
import { RenderAdsScript } from "@/app/lib/ads/renderScrip";

export default function Pixel({
	typeAd = undefined,
	urlPath = "",
}: Props) {
	const adRef = useRef<HTMLInputElement | any>({});
	const [adDiv, setAdDiv] = useState<any>(<></>);
	const id = uuid();
	const adPath = `${typeAd?.path}`;

	useEffect(() => {
		if (!typeAd) return;
		const renderAds = async () => {
			if (!typeAd) return;
			await RenderAdsScript;
			if (
				typeof window === "undefined" ||
				typeof window.googletag === "undefined"
			)
				return;

			const { googletag } = window;

			googletag.cmd = googletag.cmd || [];

			googletag.cmd.push(() => {
				const mapping = googletag
					.sizeMapping()
					.addSize(typeAd?.map[0]?.viewport, typeAd?.map[0]?.slot)
					.addSize(typeAd?.map[1]?.viewport, typeAd?.map[1]?.slot)
					.build();

				googletag.pubads().collapseEmptyDivs();

				googletag
					.defineSlot(adPath, typeAd?.size, id)
					.defineSizeMapping(mapping)
					.addService(googletag.pubads())
					.setTargeting("url", `${typeAd?.path.slice(6)}${urlPath}`)
					.setTargeting("skey", (window.location.search.match(/skey=(\w+)/) || [,''])[1]);;

				googletag.enableServices();
				googletag.cmd.push(() => {
					googletag.display(id);
				});
			});
		};

		const renderAdDiv = () => {
			setAdDiv(
				<div
					id={id}
					ref={adRef}
				/>
			);
		};

		renderAdDiv();
		renderAds();

		return () => {
			window?.googletag?.cmd?.push(() => {
				window?.googletag?.destroySlots();
			});
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [typeAd]);

	return (
		<>
			<div ref={adRef}>{typeAd && adDiv}</div>
		</>
	);
}

type Props = {
	typeAd: any;
	urlPath: any;
};
