import { gql } from "@apollo/client";
export default gql`
	query GetSlideShow($slideShowId: String!) {
		getSlideShow(slideShowId: $slideShowId) {
			blockTitle
			id
			lineColor
			subtitle
			cmsName
			isTitle
			aspectRatio
			theme
			imagesNews {
				altImage
				editorialTitle
				idImage
				image_1_1
				image_16_9
				image_3_4
				image_4_3
				description
			}
		}
	}
`;
