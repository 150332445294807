"use client";
import pressRoomStyle from "@/app/styles/Blocks/BlockPressRoomHome.module.scss";
import Link from "next/link";
import Image from "next/image";
import {
	singleDateStringFirstMonth,
} from "@/app/helpers/timeHelpers/timeHelpers";
import { WindowWithDataLayer } from "@/app/types/WindowDataLayer";
import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
declare const window: WindowWithDataLayer;

export default function PressRoomHomePrincipal({
	path,
	editorialTitle,
	imageDesktop,
	loading,
	published,
	blockDataLayer,
}: {
	path: string;
	loading: "eager" | "lazy";
	editorialTitle: string;
	imageDesktop: string;
	published: any;
	blockDataLayer: any;
}) {
	const handleBannerClick = () => {
		handleDataLayerOnClick(blockDataLayer, 0, path);
	};
	return (
		<figure className={`${pressRoomStyle["pressRoomHome__wrapperImage"]}`}>
			<Link href={path} prefetch={false} onClick={() => handleBannerClick()}>
				<Image
					alt={editorialTitle}
					src={imageDesktop || "/assets/placeholder_.svg"}
					height={233}
					width={414}
					className={`${pressRoomStyle["pressRoomHome__image"]}`}
					sizes={"(min-width:0px) 100vw"}
					loading={loading}
					priority={true}
				/>
				<div className={`${pressRoomStyle["pressRoomHome__wrapperHeadline"]}`}>
					{/* TODO: AGREGAR DATETIME A ETIQUETA TIME */}
					<time
						dateTime={published}
						className={`${pressRoomStyle["pressRoomHome__mainHeadLineTime"]}`}
					>
						{singleDateStringFirstMonth(published)} CST
					</time>
					<span className={`${pressRoomStyle["pressRoomHome__mainHeadLine"]}`}>
						{editorialTitle}
					</span>
				</div>
			</Link>
		</figure>
	);
}
