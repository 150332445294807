"use client";

import styles from "@/app/styles/Cards/CardVertical.module.scss";

import { PostCard } from "@/app/types/PostCard.type";
import { BlockDataType } from "@/app/types/BlockDataType";

import InteractiveLink from "../../../organisms/BloqueHeroGrid/InteractiveLink";
import ImageLinkVerticalCustom from "@/app/components/atoms/ImageLink/ImageLinkVerticalCustom";
import VideoDuration from "@/app/components/atoms/VideoDuration/VideoDuration";

interface CardVerticalProps {
	post: PostCard;
	isVideo: boolean;
	isDark: boolean;
	index: number;
	blockData: BlockDataType;
}
export default function CardVertical({
	post,
	index,
	isDark,
	isVideo,
	blockData,
}: CardVerticalProps) {
	blockData.content_name = isVideo ? "VOD>" : "NEW>";
	blockData.contentTitle = post?.title;
	const load =
		blockData?.posicionBloque === 0 ||
		blockData?.posicionBloque === 1 ||
		blockData?.posicionBloque === 2
			? "eager"
			: "lazy";

	return (
		<figure
			className={
				isDark
					? `${styles?.cardVertical} ${styles?.cardVertical__dark}`
					: `${styles?.cardVertical} `
			}
		>
			<div className={styles?.cardVertical__imageFrame}>
				<ImageLinkVerticalCustom
					post={post}
					cardPosition={index}
					loading={load}
					blockData={blockData}
					urlImage={
						post?.thumbnail?.imageUrl?.shapes?.square
					}
				/>
				{isVideo && <VideoDuration duration={post.duration} />}
				{post?.isLiveblog && (
					<>
						<div
							className={`${styles[`cardVertical__liveblog__caption--off`]}`}
						>
							<span
								className={`${styles[`cardVertical__liveblog__captionText`]}`}
							>
								LIVEBLOG
							</span>
						</div>
					</>
				)}
			</div>
			<figcaption className={styles?.cardVertical__cvCaption}>
				<span>{post?.term[0]?.name ?? "N+"}</span>
				<InteractiveLink
					url={post?.path}
					blockData={blockData}
					title={post?.title}
					cardPosition={index + 1}
				>
					<h3>{post?.title}</h3>
				</InteractiveLink>
			</figcaption>
		</figure>
	);
}
