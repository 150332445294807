import handleDataLayerOnClick from "@/app/helpers/analytics/handleDataLayerOnClick";
import Icon from "../../atoms/Icon/Icon";
import handleSelectContent from "@/app/helpers/analytics/handleSelectcontent";
import style from '@/app/styles/Molecules/EntryHeader.module.scss';
/**
 *
 * This component is made if we need only the funcionality in text
 */
export const ThreadsButton = ({
	shareUrl,
	text = "",
	className,
	isLogo,
	dataLayer,
	isLiveBlogLayer,
	path,
	handleEvent,
	isLight,
	title,
}: {
	shareUrl: string;
	text: string;
	className?: string;
	isLogo: boolean;
	dataLayer: any;
	isLiveBlogLayer: boolean;
	path: string;
	handleEvent?: () => void;
	isLight:boolean;
	title:string;
}) => {
	const BASE_URL =
		process?.env?.NEXT_PUBLIC_BASE_URL?.replace(/\/+$/, "") ||
		"https://www.nmas.com.mx/";
	const re = /\/videos\//;
	const reParams = /\?([a-z-]+)=([a-z-0-9]+)$/; // Sirve para saber si la url tiene o no parámetros en la url

	const eventData = {
		event: "select_content",
		clickBloque: undefined,
		content_name: `${re.test(shareUrl) ? "VOD" : "NEW"}>${shareUrl?.replace(
			BASE_URL,
			""
		)}`,
		colorBloque: "Light",
		contenidosBloque: undefined,
		content_type: "Comparte",
		countWidget: undefined,
		descripcionBloque: undefined,
		estiloBloque: undefined,
		eventAction: "click",
		posicionBloque: undefined,
		section: `${path}`,
		subBloque: undefined,
		tamanoBloque: undefined,
		tipoBloque: undefined,
		tipoElemento: undefined,
		tituloBloque: undefined,
	};

	shareUrl = shareUrl?.includes(BASE_URL)
		? `${reParams.test(shareUrl) ? shareUrl : shareUrl + "/"}`
		: `${BASE_URL}${reParams.test(shareUrl) ? shareUrl : shareUrl + "/"}`;
	shareUrl = shareUrl?.replace(/[/]+$/, "/");
	const handleOpenThreads = () => {
		window.open(`https://www.threads.net/intent/post?text=${title} ${shareUrl}`);
		if (isLiveBlogLayer) {
			handleSelectContent(
				{
					event: "select_content",
					eventAction: "click",
					content_type: "Comparte",
					content_name: "threads",
					colorBloque: undefined,
				},
				undefined,
				dataLayer?.section.replace(BASE_URL, "/") || ""
			);
		} else {
			handleDataLayerOnClick(
				{
					...eventData,
					content_name: "threads",
					tipoElemento: "threads",
				},
				1,
				shareUrl?.replace(BASE_URL, ""),
				true
			);
		}
	};

	return (
		<span
			className={className}
			onClick={() => {
				handleOpenThreads();
				if (handleEvent) {
					handleEvent();
				}
			}}
		>
			{isLogo && <Icon name="threads" className={`${style['headShare__icon__threads']}`} />}
			{text}
		</span>
	);
};
