import GetBlockTopBanner from "@/app/queries/getTopBanner";
import { BlockTopBanner } from "./types/BloqueTopBanner.type";
import styles from "@/app/styles/Blocks/BloqueTopBanner.module.scss";
import { BlockProps } from "@/app/types/BlockProps.type";
import fetchApolloData from "@/app/helpers/fetchApolloData/fetchApolloData";
import Image from "next/image";
import Link from "next/link";

export const BloqueTopBanner = async({type, id}:BlockProps) => {

    const {data, ...rest} = await fetchApolloData(GetBlockTopBanner, {blockId: id });

    if(rest?.errors) {
      console.error(rest?.errors);
    }

    const blockData : BlockTopBanner = data?.getTopBanner ?? null;

    const hasLink = blockData?.loadMoreLink.uri !== null;

    const blockType = blockData?.bannerType ?? "";

    const bannerType = blockData?.bannerLeft ?? "";

    const bannerHasLink = blockData?.bannerLink.uri !== null;

    const bannerLink = blockData?.loadMoreLink.uri !== null ? blockData?.loadMoreLink.uri : "#";
    const bannerLinkTitle = blockData?.loadMoreLink.title !== null ? blockData?.loadMoreLink.title : "";

    return (
      <>
      {blockType === "Generico" && (
        <section className={styles?.topBanner}>
          <div className={blockData?.hasPadding ? `${styles?.topBanner__inner_wrapper_padded}` : `${styles?.topBanner__inner_wrapper}` }>
            <div className={styles?.topBanner__banner_main_box}>
              <div className={styles?.topBanner__banner_sub_box}>
                <div className={styles?.topBanner__banner_logo_box}>
                  {bannerHasLink && (
                    <Link href={blockData?.bannerLink.uri ?? {}} title={blockData?.bannerLink.title} prefetch={false}>
                      {bannerType === "Image" && blockData?.bannerSvgCode !== "" && (
                        <div dangerouslySetInnerHTML={{ __html: blockData?.bannerSvgCode ?? "" }} />
                      )}
                      {bannerType === "Image" && blockData?.images.logo.url !== null && (
                        <Image src={blockData?.images.logo.url} alt={"Imagen Logo"} width={blockData?.images.logo.width} height={blockData?.images.logo.height} />
                      )}
                      {bannerType === "Texto" && (
                        <div className={styles?.topBanner__banner_intext_box}>
                          <div>{blockData?.blockTitle}</div>
                          <div>{blockData?.blockSubtitle}</div>
                        </div>
                      )}
                    </Link>
                  )}
                  {!bannerHasLink && (
                    <div>
                      {bannerType === "Image" && blockData?.bannerSvgCode !== "" && (
                        <div dangerouslySetInnerHTML={{ __html: blockData?.bannerSvgCode ?? "" }} />
                      )}
                      {bannerType === "Image" && blockData?.images.logo.url !== null && (
                        <Image src={blockData?.images.logo.url} alt={"Imagen Logo"} width={blockData?.images.logo.width} height={blockData?.images.logo.height} />
                      )}
                      {bannerType === "Texto" && (
                        <div className={styles?.topBanner__banner_intext_box}>
                          <div>{blockData?.blockTitle}</div>
                          <div>{blockData?.blockSubtitle}</div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className={styles?.topBanner__banner_sub_box_right}>
                <Link href={bannerLink} title={bannerLinkTitle} prefetch={false}>
                  <div className={styles?.topBanner__banner_right_box}>
                    <div className={styles?.topBanner__banner_img_box}>
                      <div className={blockData?.hasBlink ? `${styles?.topBanner__banner_blink}` : `${styles?.topBanner__banner_not_blink}` }><></></div>
                      <div className={styles?.topBanner__banner_circleImg}>
                        {blockData?.images.circle.url !== null && (
                          <Image src={blockData?.images.circle.url} alt={"Imagen Logo"} width={blockData?.images.circle.width} height={blockData?.images.circle.height} />
                        )}
                      </div>
                    </div>
                    <div className={styles?.topBanner__banner_text_box}>
                      <div className={styles?.topBanner__banner_title}>{blockData?.bannerTitle}</div>
                      <div className={styles?.topBanner__banner_subtitle}>{blockData?.bannerSubtitle}</div>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      )}
      {blockType === "SVG" && (
          <section className={styles?.topBanner} style={{background: blockData?.blockColor}}>
            <div className={blockData?.hasPadding ? `${styles?.topBanner__inner_wrapper_padded}` : `${styles?.topBanner__inner_wrapper}` }>
            {hasLink && (
              <Link href={blockData?.loadMoreLink.uri ?? {}} title={blockData?.loadMoreLink.title} prefetch={false}>
                <div dangerouslySetInnerHTML={{ __html: blockData?.svgCode ?? "" }} />
              </Link>
            )}
            {!hasLink && (
              <div dangerouslySetInnerHTML={{ __html: blockData?.svgCode ?? "" }} />
            )}
            </div>
          </section>
      )}
      {blockType === "Imagen" && (
        <section className={styles?.topBanner} style={{background: blockData?.blockColor}}>
          <div className={blockData?.hasPadding ? `${styles?.topBanner__inner_wrapper_padded}` : `${styles?.topBanner__inner_wrapper}` }>
          {hasLink && (
            <Link href={blockData?.loadMoreLink.uri ?? {}} title={blockData?.loadMoreLink.title} prefetch={false}>
              {blockData?.images.full.url !== null && (
                <div className={styles?.topBanner__img_full}>
                  <Image src={blockData?.images.full.url} alt={"Imagen Banner Full"} width={blockData?.images.full.width} height={blockData?.images.full.height} />
                </div>
              )}
              {blockData?.images.desktop.url !== null && (
                <div className={styles?.topBanner__img_desktop}>
                  <Image src={blockData?.images.desktop.url} alt={"Imagen Banner Desktop"} width={blockData?.images.desktop.width} height={blockData?.images.desktop.height} />
                </div>
              )}
              {blockData?.images.tablet.url !== null && (
                <div className={styles?.topBanner__img_tablet}>
                  <Image src={blockData?.images.tablet.url} alt={"Imagen Banner Tablet"} width={blockData?.images.tablet.width} height={blockData?.images.tablet.height} />
                </div>
              )}
              {blockData?.images.mobile.url !== null && (
                <div className={styles?.topBanner__img_mobile}>
                  <Image src={blockData?.images.mobile.url} alt={"Imagen Banner Mobile"} width={blockData?.images.mobile.width} height={blockData?.images.mobile.height} />
                </div>
              )}
            </Link>
          )}
          {!hasLink && (
            <div>
              {blockData?.images.full.url !== null && (
                <div className={styles?.topBanner__img_full}>
                  <Image src={blockData?.images.full.url} alt={"Imagen Banner Full"} width={blockData?.images.full.width} height={blockData?.images.full.height} />
                </div>
              )}
              {blockData?.images.desktop.url !== null && (
                <div className={styles?.topBanner__img_desktop}>
                  <Image src={blockData?.images.desktop.url} alt={"Imagen Banner Desktop"} width={blockData?.images.desktop.width} height={blockData?.images.desktop.height} />
                </div>
              )}
              {blockData?.images.tablet.url !== null && (
                <div className={styles?.topBanner__img_tablet}>
                  <Image src={blockData?.images.tablet.url} alt={"Imagen Banner Tablet"} width={blockData?.images.tablet.width} height={blockData?.images.tablet.height} />
                </div>
              )}
              {blockData?.images.mobile.url !== null && (
                <div className={styles?.topBanner__img_mobile}>
                  <Image src={blockData?.images.mobile.url} alt={"Imagen Banner Mobile"} width={blockData?.images.mobile.width} height={blockData?.images.mobile.height} />
                </div>
              )}
            </div>
          )}
          </div>
        </section>
      )}
      {blockType === "HTML" && (
        <section className={styles?.topBanner} style={{background: blockData?.blockColor}}>
          <div className={blockData?.hasPadding ? `${styles?.topBanner__inner_wrapper_padded}` : `${styles?.topBanner__inner_wrapper}` }>
          {hasLink && (
            <Link href={blockData?.loadMoreLink.uri ?? {}} title={blockData?.loadMoreLink.title} prefetch={false}>
              <div dangerouslySetInnerHTML={{ __html: blockData?.content?.processed ?? "" }} />
            </Link>
          )}
          {!hasLink && (
            <div>
              <div dangerouslySetInnerHTML={{ __html: blockData?.content?.processed ?? "" }} />
            </div>
          )}
          </div>
        </section>
      )}
      </>
    );
}
export default BloqueTopBanner;