"use client";
import { useState, Children, useContext } from "react";
import independFull from "@/app/styles/Blocks/BloqueIndependiente.module.scss";
import { NewsInfo } from "./types/IndependetResponse.interface";
import { SingleCard } from "./SingleCard";
import { LoadMoreContentContext } from "../../../context/LoadMoreFull";
import { BlockDataType } from "@/app/types/BlockDataType";

export const CardsIndependFull = ({
	newsInfo,
	theme,
	loading,
	...rest
}: {
	newsInfo: NewsInfo[];
	theme: string;
	loading: 'lazy' | 'eager';
	blockData: BlockDataType;
}) => {

	const increment = 4;
	const min = (index: number) => increment * index - increment;
	const max = (index: number) => increment * index;
	const { loadMoreIndex } = useContext(
		LoadMoreContentContext
	);
	const generalStyles:any = {
		'--scrollBGColorFull':`${theme.toLowerCase() === 'light'? '':'#00283B'}`,
		'--scrollColor':`${theme.toLowerCase() === 'light'? '#00283B':'#E3E7EB'}`,
	}
	return (
		<>
			{Children.toArray(
				Array(3)
					.fill(null)
					.map((item, index: number) => {
						return (
							<>
								{index <= loadMoreIndex && (
									<div
										key={`wrapper-${index}`}
										className={`${independFull["independFull__wrapper"]}`}
										style={generalStyles}
									>
										{Children.toArray(
											newsInfo
												.slice(min(index + 1), max(index + 1))
												.map((item, subIndex) => {
													const isVideo = (item?.type === "node--video");
													return (
														<>
															<SingleCard news={item} themeColor={theme} loading={loading} blockData={{...rest?.blockData, content_name: isVideo ? "VOD>" : "NEW>"}} cardPosition={subIndex + 1} />
														</>
													);
												})
										)}
									</div>
								)}
							</>
						);
					})
			)}
		</>
	);
};
